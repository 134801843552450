"use client";
import { useEffect, useMemo, useCallback, useState } from "react";
import {
  SlidersHorizontal,
  ArrowRight,
  ArrowLeft,
  ArrowsLeftRight,
} from "@phosphor-icons/react/dist/ssr";
import { useFlightDetailsProvider } from "@/app/lib/context/FlightDetailsContext";
import { IconButton } from "@mui/material";
import { TripType, CabinClass, SortOrder } from "@/app/lib/types/enums";
import { useLocale, useTranslations } from "use-intl";
import type { TFlightFormValues } from "@/app/lib/types/types";
import SingleDateCard from "@/app/ui/SingleDateCard";
import dayjs from "dayjs";
import clsx from "clsx";
import { useNavigate } from "@tanstack/react-router";
import { motion } from "motion/react";

const sortOptionsLabels = {
  [SortOrder.ASC]: "flights.cheapest",
  [SortOrder.DESC]: "flights.expensive",
};

const cabinClassLabels = {
  [CabinClass.ECONOMY]: "flights.cabinClass.economy",
  [CabinClass.BUSINESS]: "flights.cabinClass.business",
  [CabinClass.PREMIUM_ECONOMY]: "flights.cabinClass.premiumEconomy",
  [CabinClass.FIRST_CLASS]: "flights.cabinClass.firstClass",
};

// Generate 10 Mock Dates Data with Day and Month
const generateDateArray = (
  startDate: string | Date,
  length: number,
  day: number,
) => {
  const dates = [];
  const currentDate = new Date(startDate);

  // Adjust the start date to be 4 days before the given date
  currentDate.setDate(currentDate.getDate() - day);

  for (let i = 0; i < length; i++) {
    dates.push({
      date: new Date(currentDate),
      day: currentDate.getDate(),
      month: currentDate.toLocaleString("ar-AE", { month: "short" }),
      active: i === 4, // Set the 5th date (index 4) as active initially
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

function Header({
  filtersHandler,
  sortHandler,
  selectedSortOption,
  backendFilters,
}: {
  filtersHandler: () => void;
  sortHandler: () => void;
  selectedSortOption: SortOrder;
  backendFilters: any;
}) {
  const t = useTranslations();
  const locale = useLocale();
  const navigate = useNavigate();
  const { searchData, updateSearchData } = useFlightDetailsProvider();
  const [dates, setDates] = useState(() =>
    generateDateArray(searchData!.dates.departure, 10, 4),
  );

  const passengerCount = useMemo(
    () => searchData!.adult + searchData!.child + searchData!.infantWithoutSeat,
    [searchData],
  );

  const handleDateChange = useCallback(
    (index: number) => {
      const selectedDate = dates[index].date;
      setDates((currentDates) =>
        generateDateArray(
          new Date(selectedDate.setDate(selectedDate.getDate() - 4)),
          10,
          0,
        ).map((date, i) => ({ ...date, active: i === 4 })),
      );
    },
    [dates],
  );

  const [activeDate, setActiveDate] = useState<Date | null>(null);

  useEffect(() => {
    const newActiveDate = dates.find((date) => date.active)?.date || null;
    setActiveDate(newActiveDate);
  }, [dates]);

  useEffect(() => {
    if (
      activeDate &&
      !dayjs(activeDate).isSame(searchData!.dates.departure, "day")
    ) {
      updateSearchData((prevData: TFlightFormValues) => {
        return {
          ...prevData,
          dates: {
            ...prevData.dates,
            departure: activeDate,
          },
        };
      });
    }
  }, [activeDate, searchData!.dates.departure, updateSearchData]);

  // Scroll into active date
  useEffect(() => {
    const activeDate = dates.find((date) => date.active);
    if (activeDate) {
      const element = document.getElementById(
        `${activeDate.day}-${activeDate.month}`,
      );
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [dates]);

  return (
    <>
      <motion.div
        layoutId="hero"
        className={clsx(
          "absolute top-0 z-20 h-38 w-full rounded-b-2xl bg-transparent bg-cover bg-no-repeat",
        )}
      />
      <div className="sticky top-0 z-20 mb-4 flex flex-col gap-4 bg-slate-100 px-2 pt-4">
        <div className="align-center flex justify-between">
          <IconButton onClick={sortHandler}>
            <SlidersHorizontal size={24} className="text-slate-1100" />
          </IconButton>
          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-center gap-2">
              <h3 className="text-slate-1100 max-w-[100px] truncate font-medium">
                {searchData!.to.city}
              </h3>
              {searchData!.tripType === TripType.ROUND_TRIP ? (
                <ArrowsLeftRight size={16} color="#003E2B" />
              ) : (
                <ArrowLeft size={16} color="#003E2B" />
              )}
              <h3 className="text-slate-1100 max-w-[100px] truncate font-medium">
                {searchData!.from.city}
              </h3>
            </div>
            <span className="text-tertiary max-w-[220px] truncate text-xs font-normal">
              {searchData!.tripType === TripType.ONE_WAY
                ? dayjs(searchData!.dates.departure).format("ddd, DD MMM")
                : `${dayjs(searchData!.dates.departure).format(
                    "ddd, DD MMM",
                  )} - ${dayjs(searchData!.dates.return).format("ddd, DD MMM")}`}
              , {passengerCount} {t("travellers.title")},{" "}
              {t(cabinClassLabels[searchData!.cabinClass])}
            </span>
          </div>
          <IconButton
            onClick={() => {
              navigate({ to: "/", search: { category: "flights" } });
            }}
          >
            <ArrowRight size={24} className="text-slate-1100" />
          </IconButton>
        </div>
        {searchData!.tripType === TripType.ONE_WAY && (
          <div
            className={clsx("flex gap-4 overflow-x-auto pb-4", {
              "flex-row-reverse": locale === "ar",
            })}
          >
            {dates.map((date) => (
              <SingleDateCard
                onChange={() => handleDateChange(dates.indexOf(date))}
                key={date.day}
                day={date.day}
                active={date.active}
                month={date.month}
              />
            ))}
          </div>
        )}
        <div className="flex items-center justify-between">
          {/* TODO: hidden until DESC sorting option is enabled on BE
        <button
          onClick={filtersHandler}
          aria-label="filters-button"
          className="text-primary rounded-2xl inline-flex items-center px-4 py-2 gap-2 bg-white text-sm active:border-accent-700"
        >
          <CaretDown /> {t(sortOptionsLabels[selectedSortOption])}
        </button> */}
          <div />
        </div>
      </div>
    </>
  );
}

export default Header;
