import { useQuery } from "@tanstack/react-query";

import useDebouncedValue from "./useDebouncedValue";
import { api } from "../api/api";
import { FindCityAirportsQueryOutputDto } from "../types/codegen";

const fetcher = <TResponse, TParams = unknown>({
  url,
  params,
}: {
  url: string;
  params: TParams;
}) => {
  return api
    .get<TResponse>(url, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

// Custom hook for airport search
export function useAirportSearch(searchQuery: string) {
  const debouncedSearch = useDebouncedValue(searchQuery.trim(), 500);

  const { data, isError, isLoading } = useQuery({
    queryKey: ["airports-search", debouncedSearch] as const,
    queryFn: () =>
      fetcher<FindCityAirportsQueryOutputDto>({
        url: `/mappings/cities/airports`,
        params: { searchQuery: debouncedSearch },
      }),
    select: (data) => data?.data || [],
    enabled: debouncedSearch.length > 2,
    staleTime: 1000 * 60 * 60 * 24, // fetch fresh translations after 24 hours
  });

  return {
    data,
    isLoading,
    isError,
  };
}
