import React from "react";
import { Drawer, Button } from "@mui/material";
import { CaretLeft } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

const BottomDrawer = ({
  open,
  totalPrice,
  buttonLabel,
  onButtonClick,
  disabled,
}: {
  open: boolean;
  totalPrice: string;
  buttonLabel: string;
  onButtonClick: () => void;
  disabled: boolean;
}) => {
  const t = useTranslations();
  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: "32px 32px 0 0",
          paddingBottom: "10px",
          height: "20vh",
        },
      }}
    >
      <div className="flex h-full flex-col gap-4 p-4">
        <div className="flex items-center justify-between">
          <span className="text-primary text-md flex items-center font-semibold">
            <CaretLeft color="#6A778A" size={16} />
            {totalPrice}
          </span>
          <h5 className="text-primary text-sm font-semibold">
            {t("flights.totalPrice")}
          </h5>
        </div>
        <Button
          variant="contained"
          className="mt-auto w-full font-semibold text-white"
          onClick={onButtonClick}
          disabled={disabled}
          sx={{
            textTransform: "none",
            padding: "16px",
            borderRadius: "999px",
            "&:disabled": {
              color: "white",
            },
          }}
        >
          {t(buttonLabel)}
        </Button>
      </div>
    </Drawer>
  );
};

export default BottomDrawer;
