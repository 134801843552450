import { useState, Fragment, useId } from "react";
import { Button, Checkbox, Divider } from "@mui/material";
import {
  Swatches,
  ListBullets,
  ListDashes,
  Bed,
  Hamburger,
  Swap,
} from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

import type { IHotelsFilters } from "@/app/lib/types/interfaces";
import { HotelsSortOptions } from "@/app/lib/types/enums";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

import PriceRangeSlider from "../PriceRangeSlider";

const BOARD_BASIS_OPTIONS = [
  {
    name: "Half Board",
    shortName: "HB",
    icon: <ListDashes size={20} className="fill-secondary" />,
  },
  {
    name: "Full Board",
    shortName: "FB",
    icon: <ListBullets size={20} className="fill-secondary" />,
  },
  {
    name: "All Inclusive",
    shortName: "AI",
    icon: <Swatches size={20} className="fill-secondary" />,
  },
  {
    name: "Room Only",
    shortName: "RO",
    icon: <Bed size={20} className="fill-secondary" />,
  },
  {
    name: "Self Catering",
    shortName: "SC",
    icon: <Hamburger size={20} className="fill-secondary" />,
  },
  {
    name: "Bed & Breakfast",
    shortName: "BB",
    icon: <Swap size={20} className="fill-secondary" />,
  },
  {
    name: "Room Description",
    shortName: "RD",
    icon: <Bed size={20} className="fill-secondary" />,
  },
];

const BOARD_BASIS = BOARD_BASIS_OPTIONS.map((option) => option.shortName);
const getOptionbyName = (name: string) =>
  BOARD_BASIS_OPTIONS.find((option) => option.shortName === name);

function HotelsFilters({
  filters,
  handleFiltersChange,
}: {
  filters: IHotelsFilters;
  handleFiltersChange: (newFilters: Partial<IHotelsFilters>) => void;
  setSortValue: (value: HotelsSortOptions) => void;
  sortValue: HotelsSortOptions;
  handleDrawerClose: () => void;
}) {
  const priceHeaderId = useId();
  const { dir } = useLocaleInfo();
  const t = useTranslations();
  const [localFilters, setLocalFilters] = useState<IHotelsFilters>({
    ...filters,
  });

  const minPrice = 1;
  const maxPrice = 999999;

  return (
    <>
      <div className="flex w-full justify-center pt-5 pb-3">
        <h2 className="text-title font-base font-medium">
          {t("hotels.filters.filters")}
        </h2>
      </div>

      <div className="p-4">
        <div className="mt-4 py-2">
          <h3 id={priceHeaderId} className="text-xl font-bold text-black">
            {t("hotels.filters.priceRange")}
          </h3>
        </div>

        <div className="mx-2">
          <div
            className="text-primary block w-full text-right text-sm font-medium"
            dir={dir}
          >
            <span dir="ltr">
              {localFilters.priceRange[0].toLocaleString()} IQD
            </span>
            {" - "}
            <span dir="ltr">
              {localFilters.priceRange[1].toLocaleString()} IQD
            </span>
          </div>
          <PriceRangeSlider
            min={filters.minPrice || minPrice}
            max={filters.maxPrice || maxPrice}
            aria-labelledby={priceHeaderId}
            value={[localFilters.priceRange[0], localFilters.priceRange[1]]}
            onChange={(event, newValue) => {
              setLocalFilters((prevFilters) => ({
                ...prevFilters,
                priceRange: newValue,
              }));
            }}
          />
        </div>

        <div className="mt-6 py-2">
          <h3 className="text-xl font-bold text-black">
            {t("hotels.filters.boardBasis")}
          </h3>

          <div className="flex flex-col">
            {BOARD_BASIS.map((option) => (
              <Fragment key={option}>
                <label className="flex w-full justify-between py-2">
                  <div className="flex items-center gap-4">
                    {getOptionbyName(option)?.icon}
                    <span className="text-primary font-medium">
                      {getOptionbyName(option)?.name}
                    </span>
                  </div>
                  <Checkbox
                    dir="rtl"
                    checked={localFilters.boardBasis?.includes(option)}
                    onChange={() => {
                      setLocalFilters((prevFilters) => {
                        const newBoardBasisFilters =
                          prevFilters.boardBasis.includes(option)
                            ? prevFilters.boardBasis.filter(
                                (prev) => prev !== option,
                              )
                            : [...prevFilters.boardBasis, option];
                        return {
                          ...prevFilters,
                          boardBasis: newBoardBasisFilters,
                        };
                      });
                    }}
                  />
                </label>
                <Divider />
              </Fragment>
            ))}
          </div>
          <div className="mt-6 py-2">
            <h3 className="text-xl font-bold text-black">
              {t("hotels.filters.hotelClass")}
            </h3>
            <div className="flex flex-col">
              {[1, 2, 3, 4, 5].map((option) => (
                <Fragment key={option}>
                  <label className="flex w-full items-center justify-between py-2">
                    <span className="text-primary font-medium">
                      {t(`hotels.filters.stars.${option}`)}
                    </span>
                    <Checkbox
                      dir="rtl"
                      checked={localFilters.starRating?.includes(option)}
                      onChange={() => {
                        setLocalFilters((prevFilters) => {
                          const newStarFilters =
                            prevFilters.starRating.includes(option)
                              ? prevFilters.starRating.filter(
                                  (prev) => prev !== option,
                                )
                              : [...prevFilters.starRating, option];
                          return {
                            ...prevFilters,
                            starRating: newStarFilters.sort((a, b) => a - b),
                          };
                        });
                      }}
                    />
                  </label>
                  <Divider />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-auto flex flex-col items-center gap-2 p-4">
          <Button
            onClick={() =>
              setLocalFilters((prevFilters) => ({
                ...prevFilters,
                priceRange: [minPrice, maxPrice],
                boardBasis: BOARD_BASIS_OPTIONS.map(
                  (option) => option.shortName,
                ),
                starRating: [1, 2, 3, 4, 5],
              }))
            }
            variant="text"
            sx={{
              textTransform: "unset",
              padding: "16px",
            }}
          >
            {t("hotels.filters.clearFilters")}
          </Button>
          <Button
            fullWidth
            onClick={() => handleFiltersChange(localFilters)}
            sx={{
              padding: "16px",
              borderRadius: "999px",
              textTransform: "unset",
            }}
            variant="contained"
          >
            {t("hotels.filters.done")}
          </Button>
        </div>
      </div>
    </>
  );
}

export default HotelsFilters;
