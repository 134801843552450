import useSWR from "swr";
import api, { getQiToken, apiV2 } from "../api/api";
import type { BookFlightOfferBodyInputDto } from "@/app/lib/types/codegen";
import { my, qi } from "../api/api";
import { useTranslations } from "use-intl";

// Book Fetcher
const bookFetcher = async ({
  url,
  postData,
}: {
  url: string;
  postData: BookFlightOfferBodyInputDto;
}) => {
  try {
    await apiV2.post(url, postData);

    const qiToken = getQiToken();

    if (!qiToken) {
      const paymentData = await initiatePayment(postData.availabilityToken);
      const order = await api.get(`/orders/${paymentData.id}`);

      return {
        paymentData,
        id: paymentData.id,
        reservationId: order.bookingId,
        data: {
          id: paymentData.id || paymentData.data?.id,
          reservationId: order.bookingId,
          data: {
            id: paymentData.id || paymentData.data?.id,
            reservationId: order.bookingId,
          },
        },
      };
    }

    const paymentQiServicesResult: any = await initiateQiServicesPayment(
      postData.availabilityToken,
    );

    if (paymentQiServicesResult.status.toLowerCase() === "successful") {
      await apiV2.post("/transactions/qi-services", {
        orderId: paymentQiServicesResult.orderId,
      });
      const order = await api.get(`/orders/${paymentQiServicesResult.orderId}`);

      return {
        data: {
          id: order.id,
          reservationId: order.bookingId,
        },
        id: order.id,
        reservationId: order.bookingId,
        status: "success",
      };
    }
    throw new Error(paymentQiServicesResult.status);
  } catch (err) {
    console.log(err);
    console.error("Error in booking or payment process:", err);
    throw err;
  }
};

async function initiateQiServicesPayment(token: string) {
  try {
    const response = await apiV2.post("/orders/qi-services", {
      serviceType: "air",
      availabilityToken: token,
    });
    return new Promise((resolve, reject) => {
      qi.purchase(
        {
          price: 1600,
          description: "",
          timeToLiveMillis: 60000,
          merchantTransactionID: response.data.data.paymentId.toString(),
        },
        async (data: any) => {
          resolve({
            orderId: response.data.data.orderId,
            paymentId: response.data.data.paymentId.toString(),
            status: data.status,
          });
        },
      );
    });
  } catch (err) {
    console.error("Failed to initiate payment:", err);
    throw new Error(
      `Failed to initiate payment: ${(err as any).message || err}`,
    );
  }
}

async function initiatePayment(token: string) {
  try {
    const response = await apiV2.post("/orders/super-qi", {
      serviceType: "air",
      availabilityToken: token,
    });

    if (
      !response ||
      !response.data ||
      !response.data.data ||
      !response.data.data.redirectUrl
    ) {
      throw new Error(
        "Invalid response from API: Missing redirect action form",
      );
    }

    const redirectUrl = response.data.data.redirectUrl;

    const paymentResult = await tradePay(redirectUrl);

    return paymentResult;
  } catch (err) {
    console.error("Failed to initiate payment:", err);
    throw new Error(
      `Failed to initiate payment: ${(err as any).message || err}`,
    );
  }
}

async function tradePay(redirectUrl: string) {
  try {
    const paymentPromise = new Promise((resolve, reject) => {
      my.tradePay({
        paymentUrl: redirectUrl,
        success: (res: any) => {
          if (
            res.resultCode?.toString() === "9000" ||
            res.data?.resultCode?.toString() === "9000"
          ) {
            resolve(res);
          } else {
            reject(
              new Error(
                `Payment failed: ${res.resultCode || res.data?.resultCode}`,
              ),
            );
          }
        },
        fail: (err: unknown) => {
          console.error("Payment failed:", err);
          reject(new Error(`Payment failed: ${err}`));
        },
      });
    });

    const result = await paymentPromise;
    return result;
  } catch (err) {
    console.error("Trade payment error:", err);
    throw err;
  }
}

export function useFlightBooking(
  payload: (BookFlightOfferBodyInputDto & { offerId: string }) | null,
) {
  const t = useTranslations();
  const { data, error, isLoading, mutate } = useSWR(
    () =>
      payload
        ? {
            url: `/flights/${payload.offerId}/book`,
            postData: {
              searchResultKey: payload.searchResultKey,
              searchResultToken: payload.searchResultToken,
              availabilityToken: payload.availabilityToken,
              itineraryIndex: payload.itineraryIndex,
              travelers: payload.travelers,
            },
          }
        : null,
    bookFetcher,
    {
      onError: () => {
        my.showToast({
          type: "exception",
          content: t("flights.failed"),
          duration: 3000,
        });
      },
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
    },
  );

  return {
    data: data?.data || [],
    mutate,
    isLoading,
    isError: !!error,
    error,
  };
}
