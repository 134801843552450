import { Button } from "@mui/material";
import { useTranslations } from "use-intl";
import type { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
function DeleteTraveller({
  traveller,
  closeDrawer,
  deleteTraveller,
}: {
  traveller: FindUserTravelersOutputData;
  closeDrawer: () => void;
  deleteTraveller: () => void;
}) {
  const t = useTranslations();

  const fullName = `${traveller.firstName} ${traveller.lastName}`;
  const removeTravellerHandler = () => {
    deleteTraveller();
    closeDrawer();
  };

  return (
    <div className="flex h-full w-full flex-col items-center px-4">
      <h5 className="text-title mt-6 mb-3 text-base leading-6 font-medium">
        {t("travellers.deletePerson")}
      </h5>
      <span className="text-primary mb-6 text-sm font-normal">
        {t("travellers.deleteName", {
          fullName,
        })}
      </span>
      <div className="mt-auto mb-9 flex w-full flex-col gap-2">
        <Button
          onClick={removeTravellerHandler}
          sx={{
            textTransform: "none",
            boxShadow: "none",
            width: "100%",
            borderRadius: "12px",
            padding: "12px 24px",
          }}
          variant="contained"
          color="error"
        >
          {t("delete")}
        </Button>
        <Button
          onClick={closeDrawer}
          sx={{
            textTransform: "none",
            color: "#222930",
            boxShadow: "none",
            width: "100%",
            borderRadius: "12px",
            padding: "12px 24px",
          }}
          variant="text"
        >
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
}

export default DeleteTraveller;
