import { useHotelSearchDetailsContext } from "@/app/lib/context/HotelSearchDetailsContext";
import HotelsList from "@/app/ui/hotels-list/HotelsList";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { getQiToken } from "@/app/lib/api/api";
import clsx from "clsx";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";
import { useEffect } from "react";
import { useHotelBookingContext } from "@/app/lib/context/HotelBookingProvider";

export const Route = createFileRoute("/_authenticated/hotels/list")({
  component: RouteComponent,
});

function RouteComponent() {
  const qi = getQiToken();

  const { data: searchData } = useHotelSearchDetailsContext();
  const { setData: setHotelBooking } = useHotelBookingContext();

  useEffect(() => {
    setHotelBooking(null);
  }, [setHotelBooking]);

  if (!searchData) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    return <Navigate to="/" search={{ category: "hotels" }} />;
  }

  return (
    <MuiThemeProvider>
      <main className={clsx("no-footer", { main: !qi, "main-qi": !!qi })}>
        <HotelsList searchData={searchData} />
      </main>
    </MuiThemeProvider>
  );
}
