import AnimatedLoader from "@/app/ui/AnimatedLoader";
import BookingError from "@/app/ui/BookingError";
import FlightBookSuccess from "@/app/ui/flight-purchase/FlightBookSuccess";
import PageLoader from "@/app/ui/PageLoader";
import {
  useFlightSelectionPayloadContext,
  useTravellersContext,
} from "@/app/lib/context";
import { useSingleFlightSelectionContext } from "@/app/lib/context/SingleFlightDetailsContext";
import { useFlightBooking } from "@/app/lib/hooks/useFlightBooking";
import { transformFlightTraveler } from "@/app/lib/utils/transformTraveler";
import { createFileRoute } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import { MuiThemeProvider } from "../../../app/lib/context/MuiThemeProvider";

export const Route = createFileRoute(
  "/_authenticated/_flight-offer/flights/offer/$id/step/final",
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { departureFlight, returnFlight, singleFlightDetails } =
    useSingleFlightSelectionContext();
  const { selectedFlightPayload } = useFlightSelectionPayloadContext();
  const { getSelectedTravellers, leadTraveller } = useTravellersContext();
  const t = useTranslations();
  const { data, isError, isLoading, error } = useFlightBooking(
    selectedFlightPayload
      ? {
          offerId: selectedFlightPayload?.offerId,
          searchResultKey: selectedFlightPayload?.searchResultKey,
          searchResultToken: selectedFlightPayload?.searchResultToken,
          itineraryIndex: selectedFlightPayload?.itineraryIndex,
          availabilityToken: singleFlightDetails?.availabilityToken,
          travelers: getSelectedTravellers.map((traveller) =>
            transformFlightTraveler(traveller, {
              isLead: traveller.id === leadTraveller?.id,
            }),
          ),
        }
      : null,
  );

  console.dir(data, { depth: null });
  const isDataReady = singleFlightDetails && getSelectedTravellers;

  if (isError) return <BookingError error={error} type="flights" />;

  return (
    <MuiThemeProvider>
      {isLoading ? (
        <div className="h-96">
          <PageLoader>
            <AnimatedLoader icon="airplane" />
            <h3 className="text-primary mt-3 font-normal">
              ...{t("flights.bookingFlight")}
            </h3>
          </PageLoader>
        </div>
      ) : (
        isDataReady && (
          <FlightBookSuccess
            leadTraveller={leadTraveller}
            singleFlightDetails={singleFlightDetails}
            departureFlight={departureFlight}
            returnFlight={returnFlight}
            departureStops={departureFlight?.stops || []}
            returnStops={returnFlight?.stops || []}
            selectedTravellers={getSelectedTravellers}
            reservationId={data.data?.reservationId || data.reservationId}
            orderId={data.data?.id || data.id}
          />
        )
      )}
    </MuiThemeProvider>
  );
}
