"use client";
import { Wrench } from "@phosphor-icons/react/dist/ssr";
import { Button } from "@mui/material";
import { useTranslations } from "use-intl";
import { WHATSAPP_URL } from "@/app/lib/routes";
import { NavigateOptions, useNavigate } from "@tanstack/react-router";

interface PageErrorProps {
  back?: NavigateOptions;
}

function PageError({ back }: PageErrorProps) {
  const t = useTranslations();
  const navigate = useNavigate();
  return (
    <div className="flex h-screen w-full flex-col items-center pb-10">
      <div className="mt-[30%] flex flex-col items-center gap-4">
        <Wrench size={60} color="#0212294d" />
        <span className="text-primary text-center text-sm" dir="rtl">
          {t("pageDidntWork")}
        </span>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(back || { to: "/", search: { category: "flights" } })
          }
        >
          {t("backToHome")}
        </Button>
      </div>
      <Button
        onClick={() => {
          window.open(WHATSAPP_URL, "_blank");
        }}
        variant="text"
        dir="rtl"
        sx={{
          marginTop: "auto",
          textTransform: "none",
          color: "#01AD99",
          fontWeight: "600",
        }}
      >
        {t("contactWhatsAppSupport")}
      </Button>
    </div>
  );
}

export default PageError;
