import { THotelSearchResult } from "@/app/lib/types/types";
import { IconButton } from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react";
import { useRef, useState, useLayoutEffect } from "react";

interface HotelGalleryProps {
  initialIndex: number;
  onBackClick: () => void;
  hotel: THotelSearchResult;
}

export function HotelGallery({
  hotel,
  initialIndex,
  onBackClick,
}: HotelGalleryProps) {
  const ref = useRef<HTMLDivElement>(null);

  const { name: hotelName, imagesUrls } = hotel;
  const [index, setIndex] = useState(() => initialIndex);

  useLayoutEffect(() => {
    if (ref.current === null) return;

    ref.current.scrollLeft = ref.current.clientWidth * (index - 1);
  }, []);

  const handleScroll = () => {
    if (ref.current === null) return;

    const scrollLeft = ref.current.scrollLeft;
    const newIndex = Math.round(scrollLeft / ref.current.clientWidth) + 1;

    setIndex(newIndex);
  };

  return (
    <div className="bg-opacity-80 fixed top-0 left-0 z-50 flex h-full w-full flex-col bg-black">
      {/* Header */}
      <div className="flex items-center justify-between gap-2 p-4">
        <div className="size-10" />
        <div className="text-sm text-white">
          {index}/{imagesUrls.length}
        </div>
        <IconButton sx={{ color: "white" }} onClick={onBackClick}>
          <ArrowRight size={24} color="currentColor" />
        </IconButton>
      </div>
      {/* Image Gallery */}
      <div
        onScroll={handleScroll}
        ref={ref}
        className="flex h-full w-full snap-x snap-mandatory flex-nowrap overflow-scroll"
      >
        {imagesUrls.map((url: string, idx: number) => (
          <div
            key={url}
            className="relative flex h-full w-full flex-shrink-0 flex-grow-0 snap-center items-center justify-center"
          >
            <img
              src={url}
              alt={`${hotelName} - Image ${idx + 1}`}
              loading="lazy"
              className="mx-4 w-full rounded-lg object-contain object-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
