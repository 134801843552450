import { useTravellersContext } from "@/app/lib/context";
import { useHotelSearchDetailsContext } from "@/app/lib/context/HotelSearchDetailsContext";
import { HotelOfferSummary } from "@/app/ui/hotel/HotelOfferSummary";
import { TravellerList } from "@/app/ui/hotel/TravellerList";
import { PersistentDrawer } from "@/app/ui/PersistentDrawer";
import { Button } from "@mui/material";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import * as Sentry from "@sentry/browser";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";
import { useHotelBookingContext } from "@/app/lib/context/HotelBookingProvider";
import PageLoader from "@/app/ui/PageLoader";
import AnimatedLoader from "@/app/ui/AnimatedLoader";

export const Route = createFileRoute(
  "/_authenticated/_hotel-offer/hotels/$id/travelers",
)({
  component: RouteComponent,
  staticData: {
    stepper: { step: 2 },
    footer: false,
    drawer: true,
  },
});

function RouteComponent() {
  const { selectedTravellers } = useTravellersContext();
  const { availabilityToken } = useHotelBookingContext();
  const { data: searchData } = useHotelSearchDetailsContext();
  const t = useTranslations();
  const navigate = Route.useNavigate();
  const params = Route.useParams();

  if (!searchData) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    Sentry.captureMessage(
      "User tried to access the travellers page without search data",
      "fatal",
    );
    return <Navigate to="/hotels/$id/rooms" params={params} />;
  }

  if (!availabilityToken) {
    return (
      <PageLoader>
        <AnimatedLoader icon="buildings" />
        <h3 className="text-primary mt-3 font-normal">
          {t("hotels.checkingRoomAvailability")}
        </h3>
      </PageLoader>
    );
  }

  return (
    <MuiThemeProvider>
      <TravellerList />
      <PersistentDrawer>
        <HotelOfferSummary />
        <Button
          variant="contained"
          className="mt-2 w-full"
          disabled={
            // ensure that the user has selected anything
            selectedTravellers.length === 0 ||
            // ensure that the user has selected all the travellers
            selectedTravellers.length !== searchData.visitors
          }
          sx={{
            borderRadius: "999px",
            padding: "16px",
            textTransform: "none",
          }}
          onClick={() => {
            navigate({ to: "/hotels/$id/summary" });
          }}
        >
          {t("hotels.continue")}
        </Button>
      </PersistentDrawer>
    </MuiThemeProvider>
  );
}
