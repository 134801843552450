import { AxiosError } from "axios";
import { OfferIsNotAvailableAnymoreOutputDto } from "./types/codegen";

export function isOfferIsNotAvailableAnymoreError(
  error: Error,
): error is AxiosError<OfferIsNotAvailableAnymoreOutputDto> {
  return (
    error instanceof AxiosError &&
    !!error.response?.data &&
    typeof error.response?.data === "object" &&
    "code" in error.response.data &&
    error.response.data.code === "OFFER_IS_NOT_AVAILABLE_ANYMORE"
  );
}
