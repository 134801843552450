import { useTranslations } from "use-intl";

import type { BaggageInfo as BaggageInfoType } from "@/app/lib/utils/baggageProcessor";
import { baggageIcons } from "@/app/lib/utils/baggageIcons";

import { getTravelerIntlLabel } from "../lib/utils/transformTraveler";

type Props = {
  baggageInfo: BaggageInfoType;
};

function BaggageInfo({ baggageInfo }: Props) {
  const t = useTranslations();

  return (
    <ul className="text-primary text-sm font-normal">
      {Object.entries(baggageInfo).map(([traveller, travellerBaggageInfo]) => (
        <li key={traveller} className="not-first-of-type:mt-3">
          <span className="font-medium">
            {t(getTravelerIntlLabel(traveller))}
          </span>
          <ul>
            {Object.entries(travellerBaggageInfo).map(
              ([route, routeBaggageInfo]) => (
                <li key={route} className="mt-2">
                  {route}
                  <ul>
                    {Object.entries(routeBaggageInfo).map(
                      ([baggageType, label]) => (
                        <li
                          key={baggageType}
                          className="mt-1 flex items-center gap-2"
                        >
                          <span>{baggageIcons[baggageType]}</span>
                          {/* labels come in eng only, so ltr has to be set */}
                          <span dir="ltr">{label}</span>
                        </li>
                      ),
                    )}
                  </ul>
                </li>
              ),
            )}
          </ul>
        </li>
      ))}
    </ul>
  );
}

export default BaggageInfo;
