/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as AuthenticatedTripsImport } from './routes/_authenticated/trips'
import { Route as AuthenticatedTravelersImport } from './routes/_authenticated/travelers'
import { Route as AuthenticatedHotelOfferImport } from './routes/_authenticated/_hotel-offer'
import { Route as AuthenticatedFlightOfferImport } from './routes/_authenticated/_flight-offer'
import { Route as AuthenticatedHotelsListImport } from './routes/_authenticated/hotels.list'
import { Route as AuthenticatedFlightsListImport } from './routes/_authenticated/flights.list'
import { Route as AuthenticatedTripsScopeIdImport } from './routes/_authenticated/trips_.$scope.$id'
import { Route as AuthenticatedHotelOfferHotelsIdImport } from './routes/_authenticated/_hotel-offer/hotels.$id'
import { Route as AuthenticatedHotelOfferHotelsIdTravelersImport } from './routes/_authenticated/_hotel-offer/hotels.$id.travelers'
import { Route as AuthenticatedHotelOfferHotelsIdSummaryImport } from './routes/_authenticated/_hotel-offer/hotels.$id.summary'
import { Route as AuthenticatedHotelOfferHotelsIdRoomsImport } from './routes/_authenticated/_hotel-offer/hotels.$id.rooms'
import { Route as AuthenticatedHotelOfferHotelsIdDetailsImport } from './routes/_authenticated/_hotel-offer/hotels.$id.details'
import { Route as AuthenticatedHotelOfferHotelsIdConfirmationImport } from './routes/_authenticated/_hotel-offer/hotels.$id.confirmation'
import { Route as AuthenticatedHotelOfferHotelsIdRoomsImagesImport } from './routes/_authenticated/_hotel-offer/hotels.$id.rooms_.images'
import { Route as AuthenticatedHotelOfferHotelsIdDetailsImagesImport } from './routes/_authenticated/_hotel-offer/hotels.$id.details_.images'
import { Route as AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenImport } from './routes/_authenticated/_hotel-offer/hotels.$id.rooms_.images_.fullscreen'
import { Route as AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenImport } from './routes/_authenticated/_hotel-offer/hotels.$id.details_.images_.fullscreen'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStepFinalImport } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.final'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStep3Import } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.3'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStep2Import } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.2'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStep1Import } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.1'

// Create/Update Routes

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTripsRoute = AuthenticatedTripsImport.update({
  id: '/trips',
  path: '/trips',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTravelersRoute = AuthenticatedTravelersImport.update({
  id: '/travelers',
  path: '/travelers',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelOfferRoute = AuthenticatedHotelOfferImport.update({
  id: '/_hotel-offer',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedFlightOfferRoute = AuthenticatedFlightOfferImport.update({
  id: '/_flight-offer',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelsListRoute = AuthenticatedHotelsListImport.update({
  id: '/hotels/list',
  path: '/hotels/list',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedFlightsListRoute = AuthenticatedFlightsListImport.update({
  id: '/flights/list',
  path: '/flights/list',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTripsScopeIdRoute = AuthenticatedTripsScopeIdImport.update({
  id: '/trips_/$scope/$id',
  path: '/trips/$scope/$id',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelOfferHotelsIdRoute =
  AuthenticatedHotelOfferHotelsIdImport.update({
    id: '/hotels/$id',
    path: '/hotels/$id',
    getParentRoute: () => AuthenticatedHotelOfferRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdTravelersRoute =
  AuthenticatedHotelOfferHotelsIdTravelersImport.update({
    id: '/travelers',
    path: '/travelers',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdSummaryRoute =
  AuthenticatedHotelOfferHotelsIdSummaryImport.update({
    id: '/summary',
    path: '/summary',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdRoomsRoute =
  AuthenticatedHotelOfferHotelsIdRoomsImport.update({
    id: '/rooms',
    path: '/rooms',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdDetailsRoute =
  AuthenticatedHotelOfferHotelsIdDetailsImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdConfirmationRoute =
  AuthenticatedHotelOfferHotelsIdConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdRoomsImagesRoute =
  AuthenticatedHotelOfferHotelsIdRoomsImagesImport.update({
    id: '/rooms_/images',
    path: '/rooms/images',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdDetailsImagesRoute =
  AuthenticatedHotelOfferHotelsIdDetailsImagesImport.update({
    id: '/details_/images',
    path: '/details/images',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute =
  AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenImport.update({
    id: '/rooms_/images_/fullscreen',
    path: '/rooms/images/fullscreen',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute =
  AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenImport.update({
    id: '/details_/images_/fullscreen',
    path: '/details/images/fullscreen',
    getParentRoute: () => AuthenticatedHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute =
  AuthenticatedFlightOfferFlightsOfferIdStepFinalImport.update({
    id: '/flights/offer/$id/step/final',
    path: '/flights/offer/$id/step/final',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStep3Route =
  AuthenticatedFlightOfferFlightsOfferIdStep3Import.update({
    id: '/flights/offer/$id/step/3',
    path: '/flights/offer/$id/step/3',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStep2Route =
  AuthenticatedFlightOfferFlightsOfferIdStep2Import.update({
    id: '/flights/offer/$id/step/2',
    path: '/flights/offer/$id/step/2',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStep1Route =
  AuthenticatedFlightOfferFlightsOfferIdStep1Import.update({
    id: '/flights/offer/$id/step/1',
    path: '/flights/offer/$id/step/1',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_flight-offer': {
      id: '/_authenticated/_flight-offer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedFlightOfferImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_hotel-offer': {
      id: '/_authenticated/_hotel-offer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedHotelOfferImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/travelers': {
      id: '/_authenticated/travelers'
      path: '/travelers'
      fullPath: '/travelers'
      preLoaderRoute: typeof AuthenticatedTravelersImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/trips': {
      id: '/_authenticated/trips'
      path: '/trips'
      fullPath: '/trips'
      preLoaderRoute: typeof AuthenticatedTripsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/flights/list': {
      id: '/_authenticated/flights/list'
      path: '/flights/list'
      fullPath: '/flights/list'
      preLoaderRoute: typeof AuthenticatedFlightsListImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/hotels/list': {
      id: '/_authenticated/hotels/list'
      path: '/hotels/list'
      fullPath: '/hotels/list'
      preLoaderRoute: typeof AuthenticatedHotelsListImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_hotel-offer/hotels/$id': {
      id: '/_authenticated/_hotel-offer/hotels/$id'
      path: '/hotels/$id'
      fullPath: '/hotels/$id'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdImport
      parentRoute: typeof AuthenticatedHotelOfferImport
    }
    '/_authenticated/trips_/$scope/$id': {
      id: '/_authenticated/trips_/$scope/$id'
      path: '/trips/$scope/$id'
      fullPath: '/trips/$scope/$id'
      preLoaderRoute: typeof AuthenticatedTripsScopeIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/confirmation': {
      id: '/_authenticated/_hotel-offer/hotels/$id/confirmation'
      path: '/confirmation'
      fullPath: '/hotels/$id/confirmation'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdConfirmationImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/details': {
      id: '/_authenticated/_hotel-offer/hotels/$id/details'
      path: '/details'
      fullPath: '/hotels/$id/details'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdDetailsImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/rooms': {
      id: '/_authenticated/_hotel-offer/hotels/$id/rooms'
      path: '/rooms'
      fullPath: '/hotels/$id/rooms'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdRoomsImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/summary': {
      id: '/_authenticated/_hotel-offer/hotels/$id/summary'
      path: '/summary'
      fullPath: '/hotels/$id/summary'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdSummaryImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/travelers': {
      id: '/_authenticated/_hotel-offer/hotels/$id/travelers'
      path: '/travelers'
      fullPath: '/hotels/$id/travelers'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdTravelersImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/details_/images': {
      id: '/_authenticated/_hotel-offer/hotels/$id/details_/images'
      path: '/details/images'
      fullPath: '/hotels/$id/details/images'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdDetailsImagesImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/rooms_/images': {
      id: '/_authenticated/_hotel-offer/hotels/$id/rooms_/images'
      path: '/rooms/images'
      fullPath: '/hotels/$id/rooms/images'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdRoomsImagesImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/1': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/1'
      path: '/flights/offer/$id/step/1'
      fullPath: '/flights/offer/$id/step/1'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStep1Import
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/2': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/2'
      path: '/flights/offer/$id/step/2'
      fullPath: '/flights/offer/$id/step/2'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStep2Import
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/3': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/3'
      path: '/flights/offer/$id/step/3'
      fullPath: '/flights/offer/$id/step/3'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStep3Import
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/final': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/final'
      path: '/flights/offer/$id/step/final'
      fullPath: '/flights/offer/$id/step/final'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalImport
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/details_/images_/fullscreen': {
      id: '/_authenticated/_hotel-offer/hotels/$id/details_/images_/fullscreen'
      path: '/details/images/fullscreen'
      fullPath: '/hotels/$id/details/images/fullscreen'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/rooms_/images_/fullscreen': {
      id: '/_authenticated/_hotel-offer/hotels/$id/rooms_/images_/fullscreen'
      path: '/rooms/images/fullscreen'
      fullPath: '/hotels/$id/rooms/images/fullscreen'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenImport
      parentRoute: typeof AuthenticatedHotelOfferHotelsIdImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedFlightOfferRouteChildren {
  AuthenticatedFlightOfferFlightsOfferIdStep1Route: typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  AuthenticatedFlightOfferFlightsOfferIdStep2Route: typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  AuthenticatedFlightOfferFlightsOfferIdStep3Route: typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
}

const AuthenticatedFlightOfferRouteChildren: AuthenticatedFlightOfferRouteChildren =
  {
    AuthenticatedFlightOfferFlightsOfferIdStep1Route:
      AuthenticatedFlightOfferFlightsOfferIdStep1Route,
    AuthenticatedFlightOfferFlightsOfferIdStep2Route:
      AuthenticatedFlightOfferFlightsOfferIdStep2Route,
    AuthenticatedFlightOfferFlightsOfferIdStep3Route:
      AuthenticatedFlightOfferFlightsOfferIdStep3Route,
    AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute:
      AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute,
  }

const AuthenticatedFlightOfferRouteWithChildren =
  AuthenticatedFlightOfferRoute._addFileChildren(
    AuthenticatedFlightOfferRouteChildren,
  )

interface AuthenticatedHotelOfferHotelsIdRouteChildren {
  AuthenticatedHotelOfferHotelsIdConfirmationRoute: typeof AuthenticatedHotelOfferHotelsIdConfirmationRoute
  AuthenticatedHotelOfferHotelsIdDetailsRoute: typeof AuthenticatedHotelOfferHotelsIdDetailsRoute
  AuthenticatedHotelOfferHotelsIdRoomsRoute: typeof AuthenticatedHotelOfferHotelsIdRoomsRoute
  AuthenticatedHotelOfferHotelsIdSummaryRoute: typeof AuthenticatedHotelOfferHotelsIdSummaryRoute
  AuthenticatedHotelOfferHotelsIdTravelersRoute: typeof AuthenticatedHotelOfferHotelsIdTravelersRoute
  AuthenticatedHotelOfferHotelsIdDetailsImagesRoute: typeof AuthenticatedHotelOfferHotelsIdDetailsImagesRoute
  AuthenticatedHotelOfferHotelsIdRoomsImagesRoute: typeof AuthenticatedHotelOfferHotelsIdRoomsImagesRoute
  AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute: typeof AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute
  AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute: typeof AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

const AuthenticatedHotelOfferHotelsIdRouteChildren: AuthenticatedHotelOfferHotelsIdRouteChildren =
  {
    AuthenticatedHotelOfferHotelsIdConfirmationRoute:
      AuthenticatedHotelOfferHotelsIdConfirmationRoute,
    AuthenticatedHotelOfferHotelsIdDetailsRoute:
      AuthenticatedHotelOfferHotelsIdDetailsRoute,
    AuthenticatedHotelOfferHotelsIdRoomsRoute:
      AuthenticatedHotelOfferHotelsIdRoomsRoute,
    AuthenticatedHotelOfferHotelsIdSummaryRoute:
      AuthenticatedHotelOfferHotelsIdSummaryRoute,
    AuthenticatedHotelOfferHotelsIdTravelersRoute:
      AuthenticatedHotelOfferHotelsIdTravelersRoute,
    AuthenticatedHotelOfferHotelsIdDetailsImagesRoute:
      AuthenticatedHotelOfferHotelsIdDetailsImagesRoute,
    AuthenticatedHotelOfferHotelsIdRoomsImagesRoute:
      AuthenticatedHotelOfferHotelsIdRoomsImagesRoute,
    AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute:
      AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute,
    AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute:
      AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute,
  }

const AuthenticatedHotelOfferHotelsIdRouteWithChildren =
  AuthenticatedHotelOfferHotelsIdRoute._addFileChildren(
    AuthenticatedHotelOfferHotelsIdRouteChildren,
  )

interface AuthenticatedHotelOfferRouteChildren {
  AuthenticatedHotelOfferHotelsIdRoute: typeof AuthenticatedHotelOfferHotelsIdRouteWithChildren
}

const AuthenticatedHotelOfferRouteChildren: AuthenticatedHotelOfferRouteChildren =
  {
    AuthenticatedHotelOfferHotelsIdRoute:
      AuthenticatedHotelOfferHotelsIdRouteWithChildren,
  }

const AuthenticatedHotelOfferRouteWithChildren =
  AuthenticatedHotelOfferRoute._addFileChildren(
    AuthenticatedHotelOfferRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedFlightOfferRoute: typeof AuthenticatedFlightOfferRouteWithChildren
  AuthenticatedHotelOfferRoute: typeof AuthenticatedHotelOfferRouteWithChildren
  AuthenticatedTravelersRoute: typeof AuthenticatedTravelersRoute
  AuthenticatedTripsRoute: typeof AuthenticatedTripsRoute
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedFlightsListRoute: typeof AuthenticatedFlightsListRoute
  AuthenticatedHotelsListRoute: typeof AuthenticatedHotelsListRoute
  AuthenticatedTripsScopeIdRoute: typeof AuthenticatedTripsScopeIdRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedFlightOfferRoute: AuthenticatedFlightOfferRouteWithChildren,
  AuthenticatedHotelOfferRoute: AuthenticatedHotelOfferRouteWithChildren,
  AuthenticatedTravelersRoute: AuthenticatedTravelersRoute,
  AuthenticatedTripsRoute: AuthenticatedTripsRoute,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedFlightsListRoute: AuthenticatedFlightsListRoute,
  AuthenticatedHotelsListRoute: AuthenticatedHotelsListRoute,
  AuthenticatedTripsScopeIdRoute: AuthenticatedTripsScopeIdRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedHotelOfferRouteWithChildren
  '/travelers': typeof AuthenticatedTravelersRoute
  '/trips': typeof AuthenticatedTripsRoute
  '/': typeof AuthenticatedIndexRoute
  '/flights/list': typeof AuthenticatedFlightsListRoute
  '/hotels/list': typeof AuthenticatedHotelsListRoute
  '/hotels/$id': typeof AuthenticatedHotelOfferHotelsIdRouteWithChildren
  '/trips/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/hotels/$id/confirmation': typeof AuthenticatedHotelOfferHotelsIdConfirmationRoute
  '/hotels/$id/details': typeof AuthenticatedHotelOfferHotelsIdDetailsRoute
  '/hotels/$id/rooms': typeof AuthenticatedHotelOfferHotelsIdRoomsRoute
  '/hotels/$id/summary': typeof AuthenticatedHotelOfferHotelsIdSummaryRoute
  '/hotels/$id/travelers': typeof AuthenticatedHotelOfferHotelsIdTravelersRoute
  '/hotels/$id/details/images': typeof AuthenticatedHotelOfferHotelsIdDetailsImagesRoute
  '/hotels/$id/rooms/images': typeof AuthenticatedHotelOfferHotelsIdRoomsImagesRoute
  '/flights/offer/$id/step/1': typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  '/flights/offer/$id/step/2': typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  '/flights/offer/$id/step/3': typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  '/flights/offer/$id/step/final': typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
  '/hotels/$id/details/images/fullscreen': typeof AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute
  '/hotels/$id/rooms/images/fullscreen': typeof AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedHotelOfferRouteWithChildren
  '/travelers': typeof AuthenticatedTravelersRoute
  '/trips': typeof AuthenticatedTripsRoute
  '/': typeof AuthenticatedIndexRoute
  '/flights/list': typeof AuthenticatedFlightsListRoute
  '/hotels/list': typeof AuthenticatedHotelsListRoute
  '/hotels/$id': typeof AuthenticatedHotelOfferHotelsIdRouteWithChildren
  '/trips/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/hotels/$id/confirmation': typeof AuthenticatedHotelOfferHotelsIdConfirmationRoute
  '/hotels/$id/details': typeof AuthenticatedHotelOfferHotelsIdDetailsRoute
  '/hotels/$id/rooms': typeof AuthenticatedHotelOfferHotelsIdRoomsRoute
  '/hotels/$id/summary': typeof AuthenticatedHotelOfferHotelsIdSummaryRoute
  '/hotels/$id/travelers': typeof AuthenticatedHotelOfferHotelsIdTravelersRoute
  '/hotels/$id/details/images': typeof AuthenticatedHotelOfferHotelsIdDetailsImagesRoute
  '/hotels/$id/rooms/images': typeof AuthenticatedHotelOfferHotelsIdRoomsImagesRoute
  '/flights/offer/$id/step/1': typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  '/flights/offer/$id/step/2': typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  '/flights/offer/$id/step/3': typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  '/flights/offer/$id/step/final': typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
  '/hotels/$id/details/images/fullscreen': typeof AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute
  '/hotels/$id/rooms/images/fullscreen': typeof AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_authenticated/_flight-offer': typeof AuthenticatedFlightOfferRouteWithChildren
  '/_authenticated/_hotel-offer': typeof AuthenticatedHotelOfferRouteWithChildren
  '/_authenticated/travelers': typeof AuthenticatedTravelersRoute
  '/_authenticated/trips': typeof AuthenticatedTripsRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/flights/list': typeof AuthenticatedFlightsListRoute
  '/_authenticated/hotels/list': typeof AuthenticatedHotelsListRoute
  '/_authenticated/_hotel-offer/hotels/$id': typeof AuthenticatedHotelOfferHotelsIdRouteWithChildren
  '/_authenticated/trips_/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/_authenticated/_hotel-offer/hotels/$id/confirmation': typeof AuthenticatedHotelOfferHotelsIdConfirmationRoute
  '/_authenticated/_hotel-offer/hotels/$id/details': typeof AuthenticatedHotelOfferHotelsIdDetailsRoute
  '/_authenticated/_hotel-offer/hotels/$id/rooms': typeof AuthenticatedHotelOfferHotelsIdRoomsRoute
  '/_authenticated/_hotel-offer/hotels/$id/summary': typeof AuthenticatedHotelOfferHotelsIdSummaryRoute
  '/_authenticated/_hotel-offer/hotels/$id/travelers': typeof AuthenticatedHotelOfferHotelsIdTravelersRoute
  '/_authenticated/_hotel-offer/hotels/$id/details_/images': typeof AuthenticatedHotelOfferHotelsIdDetailsImagesRoute
  '/_authenticated/_hotel-offer/hotels/$id/rooms_/images': typeof AuthenticatedHotelOfferHotelsIdRoomsImagesRoute
  '/_authenticated/_flight-offer/flights/offer/$id/step/1': typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  '/_authenticated/_flight-offer/flights/offer/$id/step/2': typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  '/_authenticated/_flight-offer/flights/offer/$id/step/3': typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  '/_authenticated/_flight-offer/flights/offer/$id/step/final': typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
  '/_authenticated/_hotel-offer/hotels/$id/details_/images_/fullscreen': typeof AuthenticatedHotelOfferHotelsIdDetailsImagesFullscreenRoute
  '/_authenticated/_hotel-offer/hotels/$id/rooms_/images_/fullscreen': typeof AuthenticatedHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/travelers'
    | '/trips'
    | '/'
    | '/flights/list'
    | '/hotels/list'
    | '/hotels/$id'
    | '/trips/$scope/$id'
    | '/hotels/$id/confirmation'
    | '/hotels/$id/details'
    | '/hotels/$id/rooms'
    | '/hotels/$id/summary'
    | '/hotels/$id/travelers'
    | '/hotels/$id/details/images'
    | '/hotels/$id/rooms/images'
    | '/flights/offer/$id/step/1'
    | '/flights/offer/$id/step/2'
    | '/flights/offer/$id/step/3'
    | '/flights/offer/$id/step/final'
    | '/hotels/$id/details/images/fullscreen'
    | '/hotels/$id/rooms/images/fullscreen'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/travelers'
    | '/trips'
    | '/'
    | '/flights/list'
    | '/hotels/list'
    | '/hotels/$id'
    | '/trips/$scope/$id'
    | '/hotels/$id/confirmation'
    | '/hotels/$id/details'
    | '/hotels/$id/rooms'
    | '/hotels/$id/summary'
    | '/hotels/$id/travelers'
    | '/hotels/$id/details/images'
    | '/hotels/$id/rooms/images'
    | '/flights/offer/$id/step/1'
    | '/flights/offer/$id/step/2'
    | '/flights/offer/$id/step/3'
    | '/flights/offer/$id/step/final'
    | '/hotels/$id/details/images/fullscreen'
    | '/hotels/$id/rooms/images/fullscreen'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_authenticated/_flight-offer'
    | '/_authenticated/_hotel-offer'
    | '/_authenticated/travelers'
    | '/_authenticated/trips'
    | '/_authenticated/'
    | '/_authenticated/flights/list'
    | '/_authenticated/hotels/list'
    | '/_authenticated/_hotel-offer/hotels/$id'
    | '/_authenticated/trips_/$scope/$id'
    | '/_authenticated/_hotel-offer/hotels/$id/confirmation'
    | '/_authenticated/_hotel-offer/hotels/$id/details'
    | '/_authenticated/_hotel-offer/hotels/$id/rooms'
    | '/_authenticated/_hotel-offer/hotels/$id/summary'
    | '/_authenticated/_hotel-offer/hotels/$id/travelers'
    | '/_authenticated/_hotel-offer/hotels/$id/details_/images'
    | '/_authenticated/_hotel-offer/hotels/$id/rooms_/images'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/1'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/2'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/3'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/final'
    | '/_authenticated/_hotel-offer/hotels/$id/details_/images_/fullscreen'
    | '/_authenticated/_hotel-offer/hotels/$id/rooms_/images_/fullscreen'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_flight-offer",
        "/_authenticated/_hotel-offer",
        "/_authenticated/travelers",
        "/_authenticated/trips",
        "/_authenticated/",
        "/_authenticated/flights/list",
        "/_authenticated/hotels/list",
        "/_authenticated/trips_/$scope/$id"
      ]
    },
    "/_authenticated/_flight-offer": {
      "filePath": "_authenticated/_flight-offer.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_flight-offer/flights/offer/$id/step/1",
        "/_authenticated/_flight-offer/flights/offer/$id/step/2",
        "/_authenticated/_flight-offer/flights/offer/$id/step/3",
        "/_authenticated/_flight-offer/flights/offer/$id/step/final"
      ]
    },
    "/_authenticated/_hotel-offer": {
      "filePath": "_authenticated/_hotel-offer.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_hotel-offer/hotels/$id"
      ]
    },
    "/_authenticated/travelers": {
      "filePath": "_authenticated/travelers.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/trips": {
      "filePath": "_authenticated/trips.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/flights/list": {
      "filePath": "_authenticated/flights.list.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/hotels/list": {
      "filePath": "_authenticated/hotels.list.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/_hotel-offer/hotels/$id": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.tsx",
      "parent": "/_authenticated/_hotel-offer",
      "children": [
        "/_authenticated/_hotel-offer/hotels/$id/confirmation",
        "/_authenticated/_hotel-offer/hotels/$id/details",
        "/_authenticated/_hotel-offer/hotels/$id/rooms",
        "/_authenticated/_hotel-offer/hotels/$id/summary",
        "/_authenticated/_hotel-offer/hotels/$id/travelers",
        "/_authenticated/_hotel-offer/hotels/$id/details_/images",
        "/_authenticated/_hotel-offer/hotels/$id/rooms_/images",
        "/_authenticated/_hotel-offer/hotels/$id/details_/images_/fullscreen",
        "/_authenticated/_hotel-offer/hotels/$id/rooms_/images_/fullscreen"
      ]
    },
    "/_authenticated/trips_/$scope/$id": {
      "filePath": "_authenticated/trips_.$scope.$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/_hotel-offer/hotels/$id/confirmation": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.confirmation.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotel-offer/hotels/$id/details": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.details.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotel-offer/hotels/$id/rooms": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.rooms.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotel-offer/hotels/$id/summary": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.summary.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotel-offer/hotels/$id/travelers": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.travelers.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotel-offer/hotels/$id/details_/images": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.details_.images.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotel-offer/hotels/$id/rooms_/images": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.rooms_.images.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/1": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.1.tsx",
      "parent": "/_authenticated/_flight-offer"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/2": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.2.tsx",
      "parent": "/_authenticated/_flight-offer"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/3": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.3.tsx",
      "parent": "/_authenticated/_flight-offer"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/final": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.final.tsx",
      "parent": "/_authenticated/_flight-offer"
    },
    "/_authenticated/_hotel-offer/hotels/$id/details_/images_/fullscreen": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.details_.images_.fullscreen.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotel-offer/hotels/$id/rooms_/images_/fullscreen": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.rooms_.images_.fullscreen.tsx",
      "parent": "/_authenticated/_hotel-offer/hotels/$id"
    }
  }
}
ROUTE_MANIFEST_END */
