"use client";
import {
  SlidersHorizontal,
  ArrowRight,
  CaretDown,
} from "@phosphor-icons/react/dist/ssr";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useHotelSearchDetailsContext } from "@/app/lib/context/HotelSearchDetailsContext";
import { HotelsSortOptions } from "@/app/lib/types/enums";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

function Header({
  filtersHandler,
  sortHandler,
  selectedSortOption,
  hotelCount,
}: {
  filtersHandler: () => void;
  sortHandler: () => void;
  selectedSortOption: HotelsSortOptions;
  hotelCount: number | undefined;
}) {
  const navigate = useNavigate();
  const { data: searchData } = useHotelSearchDetailsContext();
  const t = useTranslations();

  const sortOptionsLabels = {
    [HotelsSortOptions.PRICE]: t("hotels.filters.cheapestFirst"),
    [HotelsSortOptions.RATING]: t("hotels.filters.ratingFirst"),
  };

  return (
    <div className="mt-4 mb-4 flex flex-col gap-4 px-4">
      <div className="align-center flex justify-between">
        <IconButton onClick={sortHandler}>
          <SlidersHorizontal size={24} className="fill-slate-1100" />
        </IconButton>
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-center gap-2">
            <h3 className="text-title font-medium">
              {searchData!.destination.name}
            </h3>
          </div>
          <span className="text-tertiary max-w-[220px] truncate text-xs font-normal">
            {dayjs(searchData!.dates.checkIn).format("ddd, DD MMM")} -{" "}
            {dayjs(searchData!.dates.checkOut).format("ddd, DD MMM")},{" "}
            {searchData!.rooms.length}{" "}
            {searchData!.rooms.length > 1
              ? t("hotels.search.rooms")
              : t("hotels.search.room")}
          </span>
        </div>
        <IconButton
          onClick={() => {
            navigate({ to: "/", search: { category: "hotels" } });
          }}
        >
          <ArrowRight size={24} className="fill-slate-1100" />
        </IconButton>
      </div>
      <div className="flex items-center justify-between">
        <button
          onClick={filtersHandler}
          aria-label="filters-button"
          className="text-primary active:border-accent-700 inline-flex items-center gap-2 rounded-2xl bg-white px-4 py-2 text-sm"
        >
          <CaretDown /> {sortOptionsLabels[selectedSortOption]}
        </button>
        {hotelCount ? (
          <div className="text-md font-medium">
            {t("hotels.availableHotels", { hotelCount })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Header;
