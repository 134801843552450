import { Fragment, useState } from "react";
import { capitalizeHotelNames } from "@/app/lib/capitalizeHotelNames";
import { Star } from "@phosphor-icons/react/dist/ssr";
import { Button, Checkbox, Divider } from "@mui/material";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { getAgeFromDateOfBirth } from "@/app/lib/getAgefromDateOfBirth";
import { HotelBooking, THotelSearchResult } from "@/app/lib/types/types";
import { useTranslations } from "use-intl";
import { boardIcon } from "../hotels-list/HotelCard";
import MoreDetailsDrawer from "../flight-purchase/MoreDetailsDrawer";
import { HotelRoomTravelers } from "./HotelRoomTravelers";
import { HotelRoom } from "./HotelRoom";
import clsx from "clsx";
import { HotelOfferTotalPrice } from "./HotelOfferTotalPrice";
import { FormikProps } from "formik";

export interface SummaryFormValues {
  roomAssigments: Record<string, { travelers: string[] }>;
  leadTraveler: string;
  termsAndConditions: boolean;
}

export const HotelBookingSummary = ({
  hotel,
  hotelBookingData,
  form,
}: {
  hotel: THotelSearchResult;
  hotelBookingData: HotelBooking;
  form: FormikProps<SummaryFormValues>;
}) => {
  const t = useTranslations();
  const { getSelectedTravellers } = useTravellersContext();
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);

  const assignedTravelers = Object.values(form.values.roomAssigments).flatMap(
    (assigment) => assigment.travelers,
  );

  return (
    <div className="flex w-full flex-col px-4">
      <div className="mb-4 flex flex-row-reverse text-base font-medium">
        {t("hotels.bookingSummary")}
      </div>

      <div className="text-tertiary mb-2 flex flex-row-reverse text-sm font-medium">
        {t("hotels.overview.hotelDetails")}
      </div>
      <div className="flex justify-end gap-4 bg-white p-4 text-end">
        <div className="flex flex-col gap-2">
          <span className="font-semibol text-title text-end">
            {capitalizeHotelNames(hotel.name)}
          </span>
          {!!hotel.stars && (
            <div className="flex items-center justify-end gap-2">
              <div className="flex">
                {Array.from(Array(Number(hotel.stars))).map((_, index) => (
                  <Star key={index} size={12} color="#EF9C43" weight="fill" />
                ))}
              </div>
              <span className="text-tertiary text-sm">
                {t("hotels.card.stars", { stars: hotel.stars })}
              </span>
            </div>
          )}
          {hotel?.boardBasis?.map((bb) => (
            <div key={bb} className="flex items-center justify-end">
              <div className="text-tertiary flex items-center gap-2 text-sm">
                {t(`hotels.boardBasis.${bb}`)}
                {boardIcon[bb]}
              </div>
            </div>
          ))}
        </div>
        <div>
          {hotel.mainImageUrl && (
            <div className="flex-end flex w-full">
              <img
                className="min-h-32 min-w-24 rounded-2xl object-cover"
                width="90"
                height="130"
                src={hotel.mainImageUrl}
                alt={hotel.name}
              />
            </div>
          )}
        </div>
      </div>

      <div className="text-tertiary mt-4 mb-2 flex flex-row-reverse text-sm font-medium">
        {t("hotels.roomDetails")}
      </div>
      <div className="text-primary flex flex-col justify-end gap-4 bg-white p-4 text-end">
        {hotelBookingData.rooms.map((room) => (
          <div className="flex w-full flex-col" key={room.roomToken}>
            <HotelRoom
              room={room}
              hotel={hotel}
              visitorsCount={getSelectedTravellers.length}
            />
            <HotelRoomTravelers
              onTravelersSelect={(travelers) => {
                form.setFieldValue("roomAssigments", {
                  ...form.values.roomAssigments,
                  [room.roomToken]: {
                    travelers,
                  },
                });
              }}
              selectedTravelers={
                form.values.roomAssigments[room.roomToken]?.travelers ?? []
              }
              travelers={getSelectedTravellers.map((traveler) => {
                return {
                  ...traveler,
                  // check if the traveler is already assigned to a room
                  available: !assignedTravelers.includes(traveler.id),
                };
              })}
            />
            <Divider />
          </div>
        ))}
      </div>

      <div className="text-tertiary mt-4 mb-2 flex flex-row-reverse text-sm font-medium">
        {t("hotels.markMainTraveller")}
      </div>
      <div className="text-primary flex flex-col justify-end bg-white p-4 text-end">
        {getSelectedTravellers.map((traveller) => (
          <Fragment key={traveller.passportNumber}>
            <div className="flex items-start justify-between gap-2 not-first:mt-4">
              {getAgeFromDateOfBirth(traveller.birthDate) < 12 ? (
                <div />
              ) : (
                <label
                  className={clsx(
                    "main-traveller-form-control flex-shrink-0",
                    traveller.id === form.values.leadTraveler && "checked",
                  )}
                >
                  <input
                    className="main-traveller-checkbox"
                    onChange={() => {
                      form.setFieldValue("leadTraveler", traveller.id);
                    }}
                    type="radio"
                    disabled={getAgeFromDateOfBirth(traveller.birthDate) < 12}
                    value={traveller.id}
                    checked={traveller.id === form.values.leadTraveler}
                  />
                  {traveller.id === form.values.leadTraveler
                    ? t("travellers.markedMainTraveller")
                    : t("travellers.mainTraveller")}
                </label>
              )}
              <div>
                {traveller.firstName} {traveller.lastName}
              </div>
            </div>

            <div className="text-tertiary text-xs">
              {traveller.birthDate && (
                <>
                  {t("hotels.overview.age")}{" "}
                  {getAgeFromDateOfBirth(traveller.birthDate)}{" "}
                </>
              )}
            </div>
          </Fragment>
        ))}
      </div>

      <div className="mt-2 flex flex-col justify-end gap-4 bg-white p-4 text-end">
        <div className="text-primary text-[14px] font-semibold">
          {t("hotels.markMainTraveller")}
        </div>
        <div className="text-title text-[14px]">
          {t("hotels.bookingPolicy")}
        </div>
      </div>

      <div className="text-tertiary mt-4 mb-2 flex flex-row-reverse text-sm font-medium">
        {t("summary")}
      </div>
      <div className="flex flex-col justify-end bg-white p-4 text-end">
        <div className="flex justify-end">
          <div className="flex flex-col content-start">
            <Button
              onClick={() => {
                form.setFieldValue(
                  "termsAndConditions",
                  !form.values.termsAndConditions,
                );
              }}
              className="flex items-start p-0 text-right"
            >
              <div className="flex flex-col">
                <div className="text-primary text-[14px] font-semibold">
                  {t("hotels.acceptGeneralTerms")}
                </div>
                <div className="text-tertiary text-[14px]">
                  {t("hotels.acceptGeneralTermsWarning")}
                </div>
              </div>
              <Checkbox
                sx={{ pl: 1, pr: 0 }}
                checked={form.values.termsAndConditions}
              />
            </Button>
            <Button variant="text" onClick={() => setMoreDetailsOpen(true)}>
              {t("moreDetails")}
            </Button>
          </div>
        </div>
      </div>

      <div className="mt-2 mb-2 flex flex-col justify-end gap-4 bg-white p-4 text-end">
        <div className="flex flex-row-reverse justify-between">
          <div className="text-tertiary text-sm">{t("total")}</div>
          <div className="text-primary text-sm font-semibold">
            <HotelOfferTotalPrice />
          </div>
        </div>
        <div className="flex flex-row-reverse justify-between">
          <div className="text-tertiary text-sm">{t("paymentMethod")}</div>
          <div className="text-primary flex gap-2 text-sm font-semibold">
            {t("qiCard")}
          </div>
        </div>
      </div>

      <MoreDetailsDrawer
        open={moreDetailsOpen}
        onClose={() => setMoreDetailsOpen(false)}
      />
    </div>
  );
};
