import useSWR from "swr";
import { BASE_API_URL } from "../routes";
import { hotelsFetcher } from "./useHotelsSearch";
import { FindHotelOffersOutputDto } from "../types/codegen";
import { queryOptions, skipToken } from "@tanstack/react-query";
import api from "../api/api";
import { HotelOffer } from "../types/types";

interface HotelOfferQueryParams {
  hotelId: string;
  searchResultKey: string;
  searchResultToken: string;
}

export const hotelOfferQueryOptions = (params: HotelOfferQueryParams | null) =>
  queryOptions({
    queryKey: ["hotel-offer", params] as const,
    queryFn: params
      ? () =>
          api.get<{ data: HotelOffer[] }>(`/hotels/${params.hotelId}/offers`, {
            params: {
              searchResultKey: params.searchResultKey,
              searchResultToken: params.searchResultToken,
            },
          })
      : skipToken,
    select: (data) => data.data.data,
  });

export const useHotelOffer = (
  hotelId: string,
  searchResultKey: string,
  searchResultToken: string,
) => {
  const { data, error, isLoading } = useSWR<FindHotelOffersOutputDto>(
    () => {
      return hotelId
        ? {
            url: `${BASE_API_URL}/hotels/${hotelId}/offers`,
            params: { searchResultKey, searchResultToken },
          }
        : null;
    },
    hotelsFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  return {
    data,
    isLoading,
    isError: error,
  };
};
