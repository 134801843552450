import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { DateRange, DayPicker } from "react-day-picker";
import type { DayPickerProps, Matcher } from "react-day-picker";
import { ar } from "react-day-picker/locale";
import "react-day-picker/style.css";

import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

import { TextField } from "./StyledTz";

type FieldInfo = {
  path: string;
  value: string;
  label: string;
};

export type CalendarProps<T> = {
  startField: FieldInfo;
  endField?: FieldInfo;
  setFieldValue: FormikHelpers<T>["setFieldValue"];
  dropdown?: boolean;
  disabledDates?: Matcher;
};

export function Calendar<T>({
  startField,
  endField,
  setFieldValue,
  dropdown,
  disabledDates,
}: CalendarProps<T>) {
  const { dir, locale } = useLocaleInfo();

  const selectedDay: Date | undefined = dayjs(startField.value).isValid()
    ? dayjs(startField.value).toDate()
    : undefined;

  const selectedRange: DateRange = {
    from: dayjs(startField.value).isValid()
      ? dayjs(startField.value).toDate()
      : undefined,
    to: dayjs(endField?.value).isValid()
      ? dayjs(endField?.value).toDate()
      : undefined,
  };

  const setDateFieldValue = (path: string, date: Date | undefined) => {
    setFieldValue(path, date ? dayjs(date).format("YYYY-MM-DD") : "");
  };

  const handleSingleDateSelection = (date: Date) => {
    setDateFieldValue(startField.path, date);
  };

  const handleDateRangeSelection = (range: DateRange) => {
    // if both dates in range are already selected, clear the selection and select the new start date
    // it's easier to set wanted range this way
    if (selectedRange.from && selectedRange.to && endField) {
      const dateToSelect = dayjs(range.from).isBefore(selectedRange.from)
        ? range.from
        : range.to;
      setDateFieldValue(startField.path, dateToSelect);
      setDateFieldValue(endField.path, undefined);
      return;
    }

    if (range.from) {
      setDateFieldValue(startField.path, range.from);
    }
    if (range.to && endField) {
      setDateFieldValue(endField.path, range.to);
    }
  };

  const fromValue = endField?.value ? selectedRange.from : selectedDay;

  const formatWeekdayName = (date: Date) => {
    return dayjs(date).format("ddd");
  };

  const formatMonthCaption = (date: Date) => {
    return dayjs(date).format("MMMM YYYY");
  };

  const pickerProps = {
    className: "mt-8",
    locale: locale === "ar" ? ar : undefined,
    dir,
    formatters: {
      formatWeekdayName,
      formatMonthCaption,
    },
    startMonth: new Date(1940, 1),
    endMonth: new Date(2050, 1),
    captionLayout: dropdown ? "dropdown" : "label",
    disabled: disabledDates,
    defaultMonth: selectedDay || new Date(),
    required: true,
  } satisfies Partial<DayPickerProps>;

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-full gap-6" dir={dir}>
        <TextField
          value={fromValue ? dayjs(fromValue).format("ddd, D MMM") : ""}
          variant="filled"
          className="pointer-events-none w-[50%]"
          dir={dir}
          label={startField.label}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />
        {endField ? (
          <TextField
            value={
              selectedRange.to
                ? dayjs(selectedRange.to).format("ddd, D MMM")
                : ""
            }
            variant="filled"
            className="pointer-events-none w-[50%]"
            dir={dir}
            label={endField.label}
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        ) : (
          <div className="w-[50%]"></div>
        )}
      </div>

      {endField ? (
        <DayPicker
          {...pickerProps}
          mode="range"
          styles={{
            today: {
              boxShadow: "0 0 0 1px #CFD4DD !important",
              color: "#222930",
            },
            day_button: {
              width: "32px",
              height: "32px",
              margin: "0 8px",
            },
          }}
          classNames={{
            day: "text-primary",
            chevron: "fill-interactive",
            button_previous: "fill-interactive rotate-180 mb-4",
            button_next: "fill-interactive rotate-180 mb-4",
            month_caption: "text-interactive text-lg font-bold mb-2",
            selected: "selected-day",
          }}
          selected={selectedRange}
          onSelect={handleDateRangeSelection}
        />
      ) : (
        <DayPicker
          {...pickerProps}
          mode="single"
          styles={{
            day_button: {
              width: "32px",
              height: "32px",
              margin: "0 8px",
            },
            selected: {
              backgroundColor: "#003E2B", // Replace with your desired color
              color: "#FFFFFF", // Optional: text color for selected days
            },
            month_caption: {
              color: "#003E2B", // Replace with your desired color
              fontWeight: "bold", // Optional for bold text
            },
          }}
          classNames={{
            day: "text-primary",
            chevron: "fill-interactive",
            button_previous: "fill-interactive rotate-180 mb-4",
            button_next: "fill-interactive rotate-180 mb-4",
            today: "text-primary",
            month_caption: "text-interactive text-lg font-bold mb-2",
            selected: "selected-day",
          }}
          selected={selectedDay}
          onSelect={handleSingleDateSelection}
        />
      )}
    </div>
  );
}
