import axios from "axios";
import { BASE_API_URL, BASE_API_URL_V2 } from "../routes";
import qiAuth from "../qiAuth";

qiAuth();
import type { SignUpResponse } from "./apiTypes";

const config = {
  baseURL: BASE_API_URL,
};

const fetcher = axios.create(config);
export const api = axios.create(config);
export const apiV2 = axios.create({
  baseURL: BASE_API_URL_V2,
});
export const my: any = typeof window !== "undefined" && (window as any)?.my;
export const qi: any = typeof window !== "undefined" && (window as any)?.qi;
export const getQiToken = () => {
  console.log("Getting qi token from qi...", qi);

  const qiToken = qi?.user?.mobile.token;
  if (qiToken) {
    console.log("Got qi token", qiToken);

    typeof window !== "undefined" &&
      window?.localStorage?.setItem("qiToken", qiToken);
    return qiToken;
  }

  console.log("No qi token found in global, trying local storage...");
  return (
    (typeof window !== "undefined" &&
      window?.localStorage?.getItem("qiToken")) ||
    qiToken
  );
};

getQiToken();

export const getAuthCode = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    console.log("Asking `my` for auth code...", my);
    if (!my) {
      reject("No `my` object found");
    }
    my?.getAuthCode({
      scopes: [
        "USER_CONTACTINFO",
        "auth_user",
        "USER_NAME",
        "USER_LOGIN_ID",
        "USER_AVATAR",
        "USER_GENDER",
        "USER_BIRTHDAY",
        "USER_NATIONALITY",
      ],
      success: (res: { authCode: string }) => {
        const authCode = res.authCode;
        localStorage.setItem("qiAuthCode", authCode);
        console.log("Received auth code from `my`...");
        resolve(authCode);
      },
      fail: (error: Error) => {
        console.error("SuperQi: ", error);
        reject(error);
      },
    });
  });
};

function getToken() {
  return new Promise((resolve, reject) => {
    const qiToken = getQiToken();
    if (qiToken) {
      resolve(qiToken);
    } else {
      reject("No token found");
    }
  });
}

const signUp = async (authCode: string): Promise<SignUpResponse> => {
  console.log("Signing up to Super QI with auth code...");
  try {
    const { data } = await axios.post<SignUpResponse>(
      `${BASE_API_URL}/auth/super-qi/sign-up`,
      {
        authCode,
        miniProgramClient: "TRAVEL_ZONE",
      },
    );
    if (data.data.accessToken && data.data.refreshToken) {
      console.log("Tokens recevied from BE (Super QI)", data.data);
      localStorage.setItem("accessToken", data.data.accessToken);
      localStorage.setItem("refreshToken", data.data.refreshToken);
    } else {
      throw new Error("Invalid response: missing tokens");
    }
    return data;
  } catch (error) {
    my.showToast({
      type: "exception",
      content: (error as any).response.data.message,
      duration: 3000,
    });
    console.error("Sign up failed:", error);
    throw error;
  }
};

const qiServicesSignUp = async (token: string): Promise<SignUpResponse> => {
  console.log("Signing up to Qi Services with token...");
  try {
    const { data } = await axios.post<SignUpResponse>(
      `${BASE_API_URL}/auth/qi-services/sign-up`,
      {
        token,
      },
    );
    if (data.data.accessToken && data.data.refreshToken) {
      console.log("Tokens recevied from BE (Qi Services)", data.data);
      localStorage.setItem("accessToken", data.data.accessToken);
      localStorage.setItem("refreshToken", data.data.refreshToken);
    } else {
      throw new Error("Invalid response: missing tokens");
    }
    return data;
  } catch (error) {
    my.showToast({
      type: "exception",
      content: (error as any).response.data.message,
      duration: 3000,
    });
    console.error("Sign up failed:", error);
    throw error;
  }
};

export async function authorize() {
  console.log("Authorizing...");
  const qiToken = getQiToken();
  if (!qiToken) {
    console.log("No Qi token found, getting auth code...");
    await Promise.race([
      getAuthCode(),
      new Promise((_, reject) =>
        setTimeout(() => reject("Timeout getting auth code"), 15000),
      ),
    ]);
  }
  let token = localStorage.getItem("accessToken");
  if (token) {
    return token;
  } else {
    const qiAuthCode = localStorage.getItem("qiAuthCode");
    if (qiToken) {
      await qiServicesSignUp(qiToken);
    } else {
      await signUp(qiAuthCode!);
    }
    token = localStorage.getItem("accessToken");
    if (!token) {
      throw new Error("Failed to get access token after getAuthCode");
    }
    return token;
  }
}

api.interceptors.request.use(async (config) => {
  try {
    const token = await authorize();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } catch (error) {
    console.error("Error in getAuthCode:", error);
    throw error;
  }
});

apiV2.interceptors.request.use(async (config) => {
  try {
    const token = await authorize();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } catch (error) {
    console.error("Error in getAuthCode:", error);
    throw error;
  }
});

export const clearStorage = () => {
  localStorage.removeItem("qiAuthCode");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.config && error.response) {
      const oldRefreshToken = localStorage.getItem("refreshToken");
      if (error.response.status === 401 && !error.config.skipIntercept) {
        const newConfig = error.config;
        try {
          console.log("Refreshing token...");
          const { data } = await fetcher.post("/auth/tokens/refresh", {
            refreshToken: oldRefreshToken,
          });
          if (data.data) {
            const { accessToken, refreshToken } = data.data;
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("accessToken", accessToken);
            newConfig.headers.Authorization = `Bearer ${accessToken}`;
            newConfig.skipIntercept = true;
            return api.request(newConfig);
          }
        } catch (refreshTokenError) {
          my.showToast({
            type: "exception",
            content: "Failed to refresh token",
            duration: 3000,
          });
          clearStorage();
          return Promise.reject(refreshTokenError);
        }
      }
    }

    console.log(
      `API ERROR: [${error.config.method}] ${error.config.url} ${error.response ? error.response.status : "Unknown"}`,
    );
    return Promise.reject(error);
  },
);

apiV2.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.config && error.response) {
      const oldRefreshToken = localStorage.getItem("refreshToken");
      if (error.response.status === 401 && !error.config.skipIntercept) {
        const newConfig = error.config;
        try {
          console.log("Refreshing token...");
          const { data } = await fetcher.post("/auth/tokens/refresh", {
            refreshToken: oldRefreshToken,
          });
          if (data.data) {
            const { accessToken, refreshToken } = data.data;
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("accessToken", accessToken);
            newConfig.headers.Authorization = `Bearer ${accessToken}`;
            newConfig.skipIntercept = true;
            return api.request(newConfig);
          }
        } catch (refreshTokenError) {
          my.showToast({
            type: "exception",
            content: "Failed to refresh token",
            duration: 3000,
          });
          clearStorage();
          return Promise.reject(refreshTokenError);
        }
      }
    }

    console.log(
      `API ERROR: [${error.config.method}] ${error.config.url} ${error.response ? error.response.status : "Unknown"}`,
    );
    return Promise.reject(error);
  },
);

export default api;
