import { OfferIsNotAvailableAnymoreOutputDto } from "@/app/lib/types/codegen";
import { AxiosError } from "axios";
import PageError from "../PageError";
import { isOfferIsNotAvailableAnymoreError } from "@/app/lib/assertions";
import { OfferNotAvailableError } from "../OfferNotAvailableError";

interface FlightOfferErrorProps {
  error: Error | AxiosError<OfferIsNotAvailableAnymoreOutputDto>;
}

export const FlightOfferError = ({ error }: FlightOfferErrorProps) => {
  if (isOfferIsNotAvailableAnymoreError(error)) {
    return <OfferNotAvailableError variant="flight" />;
  }
  return <PageError />;
};
