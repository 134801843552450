import { createRootRoute, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { IntlProvider } from "use-intl";
import dayjs from "dayjs";
import enMessages from "../../messages/en.json";
import arMessages from "../../messages/ar.json";
import { SnackbarProvider } from "@/app/lib/context/SnackbarContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FlightDetailsProvider } from "@/app/lib/context/FlightDetailsContext";
import { FlightSelectionPayloadProvider } from "@/app/lib/context/FlightSelectionPayloadContext";
import { HotelDataProvider } from "@/app/lib/context/HotelDataProvider";
import { HotelsSearchDetailsProvider } from "@/app/lib/context/HotelSearchDetailsContext";
import { HotelsTokenProvider } from "@/app/lib/context/HotelsTokenProvider";
import { SWRConfig } from "swr";
import { isStaging } from "@/app/lib/constants";
import "dayjs/locale/ar";
import { HotelBookingContextProvider } from "@/app/lib/context/HotelBookingProvider";

const locale = "ar";
const messages = {
  en: enMessages,
  ar: arMessages,
};

dayjs.locale(locale);

declare module "@tanstack/react-router" {
  interface StaticDataRouteOption {
    stepper?: { step: number; presentationMode?: "modal" };
    footer?: false;
    drawer?: boolean;
  }
}

export const Route = createRootRoute({
  component: () => (
    <>
      <SWRConfig
        value={{
          revalidateOnFocus: false,
          revalidateOnReconnect: true,
          revalidateOnMount: true,
          revalidateIfStale: true,
        }}
      >
        <IntlProvider locale={locale} messages={messages[locale]}>
          <SnackbarProvider>
            {/* TODO: Move this to a specific route */}
            <FlightDetailsProvider>
              <FlightSelectionPayloadProvider>
                <HotelsSearchDetailsProvider>
                  <HotelsTokenProvider>
                    <HotelDataProvider>
                      <HotelBookingContextProvider>
                        <Outlet />
                      </HotelBookingContextProvider>
                    </HotelDataProvider>
                  </HotelsTokenProvider>
                </HotelsSearchDetailsProvider>
              </FlightSelectionPayloadProvider>
            </FlightDetailsProvider>
          </SnackbarProvider>
        </IntlProvider>

        <ReactQueryDevtools initialIsOpen={false} />
      </SWRConfig>
      {isStaging ? <TanStackRouterDevtools /> : null}
    </>
  ),
});
