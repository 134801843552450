"use client";

import {
  flightReservationQueryOptions,
  hotelReservationQueryOptions,
} from "@/app/lib/hooks/useReservation";
import { useNavigate } from "@tanstack/react-router";
import { MyHotel } from "./MyHotel";
import { useSuspenseQuery } from "@tanstack/react-query";
import { MyTripsHeader } from "./MyTripsHeader";
import { Suspense } from "react";
import Loader from "../AnimatedLoader";
import { MyFlight } from "./MyFlight";
import PageLoader from "../PageLoader";

interface MyTripProps {
  reservationId: string;
  scope: "hotels" | "flights";
}

export const MyTrip = ({ scope, reservationId }: MyTripProps) => {
  if (scope === "hotels") {
    return (
      <Suspense
        fallback={
          <PageLoader>
            <Loader icon="buildings" />
          </PageLoader>
        }
      >
        <MyHotelReservation reservationId={reservationId} />
      </Suspense>
    );
  }

  if (scope === "flights") {
    return (
      <Suspense
        fallback={
          <PageLoader>
            <Loader icon="airplane" />
          </PageLoader>
        }
      >
        <MyFlightReservation reservationId={reservationId} />
      </Suspense>
    );
  }

  return null;
};

function MyHotelReservation({ reservationId }: { reservationId: string }) {
  const { data } = useSuspenseQuery(
    hotelReservationQueryOptions(reservationId),
  );

  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  return (
    <>
      <MyTripsHeader
        reservation={data}
        onBackClick={() => navigate({ to: "/trips" })}
      />
      <MyHotel reservation={data} />
    </>
  );
}

function MyFlightReservation({ reservationId }: { reservationId: string }) {
  const { data } = useSuspenseQuery(
    flightReservationQueryOptions(reservationId),
  );

  const navigate = useNavigate();

  if (!data) {
    return null;
  }

  return (
    <>
      <MyTripsHeader
        reservation={data}
        onBackClick={() => navigate({ to: "/trips" })}
      />
      <MyFlight reservation={data} />
    </>
  );
}
