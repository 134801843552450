import { Chip } from "@mui/material";

import { useLocaleInfo } from "../lib/hooks/useLocaleInfo";

type Props = {
  overallTime?: string | null;
};

function FlightArrow({ overallTime }: Props) {
  const { dir } = useLocaleInfo();

  return (
    <div className="relative">
      {overallTime && (
        <Chip
          className="bg-accent-700 relative z-10 px-2 text-xs font-normal text-white"
          label={overallTime}
          dir={dir}
          size="small"
        />
      )}
      <div className={`flight-separator flight-separator--${dir}`} />
    </div>
  );
}

export default FlightArrow;
