import { useState } from "react";
import { Button, SwipeableDrawer } from "@mui/material";
import {
  CircleNotch,
  ClipboardText,
  CopySimple,
  DownloadSimple,
} from "@phosphor-icons/react";

// Create new plugin instance
import { TVoucherDoc } from "@/app/lib/types/types";
import { useReservationDocuments } from "@/app/lib/hooks/useReservationDocuments";
import { my, getQiToken } from "@/app/lib/api/api";
import { Puller } from "./StyledTz";
import { BASE_API_URL } from "@/app/lib/routes";

import { useTranslations } from "use-intl";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";

export const DownloadVoucher = ({
  doc,
  dataId,
  index,
}: {
  doc: TVoucherDoc;
  dataId: string;
  index: number;
}) => {
  const { mutate, isValidating, data } = useReservationDocuments(dataId);
  console.log(data);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [pdf, setPdf] = useState<any>(null);
  const showSnackbar = useSnackbar();
  const t = useTranslations();

  const qiToken = getQiToken();

  const handleClick = () =>
    mutate().then((res) => {
      const file = res?.data?.documents[index];
      setPdf(`${BASE_API_URL}/reservations/${dataId}/${index}/voucher.pdf`);
      const openLink = () => {
        const a = document.createElement("a");
        a.setAttribute(
          "href",
          "data:text/plain;charset=utf-8," + encodeURIComponent(file.link),
        );
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      };

      let path;
      if (my && !!file.link.length && !qiToken) {
        my.downloadFile({
          url: `${BASE_API_URL}/reservations/${dataId}/${index}/voucher.pdf`,
          success({ apFilePath }: { apFilePath: any }) {
            my.openDocument({
              filePath: apFilePath,
              fileType: "pdf",
            });
          },
          fail(res: any) {
            my.alert({
              content: res,
            });
          },
        });
      }

      if (!qiToken) openLink();
    });

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      showSnackbar(t("copied"), 90);
    });
  };

  return (
    <div className="my-3 rounded-lg border">
      {!qiToken && (
        <Button
          sx={{
            textTransform: "none",
            fontSize: "14px",
            width: "100%",
            borderRadius: "8px",
            height: "40px",
          }}
          variant="contained"
          onClick={handleClick}
          startIcon={
            isValidating ? (
              <CircleNotch size={24} />
            ) : (
              <DownloadSimple size={24} />
            )
          }
        >
          <span>Download {doc?.name || "voucher"}</span>
        </Button>
      )}
      {qiToken && (
        <a
          href={`https://docs.google.com/viewerng/viewer?url=${BASE_API_URL}/reservations/${dataId}/${index}/voucher.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          <button style={{ padding: "10px 20px", cursor: "pointer" }}>
            Download PDF
          </button>
        </a>
      )}
    </div>
  );
};
