import { Card, Avatar, Chip, Divider } from "@mui/material";
import { Airplane, Armchair } from "@phosphor-icons/react/dist/ssr";
import { useTranslations } from "use-intl";
import type { GetFlightAvailabilityTokenSegment } from "@/app/lib/types/codegen";
import aircraftTypes from "@/aircraftTypes.json";
import dayjs from "dayjs";

const aircrafts: Record<string, string> = aircraftTypes;

function FlightDetailsCard({
  segment,
}: {
  segment: GetFlightAvailabilityTokenSegment;
}) {
  const t = useTranslations();
  const segmentDuration = segment.duration
    ? t("durationValue", {
        hours: segment.duration.split(":")[0]?.trim(),
        minutes: segment.duration.split(":")[1]?.trim(),
      })
    : null;
  // TODO: Sometimes backend doesnt return airplane name,
  // but does return code iata code https://www.avcodes.co.uk/acrtypes.asp
  const airplaneName =
    segment.equipment.name ||
    aircrafts[segment.equipment.code] ||
    segment.equipment.code;
  const doesOperatingCarrierDiffer =
    segment.operatingCarrier.airline.name !==
    segment.marketingCarrier.airline.name;
  const flightNumber = segment.marketingCarrier.flightNumber;
  const cabinClass = segment.cabinClasses[0];

  return (
    <Card
      className="flex min-h-[252px] flex-grow justify-end gap-4 rounded-2xl bg-white p-4"
      sx={{ boxShadow: "none" }}
    >
      <div className="flex flex-col items-end justify-between text-right">
        <div>
          <h3 className="text-title font-semibold">
            {segment.departure?.airport.code}
          </h3>
          <span className="text-tertiary text-sm">
            {segment.departure?.airport.name}
          </span>
        </div>
        <div className="flex flex-col items-end gap-2">
          <div className="flex items-center gap-2">
            <span className="text-primary text-xs">
              {segment.marketingCarrier.airline?.name || t("unknownAirline")}
            </span>
            <Avatar
              alt={`${segment.marketingCarrier.airline.name} logo`}
              sx={{ width: 16, height: 16 }}
              src={segment.marketingCarrier.airline.logoUrl}
            />
          </div>
          {doesOperatingCarrierDiffer ? (
            <div className="flex items-center gap-2">
              <span className="text-tertiary text-xs">
                {t("flights.operatedBy")}{" "}
                {segment.operatingCarrier.airline.name}
              </span>
              <Avatar
                alt={`${segment.operatingCarrier.airline.name} logo`}
                sx={{ width: 16, height: 16 }}
                src={segment.operatingCarrier.airline.logoUrl}
              />
            </div>
          ) : null}
          {airplaneName && flightNumber && (
            <div className="flex items-center gap-2">
              <span className="text-primary text-xs">
                {airplaneName} {flightNumber}
              </span>
              <Airplane size={16} />
            </div>
          )}
          <div className="flex items-center gap-2">
            {cabinClass?.type ? (
              <>
                <span className="text-primary text-xs">{cabinClass.type}</span>
                <Armchair size={16} />
              </>
            ) : null}
          </div>
        </div>
        <div>
          <h3 className="text-title font-semibold">
            {segment.arrival?.airport.code}
          </h3>
          <span className="text-tertiary text-sm">
            {segment.arrival?.airport.name}
          </span>
        </div>
      </div>
      <Divider orientation="vertical" className="bg-accent-700 h-54" flexItem />
      <div className="flex flex-col items-end justify-between">
        <div className="flex flex-col items-end">
          <h3 className="text-title text-xl font-semibold">
            {segment.departure?.time}
          </h3>
          <span className="text-tertiary text-end text-sm">
            {segment.departure?.date
              ? dayjs(segment.departure.date).format("ddd, DD MMM")
              : null}
          </span>
        </div>
        <div className="flex flex-col items-center gap-1">
          {segmentDuration && (
            <Chip
              className="bg-accent-700 relative z-10 px-2 text-xs font-normal text-white"
              label={segmentDuration}
              color="primary"
              dir="rtl"
              size="small"
            />
          )}
        </div>
        <div className="flex flex-col items-end">
          <h3 className="text-title text-xl font-semibold">
            {segment.arrival?.time}
          </h3>
          <span className="text-tertiary text-end text-sm">
            {segment.arrival?.date
              ? dayjs(segment.arrival.date).format("ddd, DD MMM")
              : null}
          </span>
        </div>
      </div>
    </Card>
  );
}

export default FlightDetailsCard;
