import { useQuery } from "@tanstack/react-query";

import useDebouncedValue from "./useDebouncedValue";
import api from "../api/api";
import { FindCitiesOutputDto } from "../types/codegen";

export const hotelsFetcher = <TResponse, TParams = unknown>({
  url,
  params,
}: {
  url: string;
  params: TParams;
}) => {
  return api
    .get<TResponse>(url, {
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const hotelsPostFetcher = <TResponse, TParams = unknown>({
  url,
  params,
}: {
  url: string;
  params: TParams;
}) => {
  return api
    .post<TResponse>(url, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

// Custom hook for cities search
export function useHotelsSearch(searchQuery: string) {
  const debouncedSearch = useDebouncedValue(searchQuery.trim(), 500);

  const { data, isError, isLoading } = useQuery({
    queryKey: ["hotels-search", debouncedSearch] as const,
    queryFn: () =>
      hotelsFetcher<FindCitiesOutputDto>({
        url: `/mappings/cities`,
        params: { searchQuery: debouncedSearch },
      }),
    select: (data) => data?.data || [],
    enabled: debouncedSearch.length > 2,
    staleTime: 1000 * 60 * 60 * 24, // fetch fresh translations after 24 hours
  });

  return {
    data,
    isLoading,
    isError,
  };
}
