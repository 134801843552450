import { Button } from "@mui/material";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { useFormik } from "formik";
import { HotelBookingSummary, SummaryFormValues } from "./HotelBookingSummary";
import {
  BookingResult,
  useHotelBooking,
} from "@/app/lib/hooks/useHotelBooking";
import * as yup from "yup";
import Loader from "../AnimatedLoader";
import BookingError from "../BookingError";
import PageLoader from "../PageLoader";
import { PersistentDrawer } from "../PersistentDrawer";
import { useTranslations } from "use-intl";
import { transformHotelTraveler } from "@/app/lib/utils/transformTraveler";
import { HotelBooking, THotelSearchResult } from "@/app/lib/types/types";

interface HotelBookingSummaryFormProps {
  onSuccess: (response: BookingResult) => void;
  availabilityToken: string;
  searchResultKey: string;
  searchResultToken: string;
  booking: HotelBooking;
  hotel: THotelSearchResult;
}

export const HotelBookingSummaryForm = ({
  onSuccess,
  availabilityToken,
  searchResultKey,
  searchResultToken,
  booking,
  hotel,
}: HotelBookingSummaryFormProps) => {
  const t = useTranslations();
  const { isPending, isError, error, mutateAsync } = useHotelBooking({
    onSuccess,
  });

  const { getSelectedTravellers } = useTravellersContext();

  const validationSchema = yup.object().shape({
    termsAndConditions: yup.boolean().required().isTrue(),
    leadTraveler: yup.string().required(),
    roomAssigments: yup
      .object()
      .shape(
        Object.fromEntries(
          booking.rooms.map((room) => [
            room.roomToken,
            yup.object({
              travelers: yup
                .array()
                .of(yup.string().required())
                .length(getSelectedTravellers.length),
            }),
          ]),
        ),
      )
      .required(),
  });

  const formik = useFormik<SummaryFormValues>({
    initialValues: {
      roomAssigments: {},
      leadTraveler: "",
      termsAndConditions: false,
    },
    onSubmit: (values) =>
      mutateAsync({
        availabilityToken,
        searchResultKey,
        searchResultToken,
        hotelId: `${hotel!.id}`,
        offerId: booking!.offerId,
        rooms: Array.from(Object.entries(values.roomAssigments)).map(
          ([packageRoomToken, assigment]) => ({
            packageRoomToken,
            travelers: assigment.travelers.map((t) => {
              const traveler = getSelectedTravellers.find(
                (trav) => trav.id === t,
              );

              return transformHotelTraveler(traveler!, {
                isLead: values.leadTraveler === t,
              });
            }),
          }),
        ),
      }),
    validationSchema,
  });

  if (isError) {
    return <BookingError error={error} type="hotels" />;
  }

  if (isPending) {
    return (
      <PageLoader>
        <Loader icon="buildings" />
      </PageLoader>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <HotelBookingSummary
        hotel={hotel}
        hotelBookingData={booking}
        form={formik}
      />

      <PersistentDrawer>
        <Button
          type="submit"
          variant="contained"
          className="w-full"
          disabled={!formik.isValid || !formik.dirty}
          sx={{
            borderRadius: "999px",
            padding: "16px",
            textTransform: "none",
          }}
        >
          {t("hotels.overview.bookRoom")}
        </Button>
      </PersistentDrawer>
    </form>
  );
};
