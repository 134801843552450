import useInfiniteScroll from "react-infinite-scroll-hook";
import Loader from "../AnimatedLoader";
import { useTranslations } from "use-intl";
import NoResultsError from "../NoResultsError";
import type { FindUserHotelsReservationsReservation } from "@/app/lib/types/codegen";
import { useHotelsReservations } from "@/app/lib/hooks/useHotelsReservations";
import { MyHotelCard } from "./MyHotelCard";
import PageLoader from "../PageLoader";

export const MyHotelsList = ({
  onReservationClick,
}: {
  onReservationClick: (flight: FindUserHotelsReservationsReservation) => void;
}) => {
  const t = useTranslations();
  const { data, setSize, hasMore, isLoading, isValidating, error } =
    useHotelsReservations();

  const isEmpty = data?.length === 0;

  const [sentryRef] = useInfiniteScroll({
    loading: isValidating,
    hasNextPage: hasMore,
    onLoadMore: () => {
      setSize((prev) => prev + 1);
    },
    disabled: !!error,
    rootMargin: "0px 0px 40px 0px",
  });

  if (isLoading) {
    return (
      <PageLoader>
        <Loader icon="buildings" />
      </PageLoader>
    );
  }

  if (isEmpty) return <NoResultsError tab="hotels" />;

  return (
    <div className="mt-2">
      {data?.map((page) =>
        page.data.reservations.map((reservation) => (
          <MyHotelCard
            onClick={() => onReservationClick(reservation)}
            key={reservation.id}
            reservation={reservation}
          />
        )),
      )}

      {isValidating || hasMore ? (
        <div
          className="flex items-center justify-center py-4"
          ref={sentryRef}
          aria-hidden={!isValidating}
        >
          <Loader icon="buildings" />
        </div>
      ) : (
        <div className="text-tertiary mb-9 flex items-center justify-center p-8">
          {t("hotels.noResults")}
        </div>
      )}
    </div>
  );
};
