import { OfferIsNotAvailableAnymoreOutputDto } from "@/app/lib/types/codegen";
import { AxiosError } from "axios";
import PageError from "../PageError";
import { isOfferIsNotAvailableAnymoreError } from "@/app/lib/assertions";
import { OfferNotAvailableError } from "../OfferNotAvailableError";

interface HotelOfferErrorProps {
  error: Error | AxiosError<OfferIsNotAvailableAnymoreOutputDto> | null;
}

export const HotelOfferError = ({ error }: HotelOfferErrorProps) => {
  if (error && isOfferIsNotAvailableAnymoreError(error)) {
    return <OfferNotAvailableError variant="hotel" />;
  }
  return <PageError back={{ to: "/", search: { category: "hotels" } }} />;
};
