import { THotelSearchResult } from "@/app/lib/types/types";

interface HotelImageListProps {
  hotel: THotelSearchResult;
  onImageClick: (index: number) => void;
}

export function HotelImageList({ onImageClick, hotel }: HotelImageListProps) {
  return (
    <div className="grid w-full grid-cols-2 gap-4 px-4 py-4">
      {hotel.imagesUrls.map((url, index) => (
        <button
          type="button"
          key={url}
          className="relative h-32 overflow-hidden"
          onClick={() => {
            onImageClick(index + 1);
          }}
        >
          <img
            src={url}
            alt={hotel.name}
            className="h-full w-full rounded-lg object-cover object-center"
          />
        </button>
      ))}
    </div>
  );
}
