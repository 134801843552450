import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";
import TravellersList from "@/app/ui/travellers/TravellersList";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/_flight-offer/flights/offer/$id/step/2",
)({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <MuiThemeProvider>
      <TravellersList controlled={true} />;
    </MuiThemeProvider>
  );
}
