"use client";

import React, {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

export interface Tokens {
  progressToken: string;
  searchResultKey: string;
  searchResultToken: string;
}

interface HotelsTokensContextValue {
  data: Partial<Tokens> | null;
  setData: Dispatch<SetStateAction<Partial<Tokens> | null>>;
}

const HotelsContextContext = createContext<HotelsTokensContextValue>({
  data: null,
  setData: () => {},
});

export const HotelsTokenProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [data, set] = useState<HotelsTokensContextValue["data"]>(null);

  return (
    <HotelsContextContext.Provider value={{ data, setData: set }}>
      {children}
    </HotelsContextContext.Provider>
  );
};

export const useOptionalHotelsTokensContext = () => {
  const context = useContext(HotelsContextContext);
  if (!context) {
    throw new Error(
      "useHotelsTokensContext must be used within a HotelsContextProvider",
    );
  }
  return context;
};
