import { Drawer } from "@mui/material";
import { MuiThemeProvider } from "../lib/context/MuiThemeProvider";
import { PropsWithChildren, RefObject, useLayoutEffect, useRef } from "react";
import useResizeObserver from "@react-hook/resize-observer";

const useDrawerSize = (target: RefObject<HTMLDivElement>) => {
  useLayoutEffect(() => {
    document.body.style.setProperty(
      "--drawer-height",
      `${target.current?.getBoundingClientRect().height || 0}px`,
    );
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => {
    document.body.style.setProperty(
      "--drawer-height",
      `${entry.target.getBoundingClientRect().height}px`,
    );
  });
};

export const PersistentDrawer = ({ children }: PropsWithChildren) => {
  const target = useRef<HTMLDivElement>(null);
  useDrawerSize(target);

  return (
    <MuiThemeProvider>
      <Drawer
        PaperProps={{ ref: target, sx: { paddingBottom: "10px" } }}
        anchor="bottom"
        variant="persistent"
        open={true}
      >
        <div className="w-full p-4">{children}</div>
      </Drawer>
    </MuiThemeProvider>
  );
};
