import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";
import Footer from "@/app/ui/Footer";
import { MyTrip } from "@/app/ui/my-trips/MyTrip";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/trips_/$scope/$id")({
  params: {
    parse: (
      rawParams: Record<string, string>,
    ): { scope: "flights" | "hotels"; id: string } => {
      if (!["flights", "hotels"].includes(rawParams.scope)) {
        throw new Error("Invalid scope");
      }
      return {
        scope: rawParams.scope as "flights" | "hotels",
        id: rawParams.id,
      };
    },
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { scope, id } = Route.useParams();

  return (
    <MuiThemeProvider>
      <main className="flex h-screen flex-col overflow-hidden bg-slate-100 pt-6 pb-14">
        <div className="flex-grow overflow-auto">
          <div className="min-h-full w-full bg-slate-100 p-4">
            <MyTrip scope={scope} reservationId={id} />
          </div>
        </div>

        <Footer />
      </main>
    </MuiThemeProvider>
  );
}
