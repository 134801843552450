import { Card, Avatar, Divider } from "@mui/material";
import { SuitcaseRolling } from "@phosphor-icons/react/dist/ssr";
import { extractAirlineCarriers } from "@/app/lib/utils/extractAirlineCarriers";
import { useTranslations } from "use-intl";
import dayjs from "dayjs";
import type { Itierary, ItieraryFlight } from "@/app/lib/types/types";
import { MuiThemeProvider } from "../lib/context/MuiThemeProvider";
import FlightArrow from "./FlightArrow";

const FlightInfo = ({ flight }: { flight: ItieraryFlight }) => {
  const firstSegment = flight.segments[0];
  const lastSegment = flight.segments[flight.segments.length - 1];
  const t = useTranslations();

  const timeArray = flight.duration?.split(":") || null;
  const overallTime = timeArray?.length
    ? t("durationValue", {
        hours: timeArray[0]?.trim(),
        minutes: timeArray[1]?.trim(),
      })
    : null;

  const doesOperatingCarrierDiffer =
    flight?.segments?.[0]?.operatingCarrier &&
    flight.segments[0].operatingCarrier.airline.name !==
      flight.segments[0].marketingCarrier.airline.name;
  return (
    <div className="flex items-end justify-between">
      <TimeInfo
        date={firstSegment.departure.date}
        time={firstSegment.departure.time}
        code={firstSegment.departure.airport.code}
      />
      <div className="flex flex-col">
        <FlightDetails overallTime={overallTime} stops={flight.stops?.length} />
        {doesOperatingCarrierDiffer && (
          <div className="text-tertiary text-center text-sm">{`${t("flights.operatedBy")} ${firstSegment.operatingCarrier.airline.name}`}</div>
        )}
      </div>
      <TimeInfo
        date={lastSegment.arrival.date}
        time={lastSegment.arrival.time}
        code={lastSegment.arrival.airport.code}
        isArrival
      />
    </div>
  );
};

const TimeInfo = ({
  date,
  time,
  code,
  isArrival = false,
}: {
  date: string;
  time: string;
  code: string;
  isArrival?: boolean;
}) => (
  <div className={`flex flex-col ${isArrival ? "text-end" : "text-start"}`}>
    <span className="text-tertiary text-sm">
      {dayjs(date).format("MMMM DD")}
    </span>
    <h3 className="text-title text-xl font-semibold">{time.slice(0, 5)}</h3>
    <span className="text-tertiary text-sm">{code}</span>
  </div>
);

export const FlightDetails = ({
  overallTime,
  stops,
}: {
  stops: number;
  overallTime: string | null;
}) => {
  const t = useTranslations();

  return (
    <div className="flex flex-col items-center gap-1">
      <FlightArrow overallTime={overallTime} />
      <span className="text-tertiary text-sm">
        {stops
          ? `${stops} ${stops === 1 ? t("flights.stop") : t("flights.stops")}`
          : t("flights.direct")}
      </span>
    </div>
  );
};

function SingleAvailableFlight({
  itinerary,
  onClick,
}: {
  itinerary: Itierary;
  onClick?: () => void;
}) {
  const t = useTranslations();
  const allAirlines = extractAirlineCarriers(
    itinerary.flights.flatMap((f) => f.segments),
  );

  const isBaggageIncluded = itinerary.flights.some((flight) =>
    flight.segments.some((segment) =>
      segment.baggages?.some((baggage) => Number(baggage.quantity) > 0),
    ),
  );

  return (
    <MuiThemeProvider>
      <Card
        dir="rtl"
        className="flex flex-col gap-4 rounded-2xl bg-white p-4 hover:cursor-pointer"
        onClick={onClick}
        sx={{
          boxShadow: "none",
          minHeight: "193px",
          borderRadius: "16px",
          margin: "8px 0",
        }}
      >
        <div className="flex w-full items-center gap-2">
          <div className="flex w-fit flex-row-reverse">
            {allAirlines.map((airline, index) => (
              <Avatar
                alt={`${airline.name} company logo`}
                sx={{
                  width: 24,
                  height: 24,
                  marginRight: index !== 0 ? "-8px" : 0,
                  zIndex: allAirlines.length - index,
                }}
                key={airline.name}
                src={airline.logoUrl}
              >
                {airline.name?.charAt(0) || ""}
              </Avatar>
            ))}
          </div>
          <div className="flex max-w-[250px] flex-row-reverse">
            {allAirlines.slice(0, 2).map((airline, index) => (
              <span
                key={airline.name || index}
                className="text-primary truncate text-sm"
              >
                {airline.name || t("unknownAirline")}
                {index !== 0 ? " ," : ""}
              </span>
            ))}
            {allAirlines.length > 2 && (
              <span className="text-primary mr-1 text-sm">
                +{allAirlines.length - 2}
              </span>
            )}
          </div>
        </div>

        <FlightInfo flight={itinerary.flights[0]} />
        {itinerary.flights[1] && <FlightInfo flight={itinerary.flights[1]} />}

        {itinerary.totalPrice ? (
          <>
            <Divider />
            <div className="flex flex-row-reverse items-center justify-between">
              <span className="text-primary text-sm font-semibold">
                {itinerary.totalPrice.toLocaleString()} {itinerary.currency}
              </span>

              {isBaggageIncluded && (
                <div className="flex items-center gap-2">
                  <span className="text-tertiary text-xs">
                    {t("flights.baggageIncluded")}
                  </span>
                  <SuitcaseRolling size={16} />
                </div>
              )}
            </div>
          </>
        ) : null}
      </Card>
    </MuiThemeProvider>
  );
}

export default SingleAvailableFlight;
