import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import type { ItieraryFlight } from "../types/types";

dayjs.extend(duration);

export function calculateOverallFlightTime(flight: ItieraryFlight | null) {
  const firstSegmentOfDeparture = flight?.segments?.[0].departure;
  const lastSegmentOfArrival =
    flight?.segments?.[flight?.segments?.length - 1].arrival;

  // construct dates from strings
  const timeOfDeparture = dayjs(
    firstSegmentOfDeparture?.date + " " + firstSegmentOfDeparture?.time,
  );
  const timeOfArrival = dayjs(
    lastSegmentOfArrival?.date + " " + lastSegmentOfArrival?.time,
  );

  // Calculate the difference in minutes
  const diffInMinutes = timeOfArrival.diff(timeOfDeparture, "minute");

  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  return {
    hours: String(hours).padStart(2, "0"),
    minutes: String(minutes).padStart(2, "0"),
  };
}
