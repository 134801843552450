import { useHotelBookingContext } from "@/app/lib/context/HotelBookingProvider";
import { useHotelDataContext } from "@/app/lib/context/HotelDataProvider";

export function HotelOfferTotalPrice() {
  const { data: hotelBooking } = useHotelBookingContext();
  const { data: hotelData } = useHotelDataContext();
  const totalPrice = hotelBooking?.rooms.reduce(
    (acc, room) => acc + room.price.value,
    0,
  );

  return (
    <>
      {totalPrice} {hotelData.minPrice?.currency}
    </>
  );
}
