import { Card, CircularProgress } from "@mui/material";
import { useTranslations } from "use-intl";

import { getBaggageInfo } from "@/app/lib/utils/baggageProcessor";
import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";

import BaggageInfo from "./BaggageInfo";

function BaggageAllowance({
  singleFlightDetails,
  isAdditionalDataLoading,
}: {
  singleFlightDetails: SingleFightDetailsData | null;
  isAdditionalDataLoading: boolean;
}) {
  const t = useTranslations();
  const baggageInfo = getBaggageInfo(singleFlightDetails);

  return (
    <div className="my-4">
      <span className="text-tertiary mb-2 block w-full text-end text-xs">
        {t("flights.baggageDetails")}
      </span>
      <Card
        dir="rtl"
        className="flex flex-col gap-4 rounded-2xl bg-white p-4"
        sx={{ boxShadow: "none" }}
      >
        <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
          {isAdditionalDataLoading ? (
            <>
              ({t("flights.retrievingBaggageDetails")}
              <CircularProgress size={20} />)
            </>
          ) : !baggageInfo ? (
            <>{t("flights.noBaggage")}</>
          ) : (
            <div className="flex w-full flex-col gap-2">
              <h3 className="text-primary text-sm font-semibold">
                {t("flights.totalBaggageAllowance")}
              </h3>

              <BaggageInfo baggageInfo={baggageInfo} />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export default BaggageAllowance;
