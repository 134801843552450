import { useEffect, useState } from "react";
import { useTranslations } from "use-intl";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import {
  SwipeableDrawer,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  CaretDown,
  AirplaneTakeoff,
  AirplaneLanding,
  CaretUpDown,
  X,
} from "@phosphor-icons/react/dist/ssr";
import { Button, TextField, Puller } from "../StyledTz";
import { FormikContext, useFormik } from "formik";
import { TripType, CabinClass, AirportType } from "@/app/lib/types/enums";
import { initialFlightsValues } from "@/app/lib/initialFlightsValues";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { getQiToken } from "@/app/lib/api/api";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";
import { string, object, date, number, ref } from "yup";
import { useFlightDetailsProvider } from "@/app/lib/context";
import AirportSearch from "./AirportSearch";
import TravellersSelection from "./TravellersSelection";
import DatesSelection from "./DatesSelection";
import dayjs from "dayjs";
import { useNavigate } from "@tanstack/react-router";
import { MuiThemeProvider } from "../../lib/context/MuiThemeProvider";

enum EDrawerComponent {
  AirportSearch = "AirportSearch",
  DatesSelection = "DatesSelection",
  PassengersSelection = "PassengersSelection",
}

const cabinClassLabels = {
  [CabinClass.ECONOMY]: "economy",
  [CabinClass.BUSINESS]: "business",
  [CabinClass.PREMIUM_ECONOMY]: "premium",
  [CabinClass.FIRST_CLASS]: "firstClass",
};

type InitialFlightsValuesKeys = keyof typeof initialFlightsValues;

const validationSchema = object().shape({
  dates: object().shape({
    departure: date()
      .required("flights.validation.dates.departure")
      .test(
        "departureRequired",
        "flights.validation.dates.minDeparture",
        (date) => {
          if (!date) return true;
          return !dayjs(date).endOf("day").isBefore(dayjs());
        },
      ),
    return: date()
      .optional()
      .min(ref("departure"), "flights.validation.dates.minReturn")
      .test(
        "returnRequiredIfRoundTrip",
        "flights.validation.dates.return",
        (date, context) => {
          const [, formValues] = context.from!;

          return formValues.value.tripType !== TripType.ROUND_TRIP || !!date;
        },
      ),
  }),
  from: object({
    name: string()
      .required("flights.validation.from")
      .max(30, "flights.validation.from"),
  }),
  to: object({
    name: string()
      .required("flights.validation.to")
      .max(30, "flights.validation.from"),
  }),
  tripType: string().required("flights.validation.tripType"),
  cabinClass: string().required("flights.validation.cabinClass"),
  // number of adults (??)
  adult: number().min(1),
  child: number().min(0),
});

function FlightCalculator() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState<{
    drawer: EDrawerComponent;
    params?: { focus?: AirportType };
  } | null>(null);
  const { dir } = useLocaleInfo();
  const qi = getQiToken();
  const { updateSearchData } = useFlightDetailsProvider();
  const showSnackbar = useSnackbar();
  const t = useTranslations();

  const handleDrawerOpen = (
    component: EDrawerComponent,
    params?: { focus?: AirportType },
  ) => {
    setDrawerOpen(true);
    setDrawerComponent({ drawer: component, params });
  };

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: initialFlightsValues,
    onSubmit: (values) => {
      updateSearchData(values);
      navigate({ to: "/flights/list" });
    },
  });

  const { setFieldValue } = formik;
  const { departure: departureDate, return: returnDate } = formik.values.dates;

  // make sure we don't have a return date before the departure date
  useEffect(() => {
    if (dayjs(returnDate).isBefore(dayjs(departureDate))) {
      setFieldValue("dates.return", departureDate);
    }
  }, [departureDate, returnDate, setFieldValue]);

  const passengerCount =
    formik.values.adult + formik.values.child + formik.values.infantWithoutSeat;

  formik.values.travellers = ` ${passengerCount > 1 ? t("persons") : t("person")} ${passengerCount}, ${t(
    `flights.cabinClass.${cabinClassLabels[formik.values.cabinClass]}`,
  )}`;

  const changeAirportValues = () => {
    formik.setFieldValue("from", formik.values.to);
    formik.setFieldValue("to", formik.values.from);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      if (getFormikErrorsRecursive(res).length > 0) {
        showSnackbar(getFormikErrorsRecursive(res, t)[0], 90);
      } else {
        formik.handleSubmit();
      }
    });
  };

  const onMouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement>,
    type: InitialFlightsValuesKeys,
    component: EDrawerComponent,
  ) => {
    if (
      // TODO: Quick solution, find better solution for this post mvp
      (e.target as HTMLElement).namespaceURI === "http://www.w3.org/2000/svg"
    ) {
      e.preventDefault();
      formik.setFieldValue(type, initialFlightsValues[type]);
    } else {
      e.preventDefault();
      handleDrawerOpen(component, { focus: type as AirportType });
    }
  };

  const departureValue = dayjs(formik.values.dates.departure).format(
    "ddd, D MMM",
  );

  const returnValue = formik.values.dates.return
    ? dayjs(formik.values.dates.return).format("ddd, D MMM")
    : "_";

  return (
    <MuiThemeProvider>
      <FormikContext.Provider value={formik}>
        <div className="w-full rounded-3xl p-4">
          <form noValidate onSubmit={onSubmit} acceptCharset="ISO-8859-1">
            <RadioGroup
              row
              aria-labelledby="trip-type"
              name="tripType"
              className="mb-5 flex justify-center"
              dir={dir}
              value={formik.values.tripType}
              onChange={(e) => {
                const { departure: departureDate, return: returnDate } =
                  formik.values.dates;

                if (dayjs(returnDate).isBefore(dayjs(departureDate))) {
                  formik.setFieldValue("dates.return", departureDate);
                }

                formik.handleChange(e);
              }}
            >
              <FormControlLabel
                value="one-way"
                control={<Radio />}
                label={t("flights.search.oneWay")}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                  },
                }}
              />
              <FormControlLabel
                value="round-trip"
                control={<Radio />}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 14,
                  },
                }}
                label={t("flights.search.roundTrip")}
              />
            </RadioGroup>
            <div className="mb-4 flex flex-col gap-4">
              <div className="relative flex flex-col gap-4">
                <TextField
                  fullWidth
                  onMouseDown={(e) => {
                    onMouseDownHandler(
                      e,
                      "from",
                      EDrawerComponent.AirportSearch,
                    );
                  }}
                  InputProps={{
                    endAdornment: formik.values.from.name && (
                      <>
                        <button className="absolute left-3">
                          <X size={20} color="#6A778A" />
                        </button>
                        {!formik.values.from?.airports && (
                          <span className="text-placeholder absolute left-9 text-sm">
                            {formik.values.from.code}
                          </span>
                        )}
                      </>
                    ),
                  }}
                  sx={{
                    input: {
                      paddingLeft: "70px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      userSelect: "none",
                    },
                  }}
                  value={formik.values.from.city}
                  variant="filled"
                  dir={dir}
                  label={
                    <div className="flex items-center gap-2">
                      <AirplaneTakeoff size={24} />
                      <span>{t("flights.search.from")}</span>
                    </div>
                  }
                />
                <CaretUpDown
                  onClick={changeAirportValues}
                  height={40}
                  width={26}
                  color="white"
                  className="absolute start-5 top-11 z-10 rounded-2xl bg-[#3B3C3D] hover:cursor-pointer"
                />
                <TextField
                  fullWidth
                  value={formik.values.to.city}
                  variant="filled"
                  dir={dir}
                  onMouseDown={(e) => {
                    onMouseDownHandler(e, "to", EDrawerComponent.AirportSearch);
                  }}
                  InputProps={{
                    endAdornment: formik.values.to.name && (
                      <>
                        <button className="absolute left-3 z-10">
                          <X size={20} color="#6A778A" />
                        </button>
                        {!formik.values.to?.airports && (
                          <span className="text-placeholder absolute left-9 text-sm">
                            {formik.values.to.code}
                          </span>
                        )}
                      </>
                    ),
                  }}
                  label={
                    <div className="flex items-center gap-2">
                      <AirplaneLanding size={24} />
                      <span>{t("flights.search.to")}</span>
                    </div>
                  }
                  sx={{
                    input: {
                      paddingLeft: "70px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      userSelect: "none",
                    },
                  }}
                />
              </div>
              <TextField
                onMouseDown={(e) => {
                  onMouseDownHandler(
                    e,
                    "dates.departure" as InitialFlightsValuesKeys,
                    EDrawerComponent.DatesSelection,
                  );
                }}
                fullWidth
                variant="filled"
                label={t("flights.search.departureDate")}
                InputProps={{
                  endAdornment: formik.values.dates.departure && (
                    <button type="button" className="absolute end-5 z-10">
                      <X size={20} color="#6A778A" />
                    </button>
                  ),
                }}
                dir={dir}
                value={
                  formik.values.tripType === TripType.ROUND_TRIP
                    ? `${departureValue} - ${returnValue}`
                    : departureValue
                }
              />
              <TextField
                fullWidth
                id="passengersNo"
                label={t("flights.search.travellers")}
                className="w-full p-0"
                dir={dir}
                InputProps={{
                  endAdornment: (
                    <CaretDown
                      className="absolute end-5"
                      color="black"
                      size={24}
                    />
                  ),
                }}
                variant="filled"
                value={formik.values.travellers}
                onMouseDown={(e) => {
                  e.preventDefault();
                  handleDrawerOpen(EDrawerComponent.PassengersSelection);
                }}
              />
            </div>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                borderRadius: "16px",
              }}
            >
              {t("flights.search.searchFlight")}
            </Button>
            <SwipeableDrawer
              anchor="bottom"
              onClose={() => setDrawerOpen(false)}
              onOpen={() => setDrawerOpen(true)}
              open={drawerOpen}
              sx={{
                "& .MuiDrawer-paper": {
                  borderRadius: "32px 32px 0 0",
                  height: qi ? "90vh" : "100vh",
                  paddingBottom: "env(safe-area-inset-bottom)",
                  overflow: "hidden",
                },
              }}
            >
              <Puller />
              {drawerComponent?.drawer === EDrawerComponent.AirportSearch &&
                drawerOpen && (
                  <AirportSearch
                    formProps={formik}
                    focus={drawerComponent.params?.focus}
                    onDrawerClose={() => setDrawerOpen(false)}
                  />
                )}
              {drawerComponent?.drawer === EDrawerComponent.DatesSelection &&
                drawerOpen && (
                  <DatesSelection
                    formProps={formik}
                    onDrawerClose={() => setDrawerOpen(false)}
                  />
                )}
              {drawerComponent?.drawer ===
                EDrawerComponent.PassengersSelection &&
                drawerOpen && (
                  <TravellersSelection
                    formProps={formik}
                    onDrawerClose={() => setDrawerOpen(false)}
                  />
                )}
            </SwipeableDrawer>
          </form>
        </div>
      </FormikContext.Provider>
    </MuiThemeProvider>
  );
}

export default FlightCalculator;
