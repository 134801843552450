import { useTranslations } from "use-intl";
import clsx from "clsx";
import {
  type FormikProps,
  FieldArray,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import { object } from "yup";
import { User, Person, X } from "@phosphor-icons/react";

import type { THotelFormValues, TRoom } from "@/app/lib/types/types";

import PlusMinusButton from "../CounterButton";
import { Button, TextField } from "../StyledTz";
import { roomsValidationSchema } from "./validation.ts";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

function VisitorsSelection({
  onClose,
  formProps,
}: {
  onClose: () => void;
  formProps: FormikProps<THotelFormValues>;
}) {
  const t = useTranslations();
  const { dir } = useLocaleInfo();

  const formik = useFormik({
    initialValues: {
      rooms: formProps.values.rooms,
    },
    validationSchema: object().shape({
      rooms: roomsValidationSchema,
    }),
    onSubmit: (values) => {
      formProps.setFieldValue("rooms", values.rooms);
      onClose();
    },
  });

  const newRoom: TRoom = {
    adults: 1,
    children: 0,
    childrenAges: [],
  };

  const { values, handleBlur } = formik;
  const roomsError = formik?.errors?.rooms as TRoom[] | undefined;
  const roomsTouched = formik?.touched?.rooms as TRoom[] | undefined;

  return (
    <FormikProvider value={formik}>
      <Form noValidate>
        <div className="flex h-full w-full flex-col p-4">
          {/* TODO: show this when IRIX will fix the issue with booking multiple rooms */}
          {/* <div className="mb-3">
            <h2 className="font-base text-title text-center font-medium">
              {t("hotels.search.visitors")}
            </h2>
          </div> */}

          <FieldArray
            name="rooms"
            render={(arrayHelpers) => (
              <div>
                <div
                  // TODO: show this when IRIX will fix the issue with booking multiple rooms
                  style={{ display: "none" }}
                  className={clsx(
                    "border-stroke-neutral mb-3 flex items-center justify-between border-b py-2",
                    {
                      "flex-row-reverse text-right": dir === "rtl",
                    },
                  )}
                >
                  <div className="flex">
                    <h4 className="text-primary font-medium">
                      {t("hotels.search.rooms")}
                    </h4>
                  </div>

                  <PlusMinusButton
                    value={values.rooms.length}
                    minValue={1}
                    maxValue={5}
                    onChange={(value) =>
                      value < values.rooms.length
                        ? arrayHelpers.remove(value)
                        : arrayHelpers.insert(value, newRoom)
                    }
                  />
                </div>

                {values.rooms &&
                  values.rooms.length > 0 &&
                  values.rooms.map((room, roomIndex) => (
                    <div
                      key={roomIndex}
                      className="border-stroke-neutral mb-3 w-full border-b"
                    >
                      <div
                        // TODO: show this when IRIX will fix the issue with booking multiple rooms
                        style={{ display: "none" }}
                        className="flex w-full justify-center pb-4"
                      >
                        <h2 className="font-medium">
                          {t("hotels.search.room")} #{roomIndex + 1}
                        </h2>
                      </div>
                      <span
                        className={clsx(
                          "text-tertiary mb-4 inline-block w-full text-xs",
                          {
                            "text-right": dir === "rtl",
                          },
                        )}
                      >
                        {t("hotels.search.visitors")}
                      </span>
                      <div
                        className={clsx(
                          "flex items-center justify-between py-2",
                          {
                            "flex-row-reverse text-right": dir === "rtl",
                          },
                        )}
                      >
                        <div className="flex items-center">
                          <h4 className="text-primary font-medium">
                            {t("hotels.search.adult")}
                          </h4>
                          <User size={20} className="fill-secondary ml-4" />
                        </div>
                        <PlusMinusButton
                          value={room.adults}
                          onChange={(value) =>
                            arrayHelpers.replace(roomIndex, {
                              ...room,
                              adults: value,
                            })
                          }
                          minValue={1}
                          maxValue={9}
                        />
                      </div>
                      <div>
                        <div
                          className={clsx(
                            "flex items-center justify-between py-2",
                            {
                              "flex-row-reverse text-right": dir === "rtl",
                            },
                          )}
                        >
                          <div className="flex items-center">
                            <h4 className="text-primary font-medium">
                              {t("hotels.search.child")}
                            </h4>
                            <Person size={20} className="fill-secondary ml-4" />
                          </div>
                          <PlusMinusButton
                            value={room.children}
                            onChange={(value) => {
                              arrayHelpers.replace(roomIndex, {
                                ...room,
                                children: value,
                                childrenAges: Array.from(
                                  Array(value).keys(),
                                ).map((age, i) => room.childrenAges[i]),
                              });
                            }}
                            maxValue={2}
                          />
                        </div>
                        {room.children > 0 &&
                          Array.from(Array(room.children).keys()).map(
                            (childIndex) => (
                              <div
                                key={childIndex}
                                className={clsx(
                                  "flex items-center justify-between py-2",
                                  {
                                    "flex-row-reverse text-right":
                                      dir === "rtl",
                                  },
                                )}
                              >
                                <h4 className="text-primary font-medium">
                                  {t("hotels.search.child")} {childIndex + 1}
                                </h4>
                                <TextField
                                  name={`rooms[${roomIndex}].childrenAges[${childIndex}]`}
                                  variant="filled"
                                  size="small"
                                  error={
                                    !!roomsTouched?.at(roomIndex)?.childrenAges[
                                      childIndex
                                    ] &&
                                    !!roomsError?.at(roomIndex)?.childrenAges[
                                      childIndex
                                    ]
                                  }
                                  label={t("hotels.search.childsAge")}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    const newValue = e.target.value
                                      ? Number(e.target.value)
                                      : NaN;

                                    arrayHelpers.replace(roomIndex, {
                                      ...room,
                                      childrenAges: room.childrenAges.map(
                                        (age, i) =>
                                          // if value is a number, store it as a number, otherwise store it as-is
                                          // validation should not allow to proceed with non-numeric values anyway
                                          i === childIndex
                                            ? isNaN(newValue)
                                              ? e.target.value
                                              : newValue
                                            : age,
                                      ),
                                    });
                                  }}
                                  type="text"
                                  inputProps={{
                                    inputMode: "numeric",
                                    pattern: "d*",
                                  }}
                                  InputProps={{
                                    endAdornment: room.childrenAges[
                                      childIndex
                                    ] && (
                                      <button
                                        className="absolute right-3 bottom-2"
                                        type="button"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            `rooms[${roomIndex}].childrenAges[${childIndex}]`,
                                            "",
                                          );
                                        }}
                                      >
                                        <X
                                          size={20}
                                          className="fill-placeholder"
                                        />
                                      </button>
                                    ),
                                  }}
                                  value={room.childrenAges[childIndex]}
                                />
                              </div>
                            ),
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          />

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{
              borderRadius: "999px",
              padding: "16px",
            }}
          >
            {t("hotels.confirm")}
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default VisitorsSelection;
