import HotelsCalculator from "../hotels/HotelsCalculator";
import FlightCalculator from "../flights/FlightCalculator";
import ScopeSwitcher from "../ScopeSwitcher";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";

interface SearchProps {
  category: "flights" | "hotels";
}

export const Search = ({ category = "flights" }: SearchProps) => {
  if (category === "hotels") {
    return (
      <MuiThemeProvider>
        <div className="sticky top-10 z-0 my-8 flex w-full items-center justify-center">
          <ScopeSwitcher current={category} />
        </div>
        <div className="flex w-full flex-col items-center justify-center rounded-3xl bg-white text-black shadow-md">
          <HotelsCalculator />
        </div>
      </MuiThemeProvider>
    );
  }

  return (
    <>
      <div className="sticky top-10 z-30 m-60 my-8 flex w-full items-center justify-center">
        <ScopeSwitcher current={category} />
      </div>
      <div className="w-full rounded-t-3xl">
        <div className="flex w-full flex-col items-center justify-center rounded-3xl bg-white text-black shadow-md">
          <FlightCalculator />
        </div>
      </div>
    </>
  );
};
