import { string, object, ref, date, number, array } from "yup";

export const roomsValidationSchema = array()
  .of(
    object().shape({
      adults: number().integer().min(1),
      children: number().integer(),
      childrenAges: array()
        .of(
          number()
            .integer()
            .min(0, "hotels.validation.childrenAge")
            .max(12, "hotels.validation.childrenAge")
            .required(),
        )
        .length(ref("children"), "hotels.validation.childrenAge"),
    }),
  )
  .min(1, "hotels.validation.roomsMin")
  .max(5, "hotels.validation.roomsMax")
  .required();

export const validationSchema = object().shape({
  destination: object().shape({
    name: string()
      .required("hotels.validation.destination")
      .max(30, "hotels.validation.destination"),
    id: number().required(),
  }),
  dates: object().shape({
    checkIn: date().required("hotels.validation.dates.checkIn"),
    checkOut: date()
      .min(ref("checkIn"), "hotels.validation.dates.min")
      .required("hotels.validation.dates.checkOut"),
  }),
  rooms: roomsValidationSchema,
  travelPurpose: string().required(),
});
