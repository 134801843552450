"use client";

import { createContext, useContext, useState, ReactNode } from "react";
import { THotelSearchResult } from "../types/types";

interface HotelDataContextValue {
  data: THotelSearchResult | null;
  setData: (data: THotelSearchResult) => void;
}

const HotelDataContext = createContext<HotelDataContextValue>({
  data: null,
  setData: () => {},
});

export const HotelDataProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = useState<HotelDataContextValue["data"]>(null);

  return (
    <HotelDataContext.Provider value={{ data, setData }}>
      {children}
    </HotelDataContext.Provider>
  );
};

export const useOptionalHotelDataContext = () => useContext(HotelDataContext);
export const useHotelDataContext = (): {
  [Key in keyof HotelDataContextValue]: NonNullable<HotelDataContextValue[Key]>;
} => {
  const value = useOptionalHotelDataContext();
  if (!value || !value.data) {
    throw new Error(
      "useHotelDataContext must be used within a HotelDataProvider and have a value",
    );
  }
  return {
    data: value.data,
    setData: value.setData,
  };
};
