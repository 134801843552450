import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  mapFlightReservation,
  useFlightsReservations,
} from "@/app/lib/hooks/useFlightsReservations";
import Loader from "../AnimatedLoader";
import { useTranslations } from "use-intl";
import SingleAvailableFlight from "../SingleAvailableFlight";
import NoResultsError from "../NoResultsError";
import type { FindUserFlightsReservationsReservation } from "@/app/lib/types/codegen";
import PageLoader from "../PageLoader";

export const MyFlightsList = ({
  onReservationClick,
  page,
}: {
  onReservationClick: (flight: FindUserFlightsReservationsReservation) => void;
  page?: number;
}) => {
  const t = useTranslations();
  const { data, setSize, hasMore, isLoading, isValidating, error } =
    useFlightsReservations(page || 1);

  console.log(data);
  const isEmpty = data?.length === 0;

  const [sentryRef] = useInfiniteScroll({
    loading: isValidating,
    hasNextPage: hasMore,
    onLoadMore: () => {
      setSize((prev) => prev + 1);
    },
    disabled: !!error,
    rootMargin: "0px 0px 40px 0px",
  });

  if (isLoading) {
    return (
      <PageLoader>
        <Loader icon="airplane" />
      </PageLoader>
    );
  }

  if (isEmpty) return <NoResultsError tab="flights" />;
  console.dir(data, { depth: null });
  return (
    <div className="mt-2">
      {data?.map((page) =>
        page.data.reservations.map((reservation) => (
          <SingleAvailableFlight
            key={reservation.id}
            onClick={() => onReservationClick(reservation)}
            itinerary={{
              totalPrice: 0,
              flights: reservation.flights.map((flight) =>
                mapFlightReservation(flight),
              ),
            }}
          />
        )),
      )}

      {isValidating || hasMore ? (
        <div
          className="flex items-center justify-center py-4"
          ref={sentryRef}
          aria-hidden={!isValidating}
        >
          <Loader icon="airplane" />
        </div>
      ) : (
        <div className="text-tertiary flex items-center justify-center p-8">
          {t("flights.noResults")}
        </div>
      )}
    </div>
  );
};
