import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataProvider";
import { validateIndex } from "@/app/lib/routeValidation";
import { HotelGallery } from "@/app/ui/hotel/HotelGallery";
import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/_hotel-offer/hotels/$id/rooms_/images_/fullscreen",
)({
  validateSearch: validateIndex,
  component: RouteComponent,
  staticData: {
    stepper: {
      presentationMode: "modal",
      step: 1,
    },
    footer: false,
    drawer: false,
  },
});

function RouteComponent() {
  const { index } = Route.useSearch();
  const navigate = Route.useNavigate();

  const { data: hotel } = useOptionalHotelDataContext();

  if (!hotel) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    return <Navigate to=".." from={Route.fullPath} />;
  }

  return (
    <HotelGallery
      hotel={hotel}
      initialIndex={index}
      onBackClick={() => navigate({ to: ".." })}
    />
  );
}
