import AdditionalInfoCard from "@/app/ui/AdditionalInfoCard";
import BaggageAllowance from "@/app/ui/BaggageAllowance";
import FlightDetailsCard from "@/app/ui/FlightDetailsCard";
import { useSingleFlightSelectionContext } from "@/app/lib/context/SingleFlightDetailsContext";
import { Chip } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import { MuiThemeProvider } from "../../../app/lib/context/MuiThemeProvider";

export const Route = createFileRoute(
  "/_authenticated/_flight-offer/flights/offer/$id/step/1",
)({
  component: RouteComponent,
});

function RouteComponent() {
  const {
    departureSegments,
    returnSegments,
    departureFlight,
    returnFlight,
    isAdditionalDataLoading,
    singleFlightDetails,
  } = useSingleFlightSelectionContext();
  const t = useTranslations();
  const departureStops = departureFlight?.stops || [];
  const returnStops = returnFlight?.stops || [];

  return (
    <MuiThemeProvider>
      <div className="px-4">
        <div className="mb-4">
          <span className="text-tertiary my-2 block w-full text-end text-xs font-medium">
            {t("flights.departure")}
          </span>
          {departureSegments?.map((segment, index) => (
            <div
              className="flex flex-col"
              key={segment.arrival.date + segment.departure.time}
            >
              <FlightDetailsCard segment={segment} />
              {departureStops.length > 0 && departureStops[index] && (
                <Chip
                  className="mx-auto my-4"
                  dir="rtl"
                  label={t("flights.flightStop", {
                    hours: departureStops[index].stopTime.split(":")[0],
                    minutes: departureStops[index].stopTime.split(":")[1],
                    cityName: segment.arrival.city.name,
                    code: segment.arrival.airport.code,
                  })}
                />
              )}
            </div>
          ))}
        </div>
        {returnSegments && (
          <div className="mb-4">
            <span className="text-tertiary my-2 block w-full text-end text-xs font-medium">
              {t("flights.search.return")}
            </span>
            {returnSegments?.map((segment, index) => (
              <div
                className="flex flex-col"
                key={segment.arrival.date + segment.departure.time}
              >
                <FlightDetailsCard segment={segment} />
                {returnStops &&
                  returnStops?.length > 0 &&
                  returnStops[index] && (
                    <Chip
                      className="mx-auto my-4"
                      dir="rtl"
                      label={t("flights.flightStop", {
                        hours: returnStops[index].stopTime.split(":")[0],
                        minutes: returnStops[index].stopTime.split(":")[1],
                        cityName: segment.arrival.city.name,
                        code: segment.arrival.airport.code,
                      })}
                    />
                  )}
              </div>
            ))}
          </div>
        )}
        {/* Baggage */}
        <BaggageAllowance
          singleFlightDetails={singleFlightDetails}
          isAdditionalDataLoading={isAdditionalDataLoading}
        />

        {/* Additional Info */}
        <AdditionalInfoCard
          singleFlightDetails={singleFlightDetails}
          isAdditionalDataLoading={isAdditionalDataLoading}
        />
      </div>
    </MuiThemeProvider>
  );
}
