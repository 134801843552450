import { MobileStepper } from "@mui/material";
import { useTranslations } from "use-intl";

import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

const TOTAL_STEPS = 3;

const Stepper = ({
  stepNumber,
  stepTitle,
}: {
  stepNumber: number;
  stepTitle: string;
}) => {
  const t = useTranslations();
  const { dir } = useLocaleInfo();

  return (
    <div className="px-4" dir={dir}>
      <MuiThemeProvider>
        <MobileStepper
          variant="progress"
          steps={TOTAL_STEPS + 1} // 0 is a valid step so we need to add 1
          position="static"
          activeStep={stepNumber}
          dir={dir}
          nextButton={null}
          backButton={null}
        />
      </MuiThemeProvider>
      <div className="mb-4 flex w-full items-center justify-end gap-2">
        <span className="text-tertiary text-xs">
          {t("flights.step")} {stepNumber}/{TOTAL_STEPS}
        </span>
        <h5 className="text-title text-xs font-semibold">{t(stepTitle)}</h5>
      </div>
    </div>
  );
};

export default Stepper;
