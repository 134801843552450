import { useState } from "react";
import useSWR from "swr";

import { BASE_API_URL } from "../routes";
import { hotelsFetcher } from "./useHotelsSearch";
import { CheckHotelsSearchProgressOutputDto } from "../types/codegen";

export const useHotelSearchProgress = (
  searchProgressToken: string | undefined,
  searchResultKey: string | undefined,
  searchResultToken: string | undefined,
) => {
  const [searchProgressInterval, setSearchProgressInterval] =
    useState<number>(100);

  const { data, error, isLoading, isValidating } =
    useSWR<CheckHotelsSearchProgressOutputDto>(
      () => {
        return searchProgressToken && searchResultKey && searchResultToken
          ? {
              url: `${BASE_API_URL}/hotels/search/progress`,
              params: {
                searchProgressToken,
                searchResultKey,
                searchResultToken,
              },
            }
          : null;
      },
      hotelsFetcher,
      {
        onSuccess: (data) => {
          if (data.data.status === "COMPLETED") {
            setSearchProgressInterval(0);
          }
          if (data.data.status === "IN_PROGRESS") {
            setSearchProgressInterval(100);
          }
        },
        revalidateIfStale: false,
        revalidateOnFocus: false,
        refreshInterval: searchProgressInterval,
      },
    );

  return {
    data,
    isLoading,
    isValidating,
    isError: error,
  };
};
