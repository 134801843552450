import { useState } from "react";
import { useDebounce } from "react-use";

const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    delay,
    [value],
  );

  return debouncedValue;
};

export default useDebouncedValue;
