"use client";

import { useEffect, useState } from "react";
import useSWR from "swr";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { X } from "@phosphor-icons/react/dist/ssr";
import PageLoader from "@/app/ui/PageLoader";
import { BASE_API_URL } from "@/app/lib/routes";
import useDebouncedValue from "@/app/lib/hooks/useDebouncedValue";
import { THotelFormValues, THotelSearchResult } from "@/app/lib/types/types";
import { LinearProgress, SwipeableDrawer } from "@mui/material";
import HotelListHeader from "./HotelListHeader";
import HotelsListSort from "./HotelsListSort";
import { DrawerComponent, HotelsSortOptions } from "@/app/lib/types/enums";
import { useHotelSearchProgress } from "@/app/lib/hooks/useHotelSearchProgress";
import { HotelCard } from "./HotelCard";
import HotelsFilters from "./HotelsFilters";
import { IHotelsFilters } from "@/app/lib/types/interfaces";
import AnimatedLoader from "../AnimatedLoader";
import { useHotelSearchResultKey } from "@/app/lib/hooks/useHotelSearchResultKey";
import PageError from "../PageError";
import NoResultsError from "../NoResultsError";
import { useOptionalHotelsTokensContext } from "@/app/lib/context/HotelsTokenProvider";
import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataProvider";
import { useTranslations } from "use-intl";
import { hotelsPostFetcher } from "@/app/lib/hooks/useHotelsSearch";
import { WhatsAppButton } from "../WhatsAppButton";
import { useNavigate } from "@tanstack/react-router";

const dataMapper = (searchData: THotelFormValues) => {
  return {
    cityId: searchData.destination.id,
    checkIn: searchData.dates.checkIn,
    checkOut: searchData.dates.checkOut,
    travelPurpose: searchData.travelPurpose,
    occupancy: {
      leaderNationality: 1,
      rooms: searchData.rooms.map(({ adults, childrenAges }) => ({
        adults,
        childrenAges,
      })),
    },
  };
};

const SORT_DIRECTION = "ASC";
const SORT_FIELD = "price";
const PRICES = [1, 999999];
const BOARD_BASIS = ["RO", "SC", "BB", "HB", "FB", "AI", "RD"];

interface HotelsListProps {
  searchData: THotelFormValues;
}

function HotelsList({ searchData }: HotelsListProps) {
  const { data: tokens, setData: setTokens } = useOptionalHotelsTokensContext();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [drawerComponent, setDrawerComponent] = useState("");
  const [sortValue, setSortValue] = useState(HotelsSortOptions.RATING);
  const { setData: setHotelData } = useOptionalHotelDataContext();
  const t = useTranslations();

  const handleOverviewOpen = (hotel: THotelSearchResult) => {
    setHotelData(hotel);
    navigate({
      to: "/hotels/$id/details",
      params: { id: `${hotel.id}` },
    });
  };

  const handleDrawerOpen = (component: DrawerComponent) => {
    setDrawerComponent(component);
    setDrawerOpen(true);
  };

  const debouncedSearch = useDebouncedValue(dataMapper(searchData!), 500);
  const { data: progressData, isLoading: isLoadingProgressData } =
    useHotelSearchProgress(
      tokens?.progressToken,
      tokens?.searchResultKey,
      tokens?.searchResultToken,
    );

  const [filters, setFilters] = useState<IHotelsFilters>({
    priceRange: [
      progressData?.data.minPrice || PRICES[0],
      progressData?.data.maxPrice || PRICES[1],
    ],
    minPrice: progressData?.data.minPrice,
    maxPrice: progressData?.data.maxPrice,
    sortField: SORT_FIELD,
    sortOrder: SORT_DIRECTION,
    boardBasis: progressData?.data?.boardBasis || BOARD_BASIS,
    starRating: progressData?.data?.stars || [1, 2, 3, 4, 5],
  });

  const shouldFetch = progressData?.data?.progress === 100;

  const {
    data: searchResultData,
    isLoading: isLoadingData,
    isValidating: isValidatingData,
    isError,
    hasReachedEnd,
    isEmpty,
    setSize,
  } = useHotelSearchResultKey(
    tokens?.searchResultKey,
    filters,
    tokens?.searchResultToken,
    shouldFetch,
    sortValue,
  );

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const { isLoading: isLoadingTokens } = useSWR(
    () => {
      return searchData && debouncedSearch
        ? {
            url: `${BASE_API_URL}/hotels/search`,
            params: { ...debouncedSearch },
          }
        : null;
    },
    hotelsPostFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onSuccess: (data) => {
        setTokens((prev) => ({
          ...prev,
          progressToken: data.data.searchProgressToken!,
          searchResultKey: data.data.searchResultKey!,
          searchResultToken: data.data.searchResultToken!,
        }));
      },
    },
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isValidatingData,
    hasNextPage: !hasReachedEnd,
    onLoadMore: () => {
      setSize((prev) => prev + 1);
    },
    disabled: isError,
    rootMargin: "0px 0px 40px 0px",
  });

  const handleFiltersChange = (newFilters: Partial<IHotelsFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (progressData?.data.status === "COMPLETED") {
      setFilters((prev) => ({
        ...prev,
        priceRange: [
          progressData?.data.minPrice || PRICES[0],
          progressData?.data.maxPrice || PRICES[1],
        ],
        minPrice: progressData?.data.minPrice,
        maxPrice: progressData?.data.maxPrice,
        boardBasis: progressData?.data?.boardBasis || BOARD_BASIS,
        // TODO: fix starRating type
        // starRating: progressData?.data?.stars || [1, 2, 3, 4, 5],
      }));
    }
  }, [progressData]);

  if (isError) {
    return <PageError />;
  }

  const progress = Number(progressData?.data?.progress || 0);
  const isLoading =
    isLoadingTokens || isLoadingData || isLoadingProgressData || progress < 100;

  if (!searchData || isLoading) {
    return (
      <>
        {progress < 100 ? (
          <LinearProgress
            variant="determinate"
            sx={{
              direction: "rtl",
              transform: "rotate(180deg)",
            }}
            value={progress}
          />
        ) : null}

        <PageLoader>
          <AnimatedLoader icon="buildings" />
          <h3 className="text-primary mt-3 font-normal">
            {t("hotels.preparingBestOffers")}
          </h3>
        </PageLoader>
      </>
    );
  }

  return (
    <div className="bg-slate-100">
      <HotelListHeader
        selectedSortOption={sortValue}
        filtersHandler={() => {
          handleDrawerOpen(DrawerComponent.HOTELS_SORT);
        }}
        sortHandler={() => {
          handleDrawerOpen(DrawerComponent.HOTELS_FILTERS);
        }}
        hotelCount={progressData?.data?.countHotels}
      />

      <WhatsAppButton />

      {isEmpty ? (
        <NoResultsError tab="hotels" />
      ) : (
        <div className="text-primary w-full px-4 text-lg font-normal">
          {searchResultData?.map(
            (hotels: { data: THotelSearchResult[]; status: string }) => {
              return hotels.data.map((hotel, index) => (
                <HotelCard
                  onClick={() => handleOverviewOpen(hotel)}
                  key={index}
                  hotel={hotel}
                />
              ));
            },
          )}

          {isValidatingData || !hasReachedEnd ? (
            <div
              className="flex items-center justify-center py-4"
              ref={sentryRef}
              aria-hidden={!isValidatingData}
            >
              <AnimatedLoader icon="buildings" />
            </div>
          ) : (
            <div className="mb-9 flex items-center justify-center p-8">
              {t("hotels.noMoreResults")}
            </div>
          )}
        </div>
      )}

      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            display: "flex",
            flexDirection: "column",
            borderRadius:
              DrawerComponent.HOTELS_SORT === drawerComponent
                ? "0"
                : "32px 32px 0 0",
            paddingBottom: "env(safe-area-inset-bottom)",
            minHeight:
              drawerComponent == DrawerComponent.HOTELS_SORT ? "35vh" : "90vh",
          },
        }}
      >
        {DrawerComponent.HOTELS_SORT == drawerComponent ? (
          <>
            <button
              className="absolute top-6 right-4 hover:cursor-pointer"
              onClick={() => setDrawerOpen(false)}
              aria-label="close drawer"
            >
              <X size={20} className="fill-interactive" width="bold" />
            </button>
            <div
              className="flex justify-center"
              style={{
                padding: "24px 0 13px 0",
              }}
            >
              <h2 className="text-title font-medium">
                {t("hotels.filters.sort")}
              </h2>
            </div>
            <HotelsListSort
              handleDrawerClose={handleDrawerClose}
              sortValue={sortValue}
              setSortValue={setSortValue}
            />
          </>
        ) : (
          <HotelsFilters
            handleDrawerClose={handleDrawerClose}
            sortValue={sortValue}
            setSortValue={setSortValue}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        )}
      </SwipeableDrawer>
    </div>
  );
}

export default HotelsList;
