import * as yup from "yup";

export const validateIndex = (search: Record<string, unknown>) => {
  return {
    index: yup.number().required().default(0).cast(search.index),
  };
};

export const validateOrderId = (search: Record<string, unknown>) => {
  return {
    orderId: yup.string().required().cast(search.orderId),
  };
};
