"use client";

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  useTravelers,
  useCreateTraveller,
  useUpdateTraveller,
  useDeleteTraveller,
} from "@/app/lib/hooks/useTravellers";
import type { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
import { getAgeFromDateOfBirth } from "../getAgefromDateOfBirth";
import { my } from "../api/api";
import { useTranslations } from "use-intl";

const defaultContextValue: {
  travellers: FindUserTravelersOutputData[];
  getSelectedTravellers: FindUserTravelersOutputData[];
  selectedTravellers: string[];
  addTraveller: (traveller: FindUserTravelersOutputData) => Promise<void>;
  editTraveller: (traveller: FindUserTravelersOutputData) => Promise<void>;
  removeTraveller: (id: string) => void;
  toggleTravellerSelection: (id: string) => void;
  handleLeadTraveller: (traveller: FindUserTravelersOutputData) => void;
  leadTraveller: FindUserTravelersOutputData | null;
  isReady: boolean;
} = {
  travellers: [],
  selectedTravellers: [],
  getSelectedTravellers: [],
  addTraveller: Promise.resolve,
  editTraveller: Promise.resolve,
  removeTraveller: () => {},
  toggleTravellerSelection: () => {},
  handleLeadTraveller: () => {},
  leadTraveller: null,
  isReady: false,
};

const TravellersContext =
  createContext<typeof defaultContextValue>(defaultContextValue);

export const TravellersProvider = ({ children }: { children: ReactNode }) => {
  const { data, isError, mutate, isLoading } = useTravelers(true);
  const [selectedTravellers, setSelectedTravellers] = useState<string[]>([]);
  const [leadTraveller, setLeadTraveller] =
    useState<FindUserTravelersOutputData | null>(null);
  const createTraveler = useCreateTraveller();
  const updateTraveler = useUpdateTraveller();
  const deleteTraveler = useDeleteTraveller();
  const t = useTranslations();

  const getSelectedTravellers: FindUserTravelersOutputData[] =
    data.travelers?.filter((traveller: FindUserTravelersOutputData) =>
      selectedTravellers.includes(traveller.id),
    );

  const handleLeadTraveller = (traveller: FindUserTravelersOutputData) => {
    setLeadTraveller(traveller);
  };

  const addTraveller = async (traveller: FindUserTravelersOutputData) => {
    try {
      await createTraveler(traveller);
      mutate(); // Refresh the travellers list
    } catch {
      my.showToast({
        type: "exception",
        content: t("errorGeneral"),
        duration: 3000,
      });
    }
  };

  const removeTraveller = async (id: string) => {
    await deleteTraveler(id);
    mutate(); // Refresh the travellers list
    setSelectedTravellers(
      selectedTravellers.filter((travellerId) => travellerId !== id),
    );
  };

  const editTraveller = async (traveller: FindUserTravelersOutputData) => {
    try {
      await updateTraveler(traveller.id, traveller);
      mutate(); // Refresh the travellers list
    } catch {
      my.showToast({
        type: "exception",
        content: t("errorGeneral"),
        duration: 3000,
      });
    }
  };

  const toggleTravellerSelection = (id: string) => {
    setSelectedTravellers((prev) => {
      if (prev.includes(id)) {
        return prev.filter((travellerId) => travellerId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  useEffect(() => {
    if (getSelectedTravellers?.length > 0 && !leadTraveller) {
      const oldEnoughTraveller = getSelectedTravellers.find(
        (traveller) => getAgeFromDateOfBirth(traveller.birthDate) < 12,
      );
      if (oldEnoughTraveller) {
        setLeadTraveller(oldEnoughTraveller);
      }
    }
  }, [getSelectedTravellers, leadTraveller]);

  const isReady = !isError && !isLoading;

  return (
    <TravellersContext.Provider
      value={{
        travellers: isReady ? data.travelers : [],
        selectedTravellers,
        addTraveller,
        leadTraveller,
        removeTraveller,
        toggleTravellerSelection,
        editTraveller,
        isReady,
        handleLeadTraveller,
        getSelectedTravellers,
      }}
    >
      {children}
    </TravellersContext.Provider>
  );
};

export const useTravellersContext = () => useContext(TravellersContext);
