import { Star, Circle } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

import { capitalizeHotelNames } from "@/app/lib/capitalizeHotelNames";
import { displayTravellers } from "@/app/lib/displayTravellers";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";
import { THotelFormValues, THotelSearchResult } from "@/app/lib/types/types";

interface HotelOverviewProps {
  searchData: THotelFormValues;
  hotel: THotelSearchResult;
}

export const HotelOverview = ({ searchData, hotel }: HotelOverviewProps) => {
  const t = useTranslations();
  const navigate = useNavigate();
  const { dir } = useLocaleInfo();

  const adultsCount = searchData.rooms.reduce(
    (acc, { adults }) => acc + adults,
    0,
  );
  const childrenCount = searchData.rooms.reduce(
    (acc, { children }) => acc + children,
    0,
  );

  const facilities = hotel.descriptions?.facilities?.split(",");
  const stayLength = dayjs(searchData.dates.checkOut).diff(
    dayjs(searchData.dates.checkIn),
    "day",
  );

  return (
    <div dir={dir} className="px-4">
      <div className="bg-white p-4">
        <div className="text-primary flex w-full overflow-y-auto text-lg font-semibold">
          {capitalizeHotelNames(hotel.name)}
        </div>
        <div className="mb-3 flex items-center gap-2">
          {!!hotel.stars && (
            <div className="flex items-center">
              <span className="text-tertiary mr-2 text-sm">
                {t("hotels.card.stars", { stars: hotel.stars })}
              </span>
              {Array.from(Array(Number(hotel.stars))).map((_, index) => (
                <Star key={index} size={12} color="#EF9C43" weight="fill" />
              ))}
            </div>
          )}
        </div>
        <div className="mb-2 flex justify-center">
          {hotel.mainImageUrl && (
            <img
              onClick={() => {
                navigate({
                  to: "/hotels/$id/details/images",
                  params: { id: `${hotel.id}` },
                });
              }}
              className="rounded-2xl"
              width={340}
              height={180}
              src={hotel.mainImageUrl}
              alt={hotel.name}
            />
          )}
        </div>
        <div className="mb-4 grid grid-cols-4 items-center justify-center gap-2">
          {hotel.imagesUrls.slice(0, 4).map((url: string, index: number) => {
            return (
              <button
                key={url}
                onClick={() =>
                  navigate({
                    to: "/hotels/$id/details/images",
                    params: { id: `${hotel.id}` },
                  })
                }
                className="relative h-20 overflow-hidden rounded-md"
                type="button"
              >
                <img
                  className="h-full w-full object-cover object-center"
                  src={url}
                  alt={`hotel${index}`}
                />
              </button>
            );
          })}
        </div>
        <div className="text-primary mb-8 flex justify-end text-[14px]">
          {hotel?.descriptions?.short || hotel?.descriptions?.full}
        </div>
        {hotel.minPrice ? (
          <div className="text-primary mb-2 flex items-center justify-between font-semibold">
            <span className="text-[14px]">{t("hotels.overview.from")}</span>
            <span className="text-md text-primary font-semibold">
              {hotel.minPrice.value} {hotel.minPrice.currency}
            </span>
          </div>
        ) : null}
        <div>
          <span className="text-tertiary text-sm">
            {t("hotels.stayLength", { stayLength })},{" "}
            {displayTravellers(t, adultsCount, childrenCount)}
          </span>
        </div>
      </div>

      {facilities != null && (
        <>
          <div className="text-tertiary mt-4 mb-2 text-sm font-medium">
            {t("hotels.overview.facilities")}
          </div>
          <div className="text-primary flex flex-wrap items-center gap-2 bg-white p-4 text-sm">
            {facilities.map((facility, index) => (
              <span key={facility} className="mb-2 flex items-center gap-2">
                {facility}{" "}
                {index === facilities.length - 1 ? null : (
                  <Circle aria-hidden weight="fill" size={6} color="#6A778A" />
                )}
              </span>
            ))}
          </div>
        </>
      )}

      <div className="text-tertiary mt-4 mb-2 text-sm font-medium">
        {t("hotels.overview.hotelDetails")}
      </div>
      <div className="text-primary flex flex-col justify-between gap-4 bg-white p-4 text-sm">
        {hotel.address && (
          <div className="flex justify-between gap-4">
            <div>
              <b>{t("hotels.overview.address")}</b>
            </div>
            <div className="flex w-210 flex-wrap justify-end">
              {hotel.address
                .split(",")
                .map((position: string, index: number) => (
                  <span key={position}>
                    {position}{" "}
                    {index <= hotel.address.split(",").length - 2 && "-"}
                  </span>
                ))}
            </div>
          </div>
        )}
        {hotel.geolocation?.latitude && (
          <div className="flex justify-between gap-4">
            <b>{t("hotels.overview.geolocation")}</b>
            <span>
              {hotel.geolocation?.latitude}, {hotel.geolocation?.longitude}
            </span>
          </div>
        )}
        {hotel.fax && (
          <div className="flex justify-between gap-4">
            <b>{t("hotels.overview.fax")}</b>
            <span>{hotel.fax}</span>
          </div>
        )}
        {hotel.email && (
          <div className="flex justify-between gap-4">
            <b>{t("hotels.overview.email")}</b>
            <span>{hotel.email}</span>
          </div>
        )}
        {hotel.telephone && (
          <div className="flex justify-between gap-4">
            <b>{t("hotels.overview.telephone")}</b>
            <span>{hotel.telephone}</span>
          </div>
        )}
      </div>
    </div>
  );
};
