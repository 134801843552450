import { mapFlightReservation } from "@/app/lib/hooks/useFlightsReservations";
import SingleAvailableFlight from "../SingleAvailableFlight";
import { FindUserFlightsReservationsReservation } from "@/app/lib/types/codegen";
import { useTranslations } from "use-intl";
import { ReservationPaymentDetails } from "./ReservationPaymentDetails";
import { ReservationNextSteps } from "./ReservationNextSteps";
import { DownloadVoucher } from "../DownloadVoucher";
import { CircularProgress, Divider } from "@mui/material";
import { TVoucherDoc } from "@/app/lib/types/types";
import { useReservationDocuments } from "@/app/lib/hooks/useReservationDocuments";

export const MyFlight = ({
  reservation,
}: {
  reservation: FindUserFlightsReservationsReservation;
}) => {
  const t = useTranslations();

  console.log(reservation);
  const { data, isLoading } = useReservationDocuments(
    reservation.id?.toString(),
  );

  return (
    <>
      <SingleAvailableFlight
        key={reservation.id}
        itinerary={{
          totalPrice: 0,
          flights: reservation.flights.map((flight) =>
            mapFlightReservation(flight),
          ),
        }}
      />
      <section className="flex w-full flex-row-reverse items-center justify-between gap-4 rounded-2xl bg-white p-4">
        <span className="text-tertiary text-xs">
          {t("flights.ticketNumber")}
        </span>
        <span className="text-title text-base font-semibold">
          {reservation.reference.external}
        </span>
      </section>

      {isLoading ? (
        <div className="mt-6 flex w-full justify-center">
          <CircularProgress size={20} />
        </div>
      ) : (
        data?.documents?.map((doc: TVoucherDoc, index: number) => (
          <DownloadVoucher
            key={doc.link}
            doc={doc}
            dataId={reservation.id?.toString()}
            index={index}
          />
        ))
      )}

      <section>
        <h3 className="text-tertiary mt-4 mb-1 text-end text-xs">
          {t("flights.passengerList")}
        </h3>
        <ul className="flex w-full flex-col gap-4 rounded-2xl bg-white p-4">
          {reservation.travelers.map((traveler) => (
            <li
              className="text-title text-end"
              key={`${traveler.firstName}${traveler.lastName}`}
            >
              {traveler.firstName} {traveler.lastName}
            </li>
          ))}
        </ul>
      </section>

      <ReservationPaymentDetails reservation={reservation} />
      <ReservationNextSteps type="flight" />
      <div className="mb-16 p-4">
        <Divider />
      </div>
    </>
  );
};
