"use client";

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { THotelFormValues } from "../types/types";

interface HotelsSearchContextValue {
  data: THotelFormValues | null;
  setData: Dispatch<SetStateAction<THotelFormValues | null>>;
}

export const HotelSearchDetailsContext =
  createContext<HotelsSearchContextValue>({
    data: null,
    setData: (data) => data,
  });

export const HotelsSearchDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [data, set] = useState<HotelsSearchContextValue["data"]>(null);

  return (
    <HotelSearchDetailsContext.Provider value={{ data, setData: set }}>
      {children}
    </HotelSearchDetailsContext.Provider>
  );
};

export const useHotelSearchDetailsContext = () =>
  useContext(HotelSearchDetailsContext);
