import Loader from "@/app/ui/AnimatedLoader";
import Footer from "@/app/ui/Footer";
import { getQiToken } from "@/app/lib/api/api";
import PageLoader from "@/app/ui/PageLoader";
import { TravelerListV2 } from "@/app/ui/travellers/TravellersListV2";

import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { motion } from "motion/react";
import { Suspense } from "react";
import { useTranslations } from "use-intl";

export const Route = createFileRoute("/_authenticated/travelers")({
  validateSearch: (search) => {
    if (search.edit) {
      return { edit: search.edit as string };
    }
    if (search.delete) {
      return { delete: search.delete as string };
    }
    if (search.new) {
      return { new: true };
    }
    return {};
  },
  component: RouteComponent,
});

function RouteComponent() {
  const t = useTranslations();
  const qi = getQiToken();
  const search = Route.useSearch();
  return (
    <>
      <motion.div
        layoutId="hero"
        className={clsx("absolute top-0 w-full bg-white bg-cover bg-no-repeat")}
      />
      <main className={clsx({ main: !qi, "main-qi": !!qi })}>
        <Suspense
          fallback={
            <PageLoader>
              <Loader icon="users" />
            </PageLoader>
          }
        >
          <div className="mt-6 w-full bg-slate-100 p-4">
            <div className="mt-2 flex w-full justify-end">
              <h4 className="text-title text-base font-medium">
                {t("travellers.listOfTravellers")}
              </h4>
            </div>

            <TravelerListV2 {...search} />
          </div>
        </Suspense>
        <Footer />
      </main>
    </>
  );
}
