import { useMutation } from "@tanstack/react-query";
import { useTranslations } from "use-intl";

import { BASE_API_URL } from "../routes";
import api, { getQiToken, my, qi, apiV2 } from "../api/api";
import {
  BookHotelOfferBodyInputDto,
  BookHotelOfferOutputData,
} from "../types/codegen";
import * as Sentry from "@sentry/react";

export type BookingResult = {
  data: { data: BookHotelOfferOutputData };
} & Record<string, unknown>;

const bookingFetcher = async ({
  url,
  params,
}: {
  url: string;
  params: any;
}): Promise<BookingResult> => {
  try {
    await apiV2.post<BookingResult["data"]>(url, params);

    const qiToken = getQiToken();

    if (!qiToken) {
      const paymentData = await initiatePayment(params.availabilityToken);

      return {
        paymentData,
        id: paymentData.id,
        data: {
          id: paymentData.id || paymentData.data?.id,
          data: {
            id: paymentData.id || paymentData.data?.id,
          },
        },
      };
    }

    const paymentQiServicesResult: any = await initiateQiServicesPayment(
      params.availabilityToken,
    );

    if (paymentQiServicesResult.status.toLowerCase() === "successful") {
      await apiV2.post("/transactions/qi-services", {
        orderId: paymentQiServicesResult.orderId,
        transactionId: paymentQiServicesResult.orderId.toString(),
      });

      return {
        id: paymentQiServicesResult.orderId,
        data: {
          id: paymentQiServicesResult.orderId,
          data: {
            id: paymentQiServicesResult.orderId,
          },
        },
        status: "success",
      };
    }

    throw new Error("Payment failed: Invalid payment status.");
  } catch (err) {
    console.error("Error in booking or payment process:", err);
    throw err;
  }
};

async function initiatePayment(token: number) {
  try {
    const response = await apiV2.post("/orders/super-qi", {
      serviceType: "hotel",
      availabilityToken: token,
    });

    if (
      !response ||
      !response.data ||
      !response.data.data ||
      !response.data.data.redirectUrl
    ) {
      throw new Error(
        "Invalid response from API: Missing redirect action form",
      );
    }

    const redirectUrl = response.data.data.redirectUrl;

    const paymentResult = await tradePay(redirectUrl);

    return paymentResult;
  } catch (err) {
    console.error("Failed to initiate payment:", err);
    throw new Error(
      `Failed to initiate payment: ${(err as any).message || err}`,
    );
  }
}

async function initiateQiServicesPayment(token: number) {
  try {
    const response = await apiV2.post("/orders/qi-services", {
      serviceType: "hotel",
      availabilityToken: token,
    });

    const result = await new Promise((resolve, reject) => {
      qi.purchase(
        {
          price: 1600,
          description: "",
          timeToLiveMillis: 60000,
          merchantTransactionID: response.data.data.orderId.toString(),
        },
        async (data: any) => {
          resolve({
            orderId: response.data.data.orderId,
            status: data.status,
          });
        },
      );
    });

    return result;
  } catch (err) {
    console.error("Failed to initiate payment:", err);
    throw new Error(
      `Failed to initiate payment: ${(err as any).message || err}`,
    );
  }
}

async function tradePay(redirectUrl: string) {
  try {
    const paymentPromise = new Promise((resolve, reject) => {
      my.tradePay({
        paymentUrl: redirectUrl,
        success: (res: any) => {
          if (
            res.resultCode.toString() === "9000" ||
            res.data?.resultCode?.toString() === "9000"
          ) {
            resolve(res);
          } else {
            reject(
              new Error(
                `Payment failed: ${res.resultCode || res.data?.resultCode}`,
              ),
            );
          }
        },
        fail: (err: unknown) => {
          console.error("Payment failed:", err);
          reject(new Error(`Payment failed: ${err}`));
        },
      });
    });

    const result = await paymentPromise;
    return result;
  } catch (err) {
    console.error("Trade payment error:", err);
    throw err;
  }
}

type Params = {
  offerId: string;
  hotelId: string;
  rooms: BookHotelOfferBodyInputDto["rooms"];
  availabilityToken: string;
  searchResultKey: string;
  searchResultToken: string;
};

export const useHotelBooking = ({
  onSuccess,
}: {
  onSuccess: (data: BookingResult) => void;
}) => {
  const t = useTranslations();

  return useMutation({
    mutationFn: ({
      offerId,
      hotelId,
      rooms,
      availabilityToken,
      searchResultKey,
      searchResultToken,
    }: Params) =>
      bookingFetcher({
        url: `/hotels/${offerId}/book`,
        params: {
          availabilityToken,
          searchResultKey,
          searchResultToken,
          hotelId: parseInt(hotelId),
          rooms,
        },
      }),
    onSuccess,
    onError: (error) => {
      Sentry.captureException(error);
      my.showToast({
        type: "exception",
        content: t("hotels.failed"),
        duration: 3000,
      });
    },
  });
};
