"use client";

import { createContext, useContext, PropsWithChildren } from "react";
import { HotelOffer } from "../types/types";

interface HotelOffersContextValue {
  readonly data: HotelOffer[];
}

const HotelOffersContext = createContext<HotelOffersContextValue>({ data: [] });

interface HotelOffersContextProviderProps
  extends PropsWithChildren,
    HotelOffersContextValue {}

export const HotelOffersContextProvider = ({
  children,
  data,
}: HotelOffersContextProviderProps) => {
  return (
    <HotelOffersContext.Provider value={{ data }}>
      {children}
    </HotelOffersContext.Provider>
  );
};

export const useHotelOffersContext = () => useContext(HotelOffersContext);
