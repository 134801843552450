// it's important that this import its first
import "./app/lib/console";
import ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import * as Sentry from "@sentry/react";
import "@fontsource-variable/inter";
import "./styles.css";
import { isProduction, isStaging } from "./app/lib/constants";
import { Devtools } from "./app/ui/Devtools";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./app/lib/query";
import { useEffect } from "react";
import PageError from "./app/ui/PageError";

// Set up a Router instance
const router = createRouter({
  routeTree,
  defaultPreload: false,
  defaultOnCatch: (error) => {
    console.error(error);
    Sentry.captureException(error);
  },
  defaultErrorComponent: ({ error }) => {
    useEffect(() => {
      Sentry.captureException(error);
    }, []);
    return <PageError />;
  },
});

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://6527f31665e26b7c439b4ab54545c562@o4505543468974080.ingest.us.sentry.io/4507934321541120",
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: isProduction ? 0.5 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
  });
}

// Register things for typesafety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const rootElement = document.getElementById("root")!;

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
      {isStaging ? <Devtools /> : null}
    </>,
  );
}
