"use client";
import { WHATSAPP_URL } from "@/app/lib/routes";
import { motion } from "motion/react";
import { useEffect, useRef } from "react";

interface WhatsAppButtonProps {
  bottomPosition?: string;
}

export function WhatsAppButton({ bottomPosition }: WhatsAppButtonProps) {
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    ref.current = window.document.body;
  }, []);

  return (
    <motion.a
      drag
      href={WHATSAPP_URL}
      className="bg-interactive p-3"
      dragMomentum={false}
      style={{
        position: "fixed",
        bottom: bottomPosition ?? 76,
        right: 16,
        borderRadius: "50%",
        zIndex: 100,
      }}
      whileDrag={{ scale: 1.2 }}
      dragConstraints={ref}
      target="_blank"
    >
      <img src="/whatsAppLogo.svg" width={24} height={24} alt="whatsapp-logo" />
    </motion.a>
  );
}
