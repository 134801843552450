"use client";

import { useState } from "react";
import { SwipeableDrawer, MenuItem } from "@mui/material";
import { MapPinLine, CaretDown, X } from "@phosphor-icons/react";
import { Button, TextField, Puller } from "../StyledTz";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

import DestinationSearch from "./DestinationSearch";
import CheckInSelection from "./CheckInSelection";
import VisitorsSelection from "./VisitorsSelections";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import { TravelPurpose } from "@/app/lib/types/enums";
import { initialHotelsValues } from "@/app/lib/initialHotelsValues";
import type { THotelFormValues, TRoom } from "@/app/lib/types/types";
import { useHotelSearchDetailsContext } from "@/app/lib/context/HotelSearchDetailsContext";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";
import { validationSchema } from "./validation.ts";

enum EDrawerComponent {
  DestinationSearch = "DestinationSearch",
  CheckInSelection = "CheckInSelection",
  VisitorsSelection = "VisitorsSelection",
}

const getVisitorsCount = (rooms: TRoom[]) => {
  return (
    rooms?.length &&
    rooms.reduce(
      (sum, { adults, children }: TRoom) => sum + adults + children,
      0,
    )
  );
};

function HotelsCalculator() {
  const [drawerComponent, setDrawerComponent] = useState<string | null>(null);
  const { data: searchData, setData: updateSearchData } =
    useHotelSearchDetailsContext();

  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const { dir } = useLocaleInfo();
  const t = useTranslations();

  const formik = useFormik({
    initialValues: searchData
      ? searchData!
      : (initialHotelsValues as THotelFormValues),
    validationSchema,
    onSubmit: (values) => {
      updateSearchData({
        ...values,
        visitors: getVisitorsCount(formik.values.rooms),
      });
      navigate({ to: "/hotels/list" });
    },
  });

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      if (getFormikErrorsRecursive(res, t).length > 0) {
        showSnackbar(getFormikErrorsRecursive(res, t)[0], 90);
      } else {
        formik.handleSubmit();
      }
    });
  };

  const checkInValue = dayjs(formik.values.dates.checkIn).format("ddd, D MMM");
  const checkOutValue = formik.values.dates.checkOut
    ? dayjs(formik.values.dates.checkOut).format("ddd, D MMM")
    : "_";

  const travelPurposeOptions = [
    {
      value: TravelPurpose.LEISURE,
      label: t("hotels.search.leisure"),
    },
    {
      value: TravelPurpose.BUSINESS,
      label: t("hotels.search.business"),
    },
  ];

  return (
    <div className="w-full rounded-3xl p-4">
      <form noValidate onSubmit={onSubmit} acceptCharset="ISO-8859-1">
        <div className="mb-4 flex flex-col gap-2">
          <div className="relative flex flex-col gap-2">
            <TextField
              fullWidth
              value={formik.values.destination.name?.toLowerCase()}
              variant="filled"
              dir={dir}
              label={
                <div className="flex items-center gap-1">
                  <MapPinLine size={24} />
                  <span className="ml-2">{t("hotels.search.destination")}</span>
                </div>
              }
              onMouseDown={(e) => {
                e.preventDefault();
                setDrawerComponent(EDrawerComponent.DestinationSearch);
              }}
              InputProps={{
                endAdornment: formik.values.destination.name && (
                  <button className="absolute left-3" type="button">
                    <X size={20} color="#6A778A" />
                  </button>
                ),
              }}
              sx={{
                "& input": {
                  textTransform: "capitalize !important",
                },
              }}
            />
          </div>
          <TextField
            fullWidth
            variant="filled"
            label={t("hotels.search.checkIn")}
            dir={dir}
            value={`${checkInValue} - ${checkOutValue}`}
            onMouseDown={(e) => {
              e.preventDefault();
              setDrawerComponent(EDrawerComponent.CheckInSelection);
            }}
          />
          <TextField
            fullWidth
            label={t("hotels.search.visitors")}
            className="w-full p-0"
            dir={dir}
            variant="filled"
            // TODO: change this copy to "visitorsSumNoRooms" key when IRIX will fix the issue with booking multiple rooms
            value={t("hotels.search.visitorsSumWithTypes", {
              adultsCount: formik.values.rooms[0].adults,
              childrenCount: formik.values.rooms[0].children,
            })}
            InputProps={{
              endAdornment: (
                <CaretDown className="absolute end-5" color="black" size={24} />
              ),
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              setDrawerComponent(EDrawerComponent.VisitorsSelection);
            }}
          />
          <TextField
            fullWidth
            id="travelPurpose"
            name="travelPurpose"
            label={t("hotels.search.travelPurpose")}
            className="w-full p-0"
            dir={dir}
            variant="filled"
            value={formik.values.travelPurpose}
            InputProps={{
              endAdornment: (
                <CaretDown className="absolute end-5" color="black" size={24} />
              ),
            }}
            select
            onChange={(e) =>
              formik.setFieldValue("travelPurpose", e.target.value)
            }
            sx={{
              "& #travelPurpose": {
                paddingRight: "20px !important",
              },
            }}
          >
            {travelPurposeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{
            borderRadius: "999px",
            padding: "16px",
          }}
        >
          {t("hotels.search.searchHotel")}
        </Button>
      </form>

      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerComponent(null)}
        onOpen={() => {}}
        open={drawerComponent != null}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "32px 32px 0 0",
            height:
              drawerComponent === EDrawerComponent.VisitorsSelection
                ? "initial"
                : "80vh",
            paddingBottom: "env(safe-area-inset-bottom)",
          },
        }}
      >
        <Puller />
        {drawerComponent === EDrawerComponent.DestinationSearch && (
          <DestinationSearch
            formProps={formik}
            onClose={() => setDrawerComponent(null)}
          />
        )}
        {drawerComponent === EDrawerComponent.CheckInSelection && (
          <CheckInSelection
            formProps={formik}
            onClose={() => setDrawerComponent(null)}
          />
        )}
        {drawerComponent === EDrawerComponent.VisitorsSelection && (
          <VisitorsSelection
            formProps={formik}
            onClose={() => setDrawerComponent(null)}
          />
        )}
      </SwipeableDrawer>
    </div>
  );
}

export default HotelsCalculator;
