import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataProvider";
import { HotelImageList } from "@/app/ui/hotel/HotelImageList";
import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/_hotel-offer/hotels/$id/details_/images",
)({
  component: RouteComponent,
  staticData: {
    stepper: {
      presentationMode: "modal",
      step: 1,
    },
    footer: false,
    drawer: false,
  },
});

function RouteComponent() {
  const navigate = Route.useNavigate();

  const { data: hotel } = useOptionalHotelDataContext();

  if (!hotel) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    return <Navigate to=".." from={Route.fullPath} />;
  }

  return (
    <HotelImageList
      hotel={hotel}
      onImageClick={(index) =>
        navigate({ to: "./fullscreen", search: { index } })
      }
    />
  );
}
