import { createFileRoute, Outlet } from "@tanstack/react-router";
import Footer from "@/app/ui/Footer";
import { motion } from "motion/react";
import { getQiToken } from "@/app/lib/api/api";
import clsx from "clsx";
import { useRouteConfig } from "@/app/lib/hooks/useRouteConfig";
import { TravellersProvider } from "@/app/lib/context/TravellersContext";
import BookingError from "@/app/ui/BookingError";
import * as Sentry from "@sentry/react";

export const Route = createFileRoute("/_authenticated/_hotel-offer")({
  component: RouteComponent,
  errorComponent: (props) => {
    Sentry.captureException(props.error);
    return <BookingError {...props} type="hotels" />;
  },
});

function RouteComponent() {
  const routeConfig = useRouteConfig();
  const qi = getQiToken();

  return (
    <>
      <motion.div
        layoutId="hero"
        className="bg-hero-pattern absolute top-0 z-20 w-full rounded-b-2xl bg-cover bg-no-repeat"
      />
      <main
        className={clsx("pt-4", {
          "no-footer": !routeConfig.footer,
          "with-drawer": !!routeConfig.drawer,
          main: !qi,
          "main-qi": !!qi,
        })}
      >
        <TravellersProvider>
          <Outlet />
        </TravellersProvider>
        {routeConfig.footer === false ? null : <Footer />}
      </main>
    </>
  );
}
