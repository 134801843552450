import { ReactNode } from "react";
import { IconButton, MobileStepper } from "@mui/material";
import { X, ArrowLeft } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";
import clsx from "clsx";
import dayjs from "dayjs";
import { NavigateOptions, useNavigate } from "@tanstack/react-router";

import { useHotelSearchDetailsContext } from "@/app/lib/context/HotelSearchDetailsContext";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

type HotelBookingHeaderProps = {
  className?: string;
  title?: ReactNode;
  presentationMode?: "modal";
  showStepper?: boolean;
} & (
  | {
      showStepper: true;
      step: number;
    }
  | { showStepper?: false }
);

const STEPS_CONFIG = [
  { title: "hotels.title", prev: { to: "/hotels/list" } },
  { title: "hotels.chooseRoom", prev: { to: "/hotels/$id/details" } },
  { title: "travellers.title", prev: { to: "/hotels/$id/rooms" } },
  { title: "summary", prev: { to: "/hotels/$id/travelers" } },
  { title: "success", prev: { to: "/", search: { category: "hotels" } } },
] satisfies {
  title: string;
  prev?: NavigateOptions;
}[];

export const HotelBookingHeader = ({
  title,
  presentationMode,
  ...props
}: HotelBookingHeaderProps) => {
  const t = useTranslations();
  const { dir } = useLocaleInfo();

  const navigate = useNavigate();

  const { data: searchData } = useHotelSearchDetailsContext();

  return (
    <div className="mb-4 flex w-full flex-col px-4" dir={dir}>
      <div className="align-center flex justify-between">
        {props.showStepper ? (
          <IconButton
            onClick={() => navigate(STEPS_CONFIG[props.step].prev)}
            sx={{ padding: 0 }}
          >
            <ArrowLeft
              size={24}
              color="text-title"
              className={clsx({ "rotate-180": dir === "rtl" })}
            />
          </IconButton>
        ) : (
          <div className="size-6" />
        )}
        <div className="flex flex-col items-center justify-center gap-1">
          <div className="flex w-full items-center justify-center">
            {props.showStepper ? (
              <h3 className="text-title font-medium">
                {t(STEPS_CONFIG[props.step].title)}
              </h3>
            ) : (
              title
            )}
          </div>
          {searchData ? (
            <span className="text-tertiary max-w-[220px] truncate text-xs font-normal">
              {dayjs(searchData.dates.checkIn).format("ddd, DD MMM")} -{" "}
              {dayjs(searchData.dates.checkOut).format("ddd, DD MMM")},{" "}
              <span>
                {searchData.rooms.length > 1
                  ? t("hotels.search.rooms")
                  : t("hotels.search.room")}{" "}
                {searchData.rooms.length}
              </span>
            </span>
          ) : null}
        </div>
        {presentationMode === "modal" ? (
          <IconButton
            onClick={() => navigate({ to: ".." })}
            sx={{ padding: 0 }}
          >
            <X size={24} color="text-title" />
          </IconButton>
        ) : (
          <div className="size-6" />
        )}
      </div>
      {props.showStepper ? (
        <>
          <MuiThemeProvider>
            <MobileStepper
              variant="progress"
              steps={5}
              position="static"
              // +1 because we're 0-indexed
              activeStep={props.step + 1}
              dir={dir}
              sx={{
                paddingTop: 2,
              }}
              nextButton={null}
              backButton={null}
            />
          </MuiThemeProvider>
          <div className="flex items-center gap-2 text-start">
            <h5 className="text-title text-xs">
              <span className="text-secondary">
                {t("hotels.step")}&nbsp;{props.step + 1}/
                {STEPS_CONFIG.length - 1} {title}
              </span>
            </h5>
          </div>
        </>
      ) : null}
    </div>
  );
};
