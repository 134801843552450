import { Divider } from "@mui/material";
import { useTranslations } from "use-intl";
import clsx from "clsx";
import type {
  FindUserTravelersOutputData,
  GetFlightAvailabilityTokenOptionalService,
} from "@/app/lib/types/codegen";
import { getAgeFromDateOfBirth } from "@/app/lib/getAgefromDateOfBirth";
import { baggageIcons } from "@/app/lib/utils/baggageIcons";

function groupByPassengerIndex(
  data: GetFlightAvailabilityTokenOptionalService[],
) {
  const grouped: Record<string, GetFlightAvailabilityTokenOptionalService[]> =
    {};

  for (const key in data) {
    const item = data[key];
    const passengerIndex = item.passengerIndex;

    if (!grouped[passengerIndex]) {
      grouped[passengerIndex] = [];
    }

    grouped[passengerIndex].push(item);
  }

  return grouped;
}

function TravellersListSummary({
  selectedTravellers,
  baggage,
  setLeadTraveller,
  leadTraveller,
}: {
  selectedTravellers: FindUserTravelersOutputData[];
  baggage: GetFlightAvailabilityTokenOptionalService[];
  leadTraveller: FindUserTravelersOutputData | null;
  setLeadTraveller?: (traveller: FindUserTravelersOutputData) => void;
}) {
  const t = useTranslations();
  const groupedByPassenger = groupByPassengerIndex(baggage);
  return (
    <div dir="rtl" className="mt-4">
      {setLeadTraveller && (
        <span className="text-tertiary mb-2 block text-xs">
          {t("travellers.travellersList")}
        </span>
      )}
      <div className="flex flex-col gap-8">
        {selectedTravellers.map(
          (traveller: FindUserTravelersOutputData, index) => (
            <div
              key={traveller.id}
              className="flex flex-col gap-4 rounded-xl bg-white"
            >
              <div
                className={clsx(
                  "flex w-full items-center justify-between gap-2",
                  {
                    "p-4": setLeadTraveller,
                  },
                )}
              >
                <div className="flex flex-col text-right">
                  <span className="text-primary text-base font-medium">
                    {traveller.firstName} {traveller.lastName}
                  </span>
                  <span className="text-tertiary text-sm">
                    {t("travellers.passportId")}:{" "}
                    {traveller.passportNumber || "-"}
                  </span>
                </div>
                {getAgeFromDateOfBirth(traveller.birthDate) < 12 ? null : (
                  <div className="flex flex-shrink-0 gap-6">
                    <div className="flex items-center gap-1">
                      <label
                        className={clsx(
                          "main-traveller-form-control",
                          traveller.id === leadTraveller?.id && "checked",
                        )}
                      >
                        <input
                          className="main-traveller-checkbox"
                          onChange={() =>
                            setLeadTraveller
                              ? setLeadTraveller(traveller)
                              : null
                          }
                          type="radio"
                          name="radio"
                          checked={traveller.id === leadTraveller?.id}
                        />
                        {t("travellers.mainTraveller")}
                      </label>
                    </div>
                  </div>
                )}
              </div>
              {groupedByPassenger[index] && (
                <>
                  <Divider flexItem className="my-2" />
                  <div className="flex flex-col gap-4 p-2">
                    {groupedByPassenger[index].map((service, index) => (
                      <div
                        key={index}
                        className="flex w-full items-center justify-between"
                      >
                        <div className="flex gap-6">
                          <div className="flex items-center gap-1">
                            {baggageIcons[service.service.categoryCode]}
                          </div>
                        </div>
                        <div className="flex flex-col text-right">
                          <span className="text-primary text-sm">
                            {service.service.name}
                          </span>
                          <span className="text-tertiary text-sm">
                            {service.route.arrivalCode} -{" "}
                            {service.route.departureCode}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  );
}

export default TravellersListSummary;
