"use client";

import { Button, SwipeableDrawer } from "@mui/material";
import { Puller } from "@/app/ui/StyledTz";
import { UserPlus } from "@phosphor-icons/react/dist/ssr";
import { useTranslations } from "use-intl";
import type { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
import SingleTravellerInList from "@/app/ui/travellers/SingleTravellerInList";
import { getQiToken } from "@/app/lib/api/api";
import DeleteTraveller from "@/app/ui/travellers/DeleteTraveller";
import TravellerForm from "@/app/ui/travellers/TravellerForm";
import { useNavigate } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  travelersQueryOptions,
  useDeleteTravelerMutation,
  useNewTravelerMutation,
  useUpdateTravelerMutation,
} from "@/app/lib/hooks/useTravellers";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";

interface TravelerListV2Props {
  edit?: string;
  delete?: string;
  new?: boolean;
}

export function TravelerListV2(props: TravelerListV2Props) {
  const navigate = useNavigate();
  const t = useTranslations();

  const { mutateAsync: addTraveler } = useNewTravelerMutation();
  const { mutateAsync: editTraveler } = useUpdateTravelerMutation();
  const { mutateAsync: deleteTraveler } = useDeleteTravelerMutation();
  const qi = getQiToken();
  const drawerOpen = !!props.edit || !!props.delete || props.new;

  const { data } = useSuspenseQuery(travelersQueryOptions({ page: 1 }));

  const initTravellerDeletion = (traveller: FindUserTravelersOutputData) => {
    navigate({ to: "/travelers", search: { delete: traveller.id } });
  };

  const initTravellerEdit = (traveller: FindUserTravelersOutputData) => {
    navigate({ to: "/travelers", search: { edit: traveller.id } });
  };

  const closeDrawerHandler = () => {
    navigate({ to: "/travelers", replace: true });
  };

  const travelerForDeletion = props.delete
    ? data?.find((t) => t.id === props.delete)
    : undefined;
  const travelerForEdit = props.edit
    ? data?.find((t) => t.id === props.edit)
    : undefined;

  return (
    <MuiThemeProvider>
      <div className="mt-4 flex flex-col gap-4 rounded-xl bg-white">
        {data?.map((traveller, index) => {
          return (
            <SingleTravellerInList
              checkDisabled={false}
              lastInList={data.length === index + 1}
              key={traveller.id}
              traveller={traveller}
              toggleTravellerSelection={() => {}}
              selectedTravellers={[]}
              onDelete={initTravellerDeletion}
              onEdit={initTravellerEdit}
            />
          );
        })}
        <Button
          variant="text"
          onClick={() => {
            navigate({ to: "/travelers", search: { new: true } });
          }}
          sx={{
            textTransform: "none",
            fontSize: "14px",
            lineHeight: "20px",
            margin: "calc(var(--spacing) * 4) 0",
            padding: "8px 16px",
          }}
          color="primary"
        >
          <UserPlus size={24} className="fill-interactive mr-2" />{" "}
          {t("travellers.addNew")}
        </Button>
      </div>
      <SwipeableDrawer
        anchor="bottom"
        onClose={closeDrawerHandler}
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "32px 32px 0 0",
            paddingBottom: "env(safe-area-inset-bottom)",
            height: qi ? "90vh" : "100vh",
          },
        }}
        onOpen={() => {}}
      >
        <Puller />
        {travelerForDeletion && (
          <DeleteTraveller
            traveller={travelerForDeletion}
            deleteTraveller={() => deleteTraveler(travelerForDeletion.id)}
            closeDrawer={closeDrawerHandler}
          />
        )}
        {(props.new || travelerForEdit) && (
          <TravellerForm
            onClose={closeDrawerHandler}
            traveller={travelerForEdit || null}
            onSubmit={(data) =>
              travelerForEdit
                ? editTraveler({ travelerId: travelerForEdit.id, ...data })
                : addTraveler(data)
            }
          />
        )}
      </SwipeableDrawer>
    </MuiThemeProvider>
  );
}
