import useSWRInfinite from "swr/infinite";
import { BASE_API_URL } from "../routes";
import { IHotelsFilters } from "../types/interfaces";
import { hotelsFetcher } from "./useHotelsSearch";

const PAGE_SIZE = 20;

export const useHotelSearchResultKey = (
  searchResultKey: string | undefined,
  filters: IHotelsFilters,
  searchResultToken: string | undefined,
  shouldFetch: boolean,
  sortField: string,
) => {
  const { data, error, isLoading, isValidating, size, setSize } =
    useSWRInfinite(
      (index) => {
        return searchResultKey && searchResultToken && shouldFetch
          ? {
              url: `${BASE_API_URL}/hotels`,
              params: {
                searchResultKey,
                searchResultToken,
                page: index + 1,
                perPage: PAGE_SIZE,
                sortField,
                sortOrder: sortField === "price" ? filters.sortOrder : "DESC",
                minPrice: filters.priceRange[0],
                maxPrice: filters.priceRange[1],
                stars: filters.starRating,
                boardBasis: filters.boardBasis,
              },
            }
          : null;
      },
      hotelsFetcher,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        initialSize: 1,
      },
    );

  const isEmpty = data?.[0]?.data.length === 0;
  const hasReachedEnd =
    isEmpty || data?.[data.length - 1]?.data.length < PAGE_SIZE;

  return {
    data,
    isLoading,
    isValidating,
    isError: error,
    isEmpty,
    hasReachedEnd,
    size,
    setSize,
  };
};
