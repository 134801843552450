import { useEffect, useState, useRef } from "react";
import { useLocale, useTranslations } from "use-intl";
import clsx from "clsx";
import {
  CaretUpDown,
  AirplaneTakeoff,
  AirplaneLanding,
  X,
} from "@phosphor-icons/react";
import { AirportType } from "@/app/lib/types/enums";
import type { FormikProps } from "formik";
import type { TFlightFormValues } from "@/app/lib/types/types";
import { Button } from "@mui/material";
import { initialFlightsValues } from "@/app/lib/initialFlightsValues";
import { useAirportSearch } from "@/app/lib/hooks/useAirportSearch";
import { toTitleCase } from "@/app/lib/utils/titleCase";
import {
  FindCitiesAirport,
  FindCitiesCityWithAirports,
} from "@/app/lib/types/codegen";
import { TextField } from "../StyledTz";

function AirportSearch({
  onDrawerClose,
  formProps,
}: {
  onDrawerClose: () => void;
  formProps: FormikProps<TFlightFormValues>;
}) {
  const locale = useLocale();
  const t = useTranslations();
  const [departure, setDeparture] = useState(formProps.values.from.name);
  const [arrival, setArrival] = useState(formProps.values.to.name);
  const [viewPortHeight, setViewPortHeight] = useState(0);
  const contentRef = useRef(null);
  const [input, setInput] = useState("");
  const [focus, setFocus] = useState(AirportType.FROM);
  const { data } = useAirportSearch(input);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value.slice(0, 30);
    setInput(input);
    if (focus === AirportType.FROM) {
      setDeparture(input);
    } else {
      setArrival(input);
    }
  };

  const onClick = (
    value: FindCitiesCityWithAirports | FindCitiesAirport,
    cityName: string,
  ) => {
    const selectionValue = {
      ...value,
      city: cityName,
    };
    setInput("");
    if (focus === AirportType.FROM) {
      formProps.setFieldValue("from", selectionValue);
      setDeparture(value.name);
      setFocus(AirportType.TO);
      if (formProps.values.to.name) {
        onDrawerClose();
      }
    } else {
      setFocus(AirportType.TO);
      formProps.setFieldValue("to", selectionValue);
      setArrival(value.name);
      if (formProps.values.from.name) {
        onDrawerClose();
      }
    }
  };

  const resetArrival = () => {
    formProps.setFieldValue("to", initialFlightsValues.to);
    setArrival(initialFlightsValues.to.name);
  };

  const resetDeparture = () => {
    formProps.setFieldValue("from", initialFlightsValues.from);
    setDeparture(initialFlightsValues.from.name);
  };

  const changeAirportValues = () => {
    formProps.setFieldValue("from", formProps.values.to);
    formProps.setFieldValue("to", formProps.values.from);
    setDeparture(formProps.values.to.name);
    setArrival(formProps.values.from.name);
  };

  useEffect(() => {
    const handleInputFocus = () => {
      // Delay the resize handling to allow the keyboard to fully appear
      setTimeout(() => {
        const currentViewportHeight = visualViewport?.height;
        setViewPortHeight(currentViewportHeight as number);
      }, 200);
    };
    window.visualViewport?.addEventListener("resize", handleInputFocus);
    return () =>
      window.visualViewport?.removeEventListener("resize", handleInputFocus);
  }, []);

  return (
    <div className="h-full w-full overflow-hidden p-4" ref={contentRef}>
      <div className="relative flex w-full flex-col gap-4 overflow-hidden">
        <TextField
          name="from"
          fullWidth
          autoComplete="off"
          variant="filled"
          sx={{
            input: {
              paddingLeft: "40px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
          onChange={onChange}
          onFocus={() => {
            setFocus(AirportType.FROM);
          }}
          value={departure}
          dir={locale === "ar" ? "rtl" : "ltr"}
          InputProps={{
            endAdornment: formProps.values.from.name && departure && (
              <button
                type="button"
                onClick={resetDeparture}
                className="absolute left-3"
              >
                <X size={20} color="#6A778A" />
              </button>
            ),
          }}
          label={
            <div className="flex items-center gap-1">
              <AirplaneTakeoff size={24} />
              <span className="ml-2">{t("flights.search.from")}</span>
            </div>
          }
        />
        <CaretUpDown
          onClick={changeAirportValues}
          height={40}
          width={26}
          color="white"
          className={clsx(
            "absolute top-11 z-10 rounded-2xl bg-[#3B3C3D] hover:cursor-pointer",
            {
              "right-5": locale === "en",
              "left-5": locale === "ar",
            },
          )}
        />
        <TextField
          name="to"
          fullWidth
          autoComplete="off"
          value={arrival}
          onChange={onChange}
          autoFocus={focus === AirportType.TO}
          onFocus={() => {
            setFocus(AirportType.TO);
          }}
          variant="filled"
          dir={locale === "ar" ? "rtl" : "ltr"}
          sx={{
            input: {
              paddingLeft: "40px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
          }}
          InputProps={{
            endAdornment: formProps.values.to.name && arrival && (
              <button
                type="button"
                onClick={resetArrival}
                className="absolute left-3"
              >
                <X size={20} color="#6A778A" />
              </button>
            ),
          }}
          label={
            <div className="flex items-center gap-1">
              <AirplaneLanding size={24} />
              <span className="ml-2">{t("flights.search.to")}</span>
            </div>
          }
        />
      </div>
      {data?.cities && data?.cities?.length > 0 ? (
        <div
          style={{
            height: viewPortHeight ? viewPortHeight - 200 : "90%",
            paddingRight: "10px",
            width: "calc(100% + 10px)",
            marginBottom: "100px",
            display: "block",
          }}
          className="overflow-y-auto"
        >
          <ul>
            {data.cities?.map((city) => (
              <li key={city.id}>
                <Button
                  onClick={() => {
                    onClick(city, toTitleCase(city.name));
                  }}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "transparent",
                    padding: "0.75rem 0",
                  }}
                  className={clsx("flex w-full gap-1", {
                    "flex-row-reverse": locale === "ar",
                  })}
                >
                  <span className="text-label max-w-[240px] truncate text-right text-base font-medium">
                    {toTitleCase(city.name)}
                  </span>
                  <span className="text-sm text-slate-500">
                    {city.countryName},
                  </span>
                  <span
                    className={clsx("block text-sm text-slate-500", {
                      "mr-auto": locale === "ar",
                      "ml-auto": locale === "en",
                    })}
                  >
                    {city.code ? city.code : "-"}
                  </span>
                </Button>
                <ul className="block">
                  {city.airports.map((airport) => (
                    <Button
                      onClick={() => {
                        onClick(airport, toTitleCase(city.name));
                      }}
                      variant="text"
                      sx={{
                        textTransform: "none",
                        padding: "0.75rem 0 0.75rem 0.75rem",
                      }}
                      className={clsx("flex w-full items-center gap-2", {
                        "flex-row-reverse": locale === "ar",
                      })}
                      key={airport.id}
                    >
                      <AirplaneLanding size={18} color="#6A778A" />
                      <span className="text-label font-medium">
                        {airport.name}
                      </span>
                      <span
                        className={clsx("text-sm text-slate-500", {
                          "mr-auto": locale === "ar",
                          "ml-auto": locale === "en",
                        })}
                      >
                        {airport.code}
                      </span>
                    </Button>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="text-placeholder mt-6 flex justify-center text-sm">
          {t("flights.search.enterName")}
        </div>
      )}
    </div>
  );
}

export default AirportSearch;
