"use client";

import { ReactNode, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useLocaleInfo } from "../hooks/useLocaleInfo";

export const MuiThemeProvider = ({ children }: { children: ReactNode }) => {
  const { dir } = useLocaleInfo();

  const theme = useMemo(
    () =>
      createTheme({
        direction: dir,
        palette: {
          primary: {
            main: "#003E2B", // use var(--color-surface-accent-stronger) once we migrate to MUI v6
          },
          error: {
            main: "#DE370C", // use var(--color-semantic-error-strong) once we migrate to MUI v6
          },
          neutral: {
            main: "#3B3C3D",
          },
        },
        components: {
          MuiRadio: {
            styleOverrides: {
              root: {
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
                "&.Mui-checked": {
                  color: "#003E2B", // Replace with your desired color
                },
              },
            },
          },
          MuiBottomNavigation: {
            styleOverrides: {
              root: {
                "& .MuiBottomNavigationAction-label": {
                  marginTop: "2px",
                  fontWeight: "bold",
                },
                "& .Mui-selected": {
                  color: "var(--color-surface-accent-stronger)",
                  zIndex: 2,
                  "& .MuiTouchRipple-root": {
                    position: "absolute",
                    top: 0,
                    left: "20%",
                    maxWidth: "75px",
                    opacity: 0.4,
                    borderRadius: "50%",
                    background:
                      "radial-gradient(circle at 50% 50%, #A7FDC3 40%, #FFFFFF 60%)",
                    zIndex: -1,
                  },
                },
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                backgroundColor: "#003E2B",
                color: "#fff",
              },
            },
          },
          MuiCheckbox: {
            styleOverrides: {
              root: {
                "&.Mui-checked": {
                  backgroundColor: "#fff",
                  color: "#003E2B",
                },
              },
            },
          },
          MuiSlider: {
            styleOverrides: {
              rail: {
                backgroundColor: "var(--color-stroke-neutral-soft)",
                opacity: 1,
              },
              thumb: {
                transform: `translate(${dir === "rtl" ? 50 : -50}%, -50%)`,
                width: 12,
                height: 20,
                backgroundColor: "var(--color-surface-accent-medium)",
                borderRadius: "4px",
                border: "5px solid var(--color-surface-accent-stronger)",
              },
            },
          },
          MuiSnackbarContent: {
            styleOverrides: {
              root: {
                maxWidth: "var(--container-2xs)",
                borderRadius: "var(--radius-lg)",
                backgroundColor: "var(--color-zinc-800)",
                boxShadow: "var(--shadow-md)",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "22px",
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                margin: "auto",
                maxWidth: "var(--container-xl)",
                borderRadius: "32px 32px 0 0",
                paddingBottom:
                  "env(safe-area-inset-bottom, calc(var(--spacing) * 4)))",
              },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                "& .MuiSelect-select": {
                  borderRadius: "16px !important",
                },
                "& .MuiSvgIcon-root": {
                  display: "none",
                },
              },
            },
          },
          MuiMobileStepper: {
            styleOverrides: {
              root: {
                width: "100%",
                paddingInline: 0,
                backgroundColor: "transparent",
                "& .MuiLinearProgress-root": {
                  width: "100%",
                  background: "#E7E9EE",
                  borderRadius: "4px",
                },
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "var(--color-surface-accent-stronger)",
                  borderRadius: "4px",
                },
              },
            },
          },
        },
      }),
    [dir],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
