"use client";

import { TextField as MuiTextField, Button as MuiButton } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { styled } from "@mui/material/styles";

export const Puller = styled("div")(() => ({
  width: "98px",
  minHeight: 8,
  backgroundColor: "#E7E9EE",
  borderRadius: 3,
  top: 12,
  margin: "12px auto",
}));

export const Button = styled(MuiButton)({
  color: "#FFF",
  background: "var(--color-surface-accent-stronger)",
  fontWeight: 700,
  fontFamily: "Helvetica Neue, sans-serif",
  fontSize: "16px",
  textTransform: "none",
  borderRadius: 100,
  padding: "12px",
});

export const TelInput = styled(MuiTelInput)(({ dir }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "16px",
    backgroundColor: "white",
    border: "1px solid #E7E9EE",
  },
  "& .MuiFilledInput-root": {
    borderRadius: "16px",
    paddingLeft: "4px",
  },

  "& .MuiFilledInput-root:after": {
    border: "none",
  },
  "& .MuiFilledInput-root:hover": {
    background: "white",
  },
  "& .MuiFilledInput-root:focus": {
    background: "none",
  },
  "& .MuiFilledInput-root:before": {
    border: "none",
  },
  "& .MuiFilledInput-root:hover:before": {
    border: "none",
    background: "none",
    width: 0,
  },
  "& .MuiInputLabel-root": {
    color: "var(--color-text-placeholder)",
    fontSize: "16px",
    lineHeight: "22px",
    ...(dir === "rtl"
      ? {
          right: 16,
          left: "unset",
        }
      : {}),
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--color-text-placeholder)",
    background: "none",
  },
  "& .MuiInputBase-input": {
    color: "var(--color-text-label)",
    direction: "ltr",
    textAlign: dir === "rtl" ? "right" : "left",
  },
  "& .MuiInputBase-input:focus": {
    backgroundColor: "none",
  },
  "& .Mui-error.MuiInputBase-root": {
    border: "1px solid var(--color-semantic-error-strong)",
  },
}));

export const TextField = styled(MuiTextField)(({ dir }) => ({
  "& .MuiFilledInput-root": {
    borderRadius: "16px",
    backgroundColor: "white",
    padding: 0,
  },

  "& .MuiFilledInput-root:after": {
    border: "none",
  },
  "& .MuiFilledInput-root:hover": {
    background: "white",
  },
  "& .MuiFilledInput-root:focus": {
    background: "none",
  },
  "& .MuiFilledInput-root:before": {
    border: "none",
  },
  "& .MuiFilledInput-root:hover:before": {
    border: "none",
    background: "none",
    width: 0,
  },
  "& .MuiInputLabel-root": {
    color: "var(--color-text-placeholder)",
    transformOrigin: "top right",
    fontSize: "16px",
    lineHeight: "22px",
    right: "32px",
    left: "unset",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--color-text-placeholder)",
    background: "white",
  },
  "& .MuiInputBase-input": {
    color: "var(--color-text-label)",
    borderRadius: "16px",
    backgroundColor: "white",
    border: "1px solid #E7E9EE",
    paddingRight: dir === "rtl" ? 22 : "unset",
  },
  "& .MuiInputBase-input:focus": {
    backgroundColor: "white",
  },
  "& .Mui-error .MuiInputBase-input": {
    border: "1px solid var(--color-semantic-error-strong)",
  },
}));
