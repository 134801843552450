import useSWR, { mutate } from "swr";
import type {
  CreateTravelerBodyInputDto,
  CreateTravelerOutputDto,
  FindUserTravelersOutputData,
  UpdateTravelerOutputDto,
} from "@/app/lib/types/codegen";
import api from "../api/api";
import {
  keepPreviousData,
  queryOptions,
  useMutation,
} from "@tanstack/react-query";
import { queryClient } from "../query";

export const useCreateTraveller = () => {
  return async (travelerData: CreateTravelerBodyInputDto) => {
    const response = await api.post<CreateTravelerOutputDto>("/travelers", {
      ...travelerData,
      // remove prefix from phone number
      emailAddress: travelerData.emailAddress || undefined,
      phoneNumber: travelerData.phoneNumber.replace("+964", ""),
    });
    mutate("/travelers");
    return response;
  };
};

export const useUpdateTraveller = () => {
  return async (travelerId: string, updateData: CreateTravelerBodyInputDto) => {
    const response = await api.put<UpdateTravelerOutputDto>(
      `/travelers/${travelerId}`,
      {
        ...updateData,
        emailAddress: updateData.emailAddress || undefined,
        phoneNumber: updateData.phoneNumber.replace("+964", ""),
      },
    );
    mutate("/travelers");
    return response;
  };
};

export const useDeleteTraveller = () => {
  return async (travelerId: string) => {
    await api.delete(`/travelers/${travelerId}`);
    mutate("/travelers");
  };
};

export const getTravellersFetcher = ({
  url,
  params,
}: {
  url: string;
  params?: any;
}) => {
  return api
    .get(url, {
      params,
    })
    .then((res) => res.data);
};

export const travelersQueryOptions = ({ page }: { page: number }) =>
  queryOptions({
    queryKey: ["travelers", page],
    queryFn: ({ queryKey: [_, page] }) =>
      // FIXME: Swagger has invalid response type
      api.get<{ data: { travelers: FindUserTravelersOutputData[] } }>(
        "/travelers",
        {
          params: {
            page: Number.parseInt(`${page}`, 10),
            perPage: 20,
          },
        },
      ),
    placeholderData: keepPreviousData,
    select: (data) => data.data.data.travelers,
  });

export const useDeleteTravelerMutation = () =>
  useMutation({
    mutationFn: (travelerId: string) => api.delete(`/travelers/${travelerId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: travelersQueryOptions({ page: 1 }).queryKey,
      });
    },
  });

export const useUpdateTravelerMutation = () =>
  useMutation({
    mutationFn: ({
      travelerId,
      ...body
    }: {
      travelerId: string;
    } & CreateTravelerBodyInputDto) =>
      api.put(`/travelers/${travelerId}`, body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: travelersQueryOptions({ page: 1 }).queryKey,
      });
    },
  });

export const useNewTravelerMutation = () =>
  useMutation({
    mutationFn: (body: CreateTravelerBodyInputDto) =>
      api.post("/travelers", body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: travelersQueryOptions({ page: 1 }).queryKey,
      });
    },
  });

export const useTravelers = (ready: boolean) => {
  const { data, error, isLoading, mutate } = useSWR(
    () => {
      return ready
        ? {
            url: `/travelers`,
            params: {
              page: 1,
              perPage: 20,
            },
          }
        : null;
    },
    getTravellersFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  return {
    data: data?.data || [],
    mutate,
    isLoading,
    isError: error,
  };
};
