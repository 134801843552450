"use client";

import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  SetStateAction,
  Dispatch,
} from "react";
import { HotelBooking } from "../types/types";

interface HotelBookingContextValue {
  data: HotelBooking | null;
  setData: Dispatch<SetStateAction<HotelBooking | null>>;
  availabilityToken: string | null;
  setAvailabilityToken: (token: string | null) => void;
}

const HotelBookingContext = createContext<HotelBookingContextValue>({
  data: null,
  setData: (data) => data,
  availabilityToken: null,
  setAvailabilityToken: () => {},
});

export const HotelBookingContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [data, setData] = useState<HotelBookingContextValue["data"]>(null);
  const [availabilityToken, setAvailabilityToken] = useState<string | null>(
    null,
  );

  return (
    <HotelBookingContext.Provider
      value={{ data, setData, availabilityToken, setAvailabilityToken }}
    >
      {children}
    </HotelBookingContext.Provider>
  );
};

export const useHotelBookingContext = () => useContext(HotelBookingContext);
