import FlightList from "@/app/ui/flight-list/FlightsList";
import { getQiToken } from "@/app/lib/api/api";
import clsx from "clsx";
import { createFileRoute } from "@tanstack/react-router";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";

export const Route = createFileRoute("/_authenticated/flights/list")({
  component: RouteComponent,
});

function RouteComponent() {
  const qi = getQiToken();
  return (
    <MuiThemeProvider>
      <main className={clsx("no-footer", { main: !qi, "main-qi": !!qi })}>
        <FlightList />
      </main>
    </MuiThemeProvider>
  );
}
