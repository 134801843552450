import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataProvider";
import { useHotelSearchDetailsContext } from "@/app/lib/context/HotelSearchDetailsContext";
import { HotelOverview } from "@/app/ui/hotel/HotelOverview";
import { PersistentDrawer } from "@/app/ui/PersistentDrawer";
import { Button } from "@mui/material";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import * as Sentry from "@sentry/browser";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";

export const Route = createFileRoute(
  "/_authenticated/_hotel-offer/hotels/$id/details",
)({
  component: RouteComponent,
  staticData: {
    stepper: { step: 0 },
    footer: false,
    drawer: true,
  },
});

/**
 * First step of the hotel booking process.
 */
function RouteComponent() {
  const t = useTranslations();
  const navigate = Route.useNavigate();

  const { data: searchData } = useHotelSearchDetailsContext();
  const { data: hotel } = useOptionalHotelDataContext();

  if (!searchData || !hotel) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    Sentry.captureMessage(
      "User tried to access hotel details page directly without going through the hotel offer page",
      "fatal",
    );
    return <Navigate to="/hotels/list" />;
  }

  return (
    <MuiThemeProvider>
      <HotelOverview searchData={searchData} hotel={hotel} />
      <PersistentDrawer>
        <Button
          variant="contained"
          className="w-full"
          onClick={() => {
            navigate({ to: "/hotels/$id/rooms" });
          }}
          sx={{
            borderRadius: "999px",
            padding: "16px",
            textTransform: "none",
          }}
        >
          {t("hotels.chooseRoom")}
        </Button>
      </PersistentDrawer>
    </MuiThemeProvider>
  );
}
