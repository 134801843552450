import { StaticDataRouteOption, useMatches } from "@tanstack/react-router";

export const useRouteConfig = () => {
  const matches = useMatches();
  const routeConfig: StaticDataRouteOption = {};

  for (const match of matches) {
    if (match.staticData.stepper !== undefined) {
      routeConfig.stepper = match.staticData.stepper;
    }
    if (match.staticData.footer !== undefined) {
      routeConfig.footer = match.staticData.footer;
    }
    if (match.staticData.drawer !== undefined) {
      routeConfig.drawer = match.staticData.drawer;
    }
  }

  return routeConfig;
};
