"use client";

import { Slider, SliderProps } from "@mui/material";

import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";

type Props = Omit<
  SliderProps,
  "valueLabelFormat" | "valueLabelDisplay" | "value" | "onChange"
> & {
  value?: [number, number];
  onChange?: (
    event: Event,
    value: [number, number],
    activeThumb: number,
  ) => void;
};

const PriceRangeSlider = ({ onChange, ...props }: Props) => {
  return (
    // This should be placed in the layout to affect all mui components, but at this point it will break a lot of them
    // because manual changes were made to them to support RTL
    <MuiThemeProvider>
      <Slider
        {...props}
        onChange={
          onChange
            ? (event, newValue, activeThumb) => {
                onChange(event, newValue as [number, number], activeThumb);
              }
            : undefined
        }
        valueLabelFormat={(value) => `${value.toLocaleString()} IQD`}
        valueLabelDisplay="auto"
      />
    </MuiThemeProvider>
  );
};

export default PriceRangeSlider;
