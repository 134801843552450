import useSWR from "swr";
import { queryOptions } from "@tanstack/react-query";

import api from "../api/api";
import {
  FindUserFlightReservationOutputDto,
  FindUserHotelReservationOutputDto,
} from "../types/codegen";

const fetcher = ({ url, params }: any) => {
  return api
    .get(url, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

export const hotelReservationQueryOptions = (reservationId: string) =>
  queryOptions({
    queryKey: ["reservations", "hotels", reservationId],
    queryFn: ({ queryKey: [, , reservationId] }) =>
      api.get<FindUserHotelReservationOutputDto>(
        `/hotels/reservations/${Number.parseInt(`${reservationId}`, 10)}`,
      ),
    select: (data) => data.data?.data,
  });

export const flightReservationQueryOptions = (reservationId: string) =>
  queryOptions({
    queryKey: ["reservations", "flights", reservationId],
    queryFn: ({ queryKey: [, , reservationId] }) =>
      api.get<FindUserFlightReservationOutputDto>(
        `/flights/reservations/${Number.parseInt(`${reservationId}`, 10)}`,
      ),
    select: (data) => data.data?.data,
  });

export const useReservation = (
  scope: "hotels" | "flights",
  orderId?: string,
) => {
  const { data, error, isLoading, mutate, isValidating } = useSWR(
    () => {
      return scope && orderId
        ? {
            url: `/${scope}/reservations/${orderId}`,
          }
        : null;
    },
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  return {
    data: data?.data,
    isLoading,
    isValidating,
    isError: error,
    mutate,
  };
};
