import { useState } from "react";
import clsx from "clsx";
import { MuiThemeProvider } from "../lib/context/MuiThemeProvider";

function SingleDateCard({
  day,
  month,
  active = false,
  onChange = () => {},
}: {
  day: number;
  month: string;
  active?: boolean;
  onChange?: () => void;
}) {
  const [animate, setAnimate] = useState(false);
  const onClick = () => {
    setAnimate(true);
    onChange();
  };

  return (
    <MuiThemeProvider>
      <div
        id={`${day}-${month}`}
        onMouseDown={() => onClick()}
        onAnimationEnd={() => setAnimate(false)}
        className={clsx(
          "flex max-h-[58px] w-fit min-w-16 flex-col rounded-2xl px-4 py-2 first:mr-4 last:ml-4 last:bg-red-500 hover:cursor-pointer",
          (active && "pointer-events-none bg-[#295942] text-white") ||
            "text-primary bg-white",
          animate && "click-animate",
        )}
      >
        <span className={clsx("text-center text-base font-semibold")}>
          {day}
        </span>
        <span className={clsx("text-center text-sm")}>{month}</span>
      </div>
    </MuiThemeProvider>
  );
}

export default SingleDateCard;
