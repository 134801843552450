import { queryOptions, skipToken } from "@tanstack/react-query";
import api from "../api/api";
import { GetHotelAvailabilityTokenOutputData } from "../types/codegen";

interface HotelRoomAvailabilityParams {
  offerId: string;
  packageToken: string;
  roomTokens: string[];
  hotelId: string;
  searchResultKey: string;
  searchResultToken: string;
}

export const hotelRoomAvailabilityQueryOptions = (
  params: HotelRoomAvailabilityParams | null,
) => {
  return queryOptions({
    queryKey: ["hotel-availability", params] as const,
    queryFn: params
      ? () =>
          api.post<{ data: GetHotelAvailabilityTokenOutputData }>(
            `/hotels/${params.offerId}/availability`,
            params,
          )
      : skipToken,
    select: (data) => data.data.data,
    enabled: !!params,
  });
};
