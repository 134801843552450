import { CaretLeft } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

import { useHotelBookingContext } from "@/app/lib/context/HotelBookingProvider";

import { HotelOfferTotalPrice } from "./HotelOfferTotalPrice";

export function HotelOfferSummary() {
  const t = useTranslations();

  const { data: booking } = useHotelBookingContext();

  if (!booking) {
    return null;
  }
  return (
    <div className="mb-2 flex items-center justify-between">
      <span className="text-primary text-md flex items-center font-semibold">
        <CaretLeft color="var(--color-slate-400)" size={16} />
        <HotelOfferTotalPrice />
      </span>
      <h5 className="text-primary text-sm font-semibold">
        {t("hotels.totalPrice")}
      </h5>
    </div>
  );
}
