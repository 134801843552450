import { useHotelBookingContext } from "@/app/lib/context/HotelBookingProvider";
import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataProvider";
import { useHotelOffersContext } from "@/app/lib/context/HotelOffersProvider";
import { useHotelSearchDetailsContext } from "@/app/lib/context/HotelSearchDetailsContext";
import { HotelOfferSummary } from "@/app/ui/hotel/HotelOfferSummary";
import { HotelRoomSelection } from "@/app/ui/hotel/HotelRoomSelection";
import { PersistentDrawer } from "@/app/ui/PersistentDrawer";
import { Button } from "@mui/material";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import * as Sentry from "@sentry/browser";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";

export const Route = createFileRoute(
  "/_authenticated/_hotel-offer/hotels/$id/rooms",
)({
  component: RouteComponent,
  staticData: {
    stepper: { step: 1 },
    footer: false,
    drawer: true,
  },
});

function RouteComponent() {
  const t = useTranslations();
  const navigate = Route.useNavigate();

  const { data: offers } = useHotelOffersContext();
  const { data: hotelData } = useOptionalHotelDataContext();

  const { data: searchData } = useHotelSearchDetailsContext();
  const { data: hotelBooking } = useHotelBookingContext();

  const params = Route.useParams();

  if (!searchData || !hotelData) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    Sentry.captureMessage(
      "User tried to access hotel rooms page directly without going through the hotel offer page",
      "fatal",
    );
    return <Navigate to="/hotels/$id/details" params={params} />;
  }

  return (
    <MuiThemeProvider>
      <HotelRoomSelection
        hotel={hotelData}
        offers={offers}
        onRoomGalleryClick={() => {
          navigate({ to: "./images" });
        }}
      />
      <PersistentDrawer>
        <HotelOfferSummary />
        <Button
          disabled={
            // ensure that the user has selected anything
            !hotelBooking ||
            // ensure that the user has selected a room for each room in the hotel
            searchData?.rooms.length !== hotelBooking?.rooms.length
          }
          variant="contained"
          className="w-full"
          sx={{
            borderRadius: "999px",
            padding: "16px",
            textTransform: "none",
          }}
          onClick={() => {
            navigate({
              to: "/hotels/$id/travelers",
            });
          }}
        >
          {t("hotels.search.bookNow")}
        </Button>
      </PersistentDrawer>
    </MuiThemeProvider>
  );
}
