import type { FormikProps } from "formik";
import { useTranslations } from "use-intl";

import type { TFlightFormValues } from "@/app/lib/types/types";
import { TripType } from "@/app/lib/types/enums";

import { Button } from "../StyledTz";
import { Calendar } from "../Calendar";
import TripTypeRadioGroup from "./TripTypeRadioGroup";

function DatesSelection({
  formProps,
  onDrawerClose,
}: {
  formProps: FormikProps<TFlightFormValues>;
  onDrawerClose: () => void;
}) {
  const t = useTranslations();

  return (
    <div className="flex h-full flex-col px-2">
      <TripTypeRadioGroup />
      <Calendar
        startField={{
          label: t("flights.search.departureDate"),
          value: formProps.values.dates.departure,
          path: "dates.departure",
        }}
        endField={
          formProps.values.tripType === TripType.ROUND_TRIP
            ? {
                label: t("flights.search.returnDate"),
                value: formProps.values.dates.return,
                path: "dates.return",
              }
            : undefined
        }
        setFieldValue={formProps.setFieldValue}
        disabledDates={{
          before: new Date(),
        }}
      />
      <Button
        fullWidth
        variant="contained"
        sx={{
          borderRadius: "16px",
          marginTop: "auto",
          marginBottom: "16px",
        }}
        onClick={onDrawerClose}
      >
        {t("flights.confirm")}
      </Button>
    </div>
  );
}

export default DatesSelection;
