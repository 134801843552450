"use client";

import { styled } from "@mui/material/styles";
import {
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslations } from "use-intl";
import clsx from "clsx";
import { useNavigate } from "@tanstack/react-router";

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: "none",
  backgroundColor: "transparent",
  textTransform: "none",
  color: "white !important",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
  [`&.${toggleButtonClasses.selected}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    align: "center",
    borderRadius: "16px",
    backgroundColor: "white !important",
    color: "black !important",
  },
}));

interface ScopeSwitcherProps {
  current: "flights" | "hotels";
}

const ScopeSwitcher = ({ current }: ScopeSwitcherProps) => {
  const t = useTranslations();
  const navigate = useNavigate();

  const handleChange = (
    _: React.MouseEvent<HTMLElement> | null,
    newScope: string | null,
  ) => {
    navigate({
      to: "/",
      search: {
        category: (newScope as ScopeSwitcherProps["current"]) || current,
      },
    });
  };

  return (
    <div className="z-10 flex rounded-3xl">
      <ToggleButtonGroup
        color="primary"
        value={current}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        className="flex rounded-3xl border-none"
        sx={{
          // FIXME: Move this color to the theme
          backgroundColor: "#295942",
          borderRadius: "20px",
          padding: "4px !important",
          height: "46px",
        }}
      >
        <StyledToggleButton
          value="flights"
          className={clsx("order-2 min-w-28")}
        >
          {t("flights.title")}
        </StyledToggleButton>
        <StyledToggleButton value="hotels" className="min-w-28">
          {t("hotels.title")}
        </StyledToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default ScopeSwitcher;
