import { FormEvent, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import clsx from "clsx";
import dayjs from "dayjs";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { Button, IconButton, MenuItem, SwipeableDrawer } from "@mui/material";
import { CaretDown, X } from "@phosphor-icons/react/dist/ssr";
import { string, object, date } from "yup";
import { useTranslations } from "use-intl";
import { AxiosError, isAxiosError } from "axios";

import { TextField, TelInput, Puller } from "@/app/ui/StyledTz";
import { Calendar } from "@/app/ui/Calendar";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import type {
  FindUserTravelersOutputData,
  ValidationExceptionOutputDto,
} from "@/app/lib/types/codegen";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

const telInputCountries: MuiTelInputCountry[] = ["IQ"];

const titleOptions = ["mr", "mrs", "miss", "mstr"];

type CalendarType = "issueDate" | "dateOfBirth" | "expiryDate";

function TravellerForm({
  traveller,
  onSubmit,
  onClose,
}: {
  traveller: FindUserTravelersOutputData | null;
  onSubmit: (traveller: FindUserTravelersOutputData) => Promise<unknown>;
  onClose: () => void;
}) {
  const t = useTranslations();
  const showSnackbar = useSnackbar();
  const { dir } = useLocaleInfo();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const [calendarType, setCalendarType] = useState<CalendarType | undefined>();

  const formik = useFormik<FindUserTravelersOutputData>({
    enableReinitialize: true,
    initialValues: traveller
      ? {
          ...traveller,
          phoneNumber: traveller.phonePrefix + traveller.phoneNumber,
        }
      : {
          id: "",
          title: "mr",
          firstName: "",
          lastName: "",
          birthDate: "",
          passportNumber: "",
          phonePrefix: "+964",
          passportIssuingDate: "",
          passportExpiryDate: "",
          emailAddress: undefined,
          phoneNumber: "",
          passportIssuingCountry: "IQ",
          passportNationality: "IQ",
        },
    validationSchema: object().shape({
      firstName: string()
        .required(t("travellers.validation.nameRequired"))
        .matches(/^[a-zA-Z\s]*$/, t("travellers.validation.nameNotValid")),
      lastName: string()
        .required(t("travellers.validation.surnameRequired"))
        .matches(/^[a-zA-Z\s]*$/, t("travellers.validation.surnameNotValid")),
      birthDate: date().required(t("travellers.validation.birthDate")),
      passportNumber: string()
        .required(t("travellers.validation.passportId"))
        .matches(/^[a-zA-Z0-9]{7,9}$/, t("travellers.validation.passportId")),
      emailAddress: string()
        .required(t("travellers.validation.emailRequired"))
        .email(t("travellers.validation.emailNotValid")),
      passportIssuingDate: date().required(
        t("travellers.validation.issueDate"),
      ),
      passportExpiryDate: date().required(
        t("travellers.validation.expiryDate"),
      ),
      title: string().required(t("travellers.validation.title")),
      phoneNumber: string()
        .required(t("travellers.validation.phone"))
        .test("is-phone-number", t("travellers.validation.phone"), (value) =>
          matchIsValidTel(value, {
            onlyCountries: telInputCountries,
          }),
        ),
    }),
    onSubmit: (values, formikHelpers) =>
      onSubmit({
        ...values,
        phoneNumber: values.phoneNumber.replace(values.phonePrefix, ""),
        emailAddress: values.emailAddress || undefined,
      })
        .then(() => {
          onClose();
        })
        .catch((err) => {
          const isValidationError = (
            error: unknown,
          ): error is AxiosError<ValidationExceptionOutputDto> => {
            return (
              isAxiosError(error) &&
              error.response?.data?.code === "VALIDATION_FAILED"
            );
          };

          if (!isValidationError(err) || !err.response) {
            throw err;
          }

          const errorMessages = err.response.data.data.reduce(
            (acc, propertyError) => {
              return {
                ...acc,
                [propertyError.property]: propertyError.message,
              };
            },
            {} as FormikErrors<FindUserTravelersOutputData>,
          );

          // mark fields with errors
          formikHelpers.setErrors(errorMessages);

          // show snackbar with first error, as we don't show errors next to fields
          const errorsToShow = getFormikErrorsRecursive(errorMessages);
          if (errorsToShow.length > 0) {
            showSnackbar(errorsToShow[0], 90);
          }
        }),
  });

  const showError = (field: keyof FindUserTravelersOutputData) => {
    const touched = formik.touched;
    const errors = formik.errors;
    return (triedSubmitting || touched[field]) && !!errors[field];
  };
  const handleDrawerOpen = (type: CalendarType) => {
    setDrawerOpen(true);
    setCalendarType(type);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      const errors = getFormikErrorsRecursive(res);
      if (errors.length > 0) {
        setTriedSubmitting(true);
        showSnackbar(errors[0], 90);
      } else {
        formik.handleSubmit();
      }
    });
  };

  return (
    <>
      <div className="mt-5 mb-3 flex w-full flex-row items-center justify-center px-4 text-center">
        <div className={clsx("absolute", dir === "ltr" ? "left-4" : "right-4")}>
          <IconButton onClick={onClose}>
            <X size={20} className="fill-interactive" />
          </IconButton>
        </div>

        <h5 className="text-title text-center text-base leading-[1.625rem] font-medium">
          {traveller ? t("travellers.edit") : t("travellers.add")}
        </h5>
      </div>
      <form
        className="relative flex h-full flex-col gap-2 p-4 pb-10"
        noValidate
        onSubmit={handleSubmit}
        acceptCharset="ISO-8859-1"
      >
        <span className="text-tertiary ml-auto text-right text-xs font-medium">
          {t("travellers.info")}
        </span>
        <div className="flex gap-2">
          <TextField
            fullWidth
            style={{ minWidth: "70%" }}
            name="firstName"
            label={t("travellers.name")}
            value={formik.values.firstName}
            error={showError("firstName")}
            onChange={formik.handleChange}
            autoComplete="off"
            variant="filled"
            dir={dir}
          />
          <TextField
            fullWidth
            id="title"
            name="title"
            label={t("travellers.passengerTitle")}
            className="w-full p-0"
            dir="ltr"
            variant="filled"
            value={formik.values.title}
            error={showError("title")}
            InputProps={{
              endAdornment: (
                <CaretDown
                  className={clsx("absolute right-5")}
                  color="black"
                  size={24}
                />
              ),
            }}
            select
            onChange={(e) => formik.setFieldValue("title", e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                left: "0",
              },
              "& .MuiSelect-select": {
                borderRadius: "16px !important",
              },
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
          >
            {titleOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {t(`travellers.titleOptions.${option}`)}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <TextField
          fullWidth
          name="lastName"
          label={t("travellers.lastName")}
          value={formik.values.lastName}
          error={showError("lastName")}
          onChange={formik.handleChange}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="dateOfBirth"
          label={t("travellers.dateOfBirth")}
          value={
            formik.values.birthDate
              ? dayjs(formik.values.birthDate).format("YYYY-MM-DD")
              : ""
          }
          error={showError("birthDate")}
          onMouseDown={() => {
            handleDrawerOpen("dateOfBirth");
          }}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="passportNumber"
          label={t("travellers.passportId")}
          value={formik.values.passportNumber}
          error={showError("passportNumber")}
          onChange={formik.handleChange}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="passportIssuingDate"
          label={t("travellers.passportIssueDate")}
          value={
            formik.values.passportIssuingDate
              ? dayjs(formik.values.passportIssuingDate).format("YYYY-MM-DD")
              : ""
          }
          error={showError("passportIssuingDate")}
          onMouseDown={() => {
            handleDrawerOpen("issueDate");
          }}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="passportExpiryDate"
          label={t("travellers.passportExpiryDate")}
          value={
            formik.values.passportExpiryDate
              ? dayjs(formik.values.passportExpiryDate).format("YYYY-MM-DD")
              : ""
          }
          error={showError("passportExpiryDate")}
          onMouseDown={() => {
            handleDrawerOpen("expiryDate");
          }}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="emailAddress"
          label={t("travellers.email")}
          value={formik.values.emailAddress}
          error={showError("emailAddress")}
          onChange={formik.handleChange}
          autoComplete="off"
          type="email"
          variant="filled"
          dir={dir}
        />
        <TelInput
          fullWidth
          value={formik.values.phoneNumber}
          error={showError("phoneNumber")}
          onChange={(event) => {
            let phoneNumber = event.replace(/\D/g, "");
            if (!phoneNumber.startsWith("964")) {
              phoneNumber = "964";
            }
            phoneNumber = "+" + phoneNumber;
            formik.setFieldValue("phoneNumber", phoneNumber);
          }}
          onlyCountries={telInputCountries}
          disableFormatting
          disableDropdown
          sx={{
            paddingBottom: "12px",
          }}
          name="phoneNumber"
          label={t("travellers.phoneNumber")}
          autoComplete="off"
          variant="filled"
          defaultCountry={telInputCountries[0]}
          dir={dir}
        />
        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{
            zIndex: 20, // fixes input labels hovering over button
            marginTop: "auto",
            textTransform: "none",
            position: "sticky",
            bottom: "12px",
            borderRadius: "999px",
            padding: "16px 24px",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "22px",
          }}
        >
          {t("saveChanges")}
        </Button>
      </form>
      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            paddingBottom: "env(safe-area-inset-bottom)",
            borderRadius: "32px 32px 0 0",
          },
        }}
      >
        {drawerOpen && (
          <div className="flex h-full flex-col p-4 pt-0">
            <div className="flex items-center justify-center py-4">
              <Puller />
            </div>
            <Calendar
              dropdown
              setFieldValue={formik.setFieldValue}
              {...(calendarType === "issueDate"
                ? {
                    startField: {
                      label: t("travellers.passportIssueDate"),
                      value: formik.values.passportIssuingDate,
                      path: "passportIssuingDate",
                    },
                    disabledDates: {
                      after: new Date(),
                    },
                  }
                : calendarType === "dateOfBirth"
                  ? {
                      startField: {
                        label: t("travellers.dateOfBirth"),
                        value: formik.values.birthDate,
                        path: "birthDate",
                      },
                      disabledDates: {
                        after: new Date(),
                      },
                    }
                  : {
                      startField: {
                        label: t("travellers.passportExpiryDate"),
                        value: formik.values.passportExpiryDate,
                        path: "passportExpiryDate",
                      },
                      disabledDates: {
                        before: new Date(),
                      },
                    })}
            />
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                marginTop: "auto",
                textTransform: "none",
                borderRadius: "999px",
                position: "sticky",
                bottom: "12px",
                padding: "12px 24px",
              }}
              onClick={() => setDrawerOpen(false)}
            >
              {t("flights.done")}
            </Button>
          </div>
        )}
      </SwipeableDrawer>
    </>
  );
}

export default TravellerForm;
